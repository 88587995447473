import { images } from "../images";

const embed = "https://www.youtube.com/watch?v=";

export const winner2024Data = [
  {
    id: 1,
    image: images.winnersGrowAward.image,
    alt: images.winnersGrowAward.alt,
    category: "grow <br/> award winner",
    brand: "skininspired",
    name: "PIYUSH JAIN & DR. PRASHANT AGRAWAL",
    desc: `Founded by Piyush Jain and Dr. Prashant Agrwal, SkinInspired is a science-backed skincare brand that uses multi-active ingredients to deliver immediate results in innovative packaging that makes for a seamless user experience.`,
    watch: embed + "y5U24_vfwnQ",
    web: "https://skininspired.in/",
    insta: "https://www.instagram.com/skininspired.in/",
  },
  {
    id: 2,
    image: images.winnersImagineAward.image,
    alt: images.winnersImagineAward.alt,
    category: "imagine <br/> award winner",
    brand: "NOT JUST VANILLA",
    name: "AKSHAY BAWA & KRITIKA RATHI",
    desc: `Founded by Akshay Bawa and Kritika Rathi, Not Just Vanilla redefines bodycare with targeted, results-driven solutions for overlooked areas, empowering its customers to feel confident and embrace self-love every day.`,
    watch: embed + "UvANJ_oTS5I",
    web: "",
    insta:
      "https://www.instagram.com/notjustvanilla.co/",
      color: "#C774A0",
  },
  {
    id: 3,
    image: images.winnersImagineAward02.image,
    alt: images.winnersImagineAward02.alt,
    category: "imagine <br/> award winner",
    brand: "AMRIGHT",
    name: "VAISHNAVI MAGANTI & VIJAY SIVARAJU",
    desc: `Founded by Vaishnavi Maganti and Vijay Sivaraju, Amright aims to revive the legacy of thick Indian hair with a three product kit comprising of a nutrient-rich pill (using patented V-fill™ technology), revitalizing serum and pioneering 'root biotech' oil with a booster shot that helps reverse hair thinning by treating the root cause without any side effects.
    <br/><br/>
    Tailored for today’s women, Amright offers custom product combos based on personalized hair assessments and provide online consultations with trichologists to help women address their hair issues.`,
    watch: embed + "8G1ryL26fn4",
    web: "",
    insta: "https://www.instagram.com/amrightindia/",
    color: "#C774A0",
  },
  {
    id: 4,
    image: images.winnersCreateAward.image,
    alt: images.winnersCreateAward.alt,
    category: "create <br/> award winner",
    brand: "JOURNEY OF OBJECTS",
    name: "ALIA ALLANA",
    desc: `Founded by Alia Allana, Journey of Objects artfully blends reportage and retail. As an e-commerce marketplace, Journey of Objects helps embrace sustainability and preserve the cultural diversity of India through industry research and thoughtful product curation.`,
    watch: embed + "_ZsUEK1YiB8",
    web: "https://www.journeyofobjects.com/",
    insta: "https://www.instagram.com/journeyofobjects/?igsh=NTdsaDUwbGVydm56",
    color: "#FC9500",

  },
];