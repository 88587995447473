import React from "react";
import { Helmet } from "react-helmet";

const ApplicationPixel = () => {
  return (
    <>
      {process.env.REACT_APP_APP_MODE == "production" && (
        <Helmet>
          {/* Meta Pixel Code  */}
          <script>
            {`!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '277245661339991');
              fbq('track', 'PageView');`}
          </script>
          <noscript>
            {`<img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=277245661339991&ev=PageView&noscript=1"
            />`}
          </noscript>
          {/* End Meta Pixel Code  */}
          {/* Google Tag Manager  */}
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':

              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],

              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=

              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);

              })(window,document,'script','dataLayer','GTM-WDCQVFL');`}
          </script>
          {/* End Google Tag Manager */}
        </Helmet>
      )}
    </>
  );
};

export default ApplicationPixel;
