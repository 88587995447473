import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Homepage from "./pages/Homepage/Homepage";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ContactUs from "./pages/ContactUs/ContactUs";
import Winners from "./pages/Winners/Winners";
import {
  prevWinnersURL,
  accountLogin,
  accountRegister,
  accountReset,
  accountSignUp,
  contactUs,
  homepage,
  judges,
  masterClass,
  newmasterInnerURL,
  pageNotFound,
  pressURL,
  awardsURL,
  ThankyouURL,
  applicationcriteria,
  faqURL,
  aboutUsURL,
  privacyandcookiepolicyroute,
  awardsEveningURL,
  businessOfFashionURL,
  twentThreeWinnersURL,
  exhibitionURL,
  finalist2024URL,
  eventNightURL,
  twentyFourWinnersURL,
} from "./helpers/constant-words";
import Prizes from "./pages/Prizes/Prizes";
import Press from "./pages/Press/Press";
import Judges from "./pages/Judges/Judges";
import Masterclass from "./pages/Masterclass/Masterclass";
import MasterclassInner from "./pages/MasterclassInner/MasterclassInner";
import SignUp from "./pages/SignUp/SignUp";
import Login from "./pages/Login/Login";
import RegisterForm from "./pages/RegisterForm/RegisterForm";
import ThankYou from "./pages/RegisterForm/ThankYou/ThankYou";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import ApplicationCriteria from "./pages/ApplicationCriteria/ApplicationCriteria";
import Faq from "./pages/Faq/Faq";
import Aboutus from "./pages/AboutUs/AboutUs";
import PrivacyAndCookiePolicy from "./pages/PrivacyAndPolicy/PrivacyAndCookiePolicy";
import MeetTheWinners from "./pages/MeetTheWinners/MeetTheWinners";
import AwardsEvening from "./pages/AwardsEvening/AwardsEvening";
import BusinessOfFashion from "./pages/BusinessOfFashion/BusinessOfFashion";
import Exhibition from "./pages/Exhibition/Exhibition";
import Voting from "./pages/Voting/Voting";
import VotingInner from "./pages/Votinginner/VotingInner";
import EventNight from "./pages/EventNight/EventNight";
import MeetWinners2024 from "./pages/MeetWinners2024/MeetWinners2024";

const Routing = () => {
  return (
    <>
      <Routes>
        <Route path={homepage} element={<Homepage />} />
        <Route path={contactUs} element={<ContactUs />} />
        <Route path={prevWinnersURL} element={<Winners />} />
        <Route path={twentThreeWinnersURL} element={<MeetTheWinners />} />
        <Route path={awardsURL} element={<Prizes />} />
        <Route path={pressURL} element={<Press />} />
        <Route path={judges} element={<Judges />} />
        <Route path={applicationcriteria} element={<ApplicationCriteria />} />
        <Route path={aboutUsURL} element={<Aboutus />} />
        <Route path={faqURL} element={<Faq />} />
        <Route path={masterClass} element={<Masterclass />} />
        <Route path={newmasterInnerURL} element={<MasterclassInner />} />
        <Route path={accountSignUp} element={<Navigate to={homepage} />} />
        <Route path={accountLogin} element={<Navigate to={homepage} />} />
        {/* <Route path={accountRegister} element={<RegisterForm />} /> */}
        <Route path={accountRegister} element={<Navigate to={homepage} />} />
        <Route path={ThankyouURL} element={<ThankYou />} />
        <Route path={accountReset} element={<Navigate to={homepage} />} />
        {/* <Route
          path={termsAndConditionsRoute}
          element={<TermsAndConditions />}
        /> */}
        <Route
          path={privacyandcookiepolicyroute}
          element={<PrivacyAndCookiePolicy />}
        />
        {/* <Route exact path={finalistURL} element={<Finalist />} /> */}
        {/* <Route
          exact
          path={imagineURL}
          element={<Finalistimagine />}
        /> */}
        {/* <Route exact path="/finalist" element={<FinalistV2 />} /> */}
        {/*<Route path={finalist2024URL} element={<Voting />} /> */}
        {/* <Route path={`${finalist2024URL}/:slug`} element={<VotingInner />} /> */}
        
        {/* <Route path={meetWinnersURL} element={<Winners2024 />} /> */}
        <Route path={awardsEveningURL} element={<AwardsEvening />} />
        {/* <Route path={luminariesURL} element={<Luminaries />} /> */}
        <Route path={businessOfFashionURL} element={<BusinessOfFashion />} />
        <Route path={exhibitionURL} element={<Exhibition />} />
        {/* <Route path={votingURL} element={<Voting />} /> 
        {/* <Route path={votingimagineURL} element={<Votingimagine />} /> */}
        {/* <Route path={votinggrowURL} element={<Votinggrow />} /> */}
        {/* <Route path={votingcreateURL} element={<Votingcreate />} /> */}
        <Route exact path={finalist2024URL} element={<Voting />} />
        <Route exact path={`${finalist2024URL}/:slug`} element={<VotingInner />} />
        {/* <Route path={eventNightURL} element={<EventNight />} /> */}
        <Route path={twentyFourWinnersURL} element={<MeetWinners2024 />} />
        <Route exact path={pageNotFound} element={<PageNotFound />} />
        <Route path="*" element={<Navigate to={pageNotFound} />} />
      </Routes>
    </>
  );
};

export default Routing;
