import React, { useState } from "react";

import UserContext from "./UserContext";

const UserState = (props) => {
  const [user, setUser] = useState({});

  const update = (data) => {
    setUser(data);
  };
  return (
    <UserContext.Provider value={{ data: user, update }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
