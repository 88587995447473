import React, { useEffect, useState } from "react";
import "./masterclassinner.scss";
import { useWindowSize } from "react-use";
import {
  masterclassrightarrow,
  slideleftarrow,
  sliderightarrow,
} from "../../images";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { masterclassData } from "../../data/masterData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import { Link, useParams, useNavigate } from "react-router-dom";
import { masterClass } from "../../helpers/constant-words";
import { findBySlug } from "../../data/masterData";

const MasterclassInner = () => {
  const { width: windowWidth } = useWindowSize();
  const { slug } = useParams();
  const [data, setData] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const data = findBySlug(slug);

    if (!data) {
      navigate("/404");
    } else {
      setData(data);
      window.scrollTo(0, 0);
    }
  }, [slug]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header slug={slug} />

      <section className="masterclaass_inner1">
        <img
          width={1440}
          height={678}
          src={windowWidth > 600 ? data.bannerImg : data.mobBannerImg}
          alt="masterclass"
          className="banner_img"
        />
      </section>

      <section className="masterclaass_inner2">
        <div className="my_container text_video_container">
          <article className="profile_details">
            <h3 className="profile_cat">{data.category}</h3>
            <h1 className="section_subheading profile_name">{data.name}</h1>
            <h2
              className="profile_designation"
              dangerouslySetInnerHTML={{
                __html: data.innerDesignation
                  ? data.innerDesignation
                  : data?.designation,
              }}
            />
            <p
              className="profile_description"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
          </article>
          <iframe
            width={1244}
            height={
              windowWidth > 992 ? 730 : "" || windowWidth > 600 ? 425 : 225
            }
            className="youtube_box"
            // src={`https://www.youtube.com/embed/${data.videoId}?loop=1&autoplay=1&mute=1`}
            src={data.videoId}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </section>

      <section className="masterclaass_inner3">
        <div className="my_container">
          <div className="other_heading">
            OTHER masterCLASSES FROM OUR MENTORS
          </div>
          <div className="other_masterclass_swiper">
            <Swiper
              modules={[Navigation, Autoplay]}
              spaceBetween={40}
              autoHeight={false}
              navigation={{
                prevEl: ".navigation_wrapper .arrowleft",
                nextEl: ".navigation_wrapper .arrowright",
              }}
              grabCursor={true}
              // autoplay={{
              //   delay: 3000,
              //   disableOnInteraction: false,
              // }}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                  spaceBetween: 23,
                },
                600: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
              }}
            >
              {masterclassData
                .filter((item) => item.slug != slug)
                .map((item, i) => (
                  <SwiperSlide key={i}>
                    <div className="masterclass_profile">
                      <Link
                        to={`${masterClass}/${item.slug}`}
                        style={{
                          textDecoration: "none",
                        }}
                        className="img_text_body"
                      >
                        <img
                          width={320}
                          height={320}
                          src={item.img}
                          alt={item.alt}
                          className="masterclass_img"
                          loading="lazy"
                        />
                        <h6 className="masterclass_category">
                          {item.category}
                        </h6>
                        <h6 className="masterclass_name">{item.name}</h6>
                        <p className="masterclass_desc">{item.designation}</p>
                      </Link>
                      <div className="btn_wrapper">
                        <Link
                          className="general_cta"
                          to={`${masterClass}/${item.slug}`}
                        >
                          Information
                          <img
                            width={10.5}
                            height={10}
                            src={masterclassrightarrow}
                            alt=""
                            className="right_arrow"
                            loading="lazy"
                          />
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            {windowWidth > 600 ? (
              <div className="navigation_wrapper">
                <img
                  src={slideleftarrow}
                  alt="left nav"
                  className="arrow_img arrowleft"
                />
                <img
                  src={sliderightarrow}
                  alt="right nav"
                  className="arrow_img arrowright"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MasterclassInner;
