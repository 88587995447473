import { images } from "../images";

export const judgesData = [
  {
    id: 1,
    img: images.shanarandhavajudge.image,
    alt: images.shanarandhavajudge.alt,
    name: "Shana Randhava",
    desg: "SENIOR VICE PRESIDENT, NEW INCUBATION VENTURES, THE ESTÉE LAUDER COMPANIES",
    company: "",
    desc: `As the head of New Incubation Ventures (NIV), Shana Randhava is responsible for building The Estée Lauder Companies (ELC) early-stage investment and incubation strategy. This new team is building a distinctive capability for ELC to invest in and experiment at scale and create an actionable pipeline of next generation brands for the ELC portfolio. Prior to creating NIV, Shana led the New Business Development team and was responsible for shaping and executing the ELC’s M&A strategy for DECIEM, Dr. Jart+, Forest Essentials, BY Killian and Too Faced.<br /><br/>New Incubation Ventures (NIV) is the strategic early-stage investment and incubation arm for The Estée Lauder Companies. NIV partners with forward-thinking founders and entrepreneurs to create, fund and support the best-emerging beauty brands and new business models to shape the future of beauty and build an actionable pipeline of diversified brands and new engines of growth for the ELC portfolio.`,
  },
  {
    id: 2,
    img: images.anchitnayarjudge.image,
    alt: images.anchitnayarjudge.alt,
    name: "Anchit Nayar",
    desg: "Executive Director & CEO",
    company: "NYKAA BEAUTY",
    desc: `As the CEO of Nykaa's beauty business, Anchit spearheads the flagship's beauty e-commerce and retail businesses, holistically focusing on industry-first initiatives and best-in-class omnichannel experiences that are tailored to the needs of both customers and brand partners. Anchit’s journey with Nykaa began as CEO of Nykaa Retail where he lead the company’s offline strategy with a focus on expanding the physical store presence. Today, along with highly differentiated platforms, Nykaa’s retail footprint spans across 175 stores in 60 cities, offering an extensive array of over 3400+ Indian and international brands. Under his stewardship, Nykaa continues to be a dominant and innovative marketing force, redefining beauty retail in the country and bringing the best brands, trends and experiences closer to consumers.<br/><br/>For the pivotal role that Anchit has played in making Nykaa synonymous with beauty in India, he was included in the illustrious Fortune India's 40 under 40 list of achievers in 2022. He was recognised by Forbes India as the Tycoon of Tomorrow and as one of the top retail minds at the Asia Retail Congress held in 2023.`,
  },
  {
    id: 3,
    img: images.katrinakaifjudge.image,
    alt: images.katrinakaifjudge.alt,
    name: "Katrina Kaif",
    desg: "ACTOR AND CO-FOUNDER,",
    company: "KAY BEAUTY",
    desc: `As a distinguished performer and award-winning entrepreneur, Katrina Kaif has earned her place as a change maker in her own right. Her proven track record of success has established her as a force to be reckoned with in the entertainment industry and as one of India's highest grossing female actors.<br /><br />As a self-made entrepreneur and philanthropist, Katrina has formed partnerships with leading global companies and her homegrown beauty brand, Kay Beauty, has achieved great success in India for its inclusive range of makeup products catering to all skin tones.`,
  },
  {
    id: 4,
    img: images.rohanvazirallijudge.image,
    alt: images.rohanvazirallijudge.alt,
    name: "Rohan Vaziralli",
    desg: "GENERAL MANAGER",
    company: "ELCA COSMETICS PRIVATE LIMITED",
    desc: `Rohan Vaziralli is the General Manager at ELCA Cosmetics Private Limited who embarked on his remarkable journey with the Estée Lauder Companies in 1996, setting the stage for an inspiring career defined by innovation and strategic vision. His extraordinary ascent within the company has been marked by a series of pivotal roles that have showcased his diverse skill set and unwavering commitment to excellence. Rohan’s multifaceted career trajectory in prestige cosmetics encompasses manufacturing, supply chain management, business development, and affiliate development. His tenure as the Business Development Manager in Dubai provided invaluable insights into international markets, laying the groundwork for his subsequent appointment as the Country Manager for ELCA Cosmetics, India, a subsidiary of Estée Lauder Companies, in 2005. Today, as the General Manager of Estée Lauder Companies in India, Vaziralli continues to spearhead the growth and development of an extensive portfolio of prestigious beauty and fragrance brands, including Estée Lauder, M.A.C, Clinique, Bobbi Brown, and Jo Malone London, among others. His strategic acumen and keen understanding of consumer behavior have been instrumental in driving the company's online expansion and reinforcing its leadership position in skincare. By fostering stronger omnichannel retailer partnerships and enhancing local relevancy, he has elevated Estée Lauder's brand presence and share of voice in the Indian prestige beauty landscape to new heights.`,
  },
  {
    id: 5,
    img: images.diipabullerkhoslajudge.image,
    alt: images.diipabullerkhoslajudge.alt,
    name: "Diipa Büller-Khosla",
    desg: "Founder",
    company: "Indē Wild",
    desc: `Born and raised in India, Diipa Büller-Khosla is the founder of Indē Wild, a skincare brand based on science and backed by Ayurveda. She is also an influencer and a devoted advocate of women's empowerment with features in illustrious publications like Vogue, Forbes, Elle, Grazia, Condé Nast and more. Diipa has worked with the United Nations and also runs her own NGO called ‘Post for Change’.`,
  },
  {
    id: 6,
    img: images.taruntahilianijudge.image,
    alt: images.taruntahilianijudge.alt,
    name: "Tarun Tahiliani",
    desg: "Founder & Director",
    company: "Tarun Tahiliani",
    desc: `Tarun Tahiliani, a Wharton School of Business graduate, recognized the burgeoning potential of India's couture industry upon returning to his homeland. In 1987, he pioneered India's fashion landscape by establishing Ensemble, the country's first multi-designer boutique before launching his eponymous label. Since his inaugural solo show at London's Dorchester Hotel in September 1994, Tahiliani has showcased his collections globally from New York to Dubai and Hong Kong. He is also credited with being the first Indian designer to show a collection at Milan Fashion Week in 2003. Tahiliani's distinctive aesthetic is characterized by intricate detailing, refined luxury, and impeccable tailoring. From couture to ready-to-wear, each piece reflects timelessness and a fusion of Indian sensibility and international allure. The brand reinvents the subcontinent's rich heritage into contemporary high fashion, celebrating the genius of Indian craftsmanship and the finest textiles on a global stage. Known as one of the pioneers of couture in India, Tahiliani authored his first book ‘Journey to India Modern’ in 2023 which encapsulates his three-decade legacy and provides a deep dive into the fashion ecosystem in the country. In 2025 Tahiliani will also become the first Indian designer to have a curated showcase of his creations and legacy contributions to fashion, shown at the Asian Civilization Museum in Singapore.`,
  },
  {
    id: 7,
    img: images.gauravguptajudge.image,
    alt: images.gauravguptajudge.alt,
    name: "Gaurav Gupta",
    desg: "COUTURIER, ARTIST AND DESIGNER",
    company: "",
    desc: `Gaurav Gupta is the designer and founder of his eponymous label that he founded in 2005 after graduating from the Central Saint Martins College of Art & Design. In a span of nineteen illustrious years, Gaurav has carved a space for himself in the global fashion landscape that is proudly Indian at its core yet boundless in its form. His signature sculptural silhouettes have been spotted all over the world, including at The Oscars, The Emmy Awards, and The Festival De Cannes, as well as on global best-dressed lists and magazine covers. Gaurav’s designs have also been worn by some of the biggest celebrities in the world, including luminaries like Beyoncé, Nicki Minaj, Cardi B, and Shakira. Hailed as the future of couture, Gaurav has been awarded at the Mittelmoda, Altaroma Altamoda, and the Makuhari Grand Prix.`,
  },
  {
    id: 8,
    img: images.anaitashroffadajaniajudge.image,
    alt: images.anaitashroffadajaniajudge.alt,
    name: "Anaita Shroff Adajania",
    desg: "STYLIST, CREATIVE DIRECTOR & FOUNDER, STYLE CELL",
    company: "",
    desc: `Heralded as the first lady of fashion in India, Anaita Shroff Adajania has been the stylist and costume designer for some of Bollywood’s most iconic films, including Gehraiyan, Dhoom, Cocktail and War. There is no popular celebrity that Anaita has not styled through her styling journey whether it is Kim Kardashian, Gisele Bundchen, Katie Perry, Shah Rukh Khan, Hritik Roshan, Alia Bhatt, Katrina Kaif or Deepika Padukone. Known for acing everything from red carpet to airport looks, she’s on every celebrity’s speed dial. This award winning stylist’s expertise is not only limited to styling celebrities, it is also extended to influencers and socialites, making her the go-to stylist for them all. Post a successful run as the founding Fashion Director of Vogue India for 13 years, Anaita now runs Style Cell - India’s first and biggest styling consultancy. She is now on demand for all fashion and lifestyle brands for her unique and real, yet aspirational Instagram content.`,
  },
  {
    id: 9,
    img: images.drjaishreesharadjudge.image,
    alt: images.drjaishreesharadjudge.alt,
    name: "Dr. Jaishree Sharad",
    desg: "Celebrated Cosmetic Dermatologist, Author and TEDX Speaker",
    company: "",
    desc: `Dr. Jaishree Sharad is a globally acclaimed cosmetic dermatologist based in Mumbai, with 24 years of experience and a high-profile clientele from Bollywood, politics, and cricket. As the founder and director of Skinfinitii Aesthetic Skin and Laser Clinics, Dr. Jaishree Sharad excels in advanced treatments like fillers, Botox, and laser therapies and is also a celebrated author with three bestsellers published by Penguin Random House. A TEDx speaker and a respected academic, she contributes to several prestigious dermatological journals and has been a key faculty at over 800 international and national conferences.`,
  },
  {
    id: 10,
    img: images.jaffreyzamanjudge.image,
    alt: images.jaffreyzamanjudge.alt,
    name: "Jaffrey Zaman",
    desg: "MANAGING DIRECTOR",
    company: "INTERCOS INDIA PRIVATE LIMITED",
    desc: `Jaffrey Zaman is the esteemed Managing Director of Intercos India and a visionary and purpose-driven leader in the Indian beauty space with a goal is to bring continuous innovation in the fields of R&D, Sourcing Manufacturing ,Quality, Marketing, Distribution, Retail and Business Development within the categories of make-up, skincare and fragrance. Throughout his career, Jaffrey has demonstrated astute leadership in managing large teams within diverse geographies by trying to create ecosystems that can grow organically. He has worked with many renowned beauty multinational companies like Intercos ,LVMH, Estee Lauder, Coty, Ajmal and Unilever and likes to contribute as a guest speaker in many world renowned academic institutions like Fashion Institute of Technology and SDA Bocconi.`,
  },
  {
    id: 11,
    img: images.shrutichandrajudge.image,
    alt: images.shrutichandrajudge.alt,
    name: "Shruti Chandra",
    desg: "Vice President, Invest India and Vice Chair, Women Empowerment, Europe India Council for Business and Industry (EICBI)",
    company: "",
    desc: `Shruti Chandra, Vice President at Invest India, leads the Retail, Consumer Goods, and E- commerce sectors, strategically crafting investment strategies and nurturing relationships. A pioneering figure in India, Retail and E-commerce landscape, she illuminates investment prospects at both domestic and international forums, bolstering FDI inflows. Her expertise spans Digital Services, E-commerce, Consumer Protection, and Policy Advocacy, actively contributing to Ease of Doing Business initiatives. Ms. Chandra holds a Bachelor's degree from the University of Melbourne and a Master's in Management and International Management from the University of Sydney Business School and CEMS (Rotterdam School of Management). Additionally, she was awarded a scholarship for the Hinrich Foundation – NUS Singapore Executive Program on Cross Border Trade.<br/><br/>Her industry influence is highlighted by her roles as a jury member for the Economic Times Great India Retail Awards 2024, Economic Times E-commerce and Digital Natives Awards (eDNA) 2023, and the Global Fintech Festival 2022. Being featured among the EICBI EuropeIndia40 leaders for 2023 underscores her impactful leadership and expansive global footprint.<br/><br/>Moreover, Ms. Chandra serves as the Chairperson of the Europe India Centre for Women Leadership (EICWL), Vice Chair of Gender Equality and Women Empowerment at the Europe India Council for Business and Industry (EICBI), and is a Council Member of the Women’s Indian Chamber of Commerce and Industry (WICCI) India-EU Business Council. Her extensive contributions continue to drive significant advancements in her field, demonstrating her commitment to fostering growth, diversity & inclusion and innovation.`,
  },
  {
    id: 12,
    img: images.sujatajudge.image,
    alt: images.sujatajudge.alt,
    name: "Sujata Assomull",
    desg: "Contributing Editor",
    company: "Vogue Business",
    desc: `London-born Sujata Assomull is a Dubai-based journalist and author of the bestselling 100 Iconic Bollywood Costumes. A mindful fashion and pro-age advocate, she was the launch Editor-in-chief of Harper’s Bazaar India and has previously worked with Khaleej Times as their Group Fashion Editor. An international chronicler of fashion trends, she is a go-to resource in the industry for commentary on India and the Middle East’s evolving and dynamic markets. Sujuta is currently a contributing editor for Vogue Business and a columnist with India’s leading business daily, Mint, where she looks at fashion through a cultural and economic lens. Her bylines have appeared in Vogue India, Vogue Arabia, Business of Fashion, South China Morning Post, Khaleej Times and Arab News.`,
  },
  {
    id: 13,
    img: images.samrathbedijudge.image,
    alt: images.samrathbedijudge.alt,
    name: "Samrath Bedi",
    desg: "EXECUTIVE DIRECTOR",
    company: "FOREST ESSENTIALS",
    desc: `Mr. Samrath Bedi completed his schooling from The Doon School, Dehradun, following which he attended college at the University of Rochester, New York where he majored in Economics and Psychology. Samrath then worked with the Standard Chartered Bank in New York for a short stint in corporate banking. Seeing an opportunity in the evolving natural and organic skin care market, combined with his passion to represent India at its very best, he returned to India to work with Forest Essentials, a company started by his mother a few years earlier. It has taken years of research with Ayurvedic Vaids and modern biochemists to develop a philosophy that deeply imbibes the spirit and identity of Indian Ayurveda, a 6000-year-old science.<br/><br/>Today, Forest Essentials caters to four major categories of products: skin care; child care, hair care, Natural makeup and Wellness. The products have pure potent ingredients and are made using traditional methods known to be hugely effective while providing the consumer with a sensory experience and have created what is arguably a cult following with their users in India and abroad. Samrath is currently the Executive Director of Forest Essentials, which has the largest network of over 150 company-owned stores in India and is the first Indian beauty brand to have gone global with international stores in London, Dubai and Kuwait; a true testament to the brands’ pathbreaking journey to create high quality and aspirational Indian beauty products.`,
  },
  {
    id: 14,
    img: images.gianandreaferrarijudge.image,
    alt: images.gianandreaferrarijudge.alt,
    name: "Gianandrea Ferrari",
    desg: "CEO, Intercos Europe, Middle-East & India",
    company: "",
    desc: `Gianandrea Ferrari is a seasoned executive with over 20 years of experience in the beauty and cosmetics industry. Currently serving as the Chief Executive Officer of Intercos, he is known for his strategic vision, strong leadership skills, and deep understanding of global markets. Under his guidance, Intercos has expanded its product portfolio, increased market share, and established itself as a key player in the industry. Ferrari is recognized for his innovative approach to product development, commitment to sustainability and dedication to driving growth and profitability. His passion for excellence and customer-centric mindset have been instrumental in shaping Intercos' success and position of the company as a leader in the competitive beauty sector.`,
  },
  {
    id: 15,
    img: images.rochellepintojudge.image,
    alt: images.rochellepintojudge.alt,
    name: "Rochelle Pinto",
    desg: "Head of Editorial Content",
    company: "Vogue India",
    desc: `Rochelle Pinto is the Head of Editorial Content for Vogue India, where she is responsible for the vision and content across all platforms for the brand in India. Rochelle is a career journalist, published author and public speaker with over 17 years of experience across print and digital, including as the founding editor at Tweak India.`,
  },
  {
    id: 16,
    img: images.sumitbhasinjudge.image,
    alt: images.sumitbhasinjudge.alt,
    name: "Sumit Bhasin",
    desg: "SENIOR VICE PRESIDENT, GLOBAL CORPORATE FRAGRANCE INNOVATION, PRODUCT DEVELOPMENT & R&D, THE ESTÉE LAUDER COMPANIES",
    company: "",
    desc: `Sumit Bhasin joined the Estée Lauder Companies (ELC) in 2020, serving as Senior Vice President, Corporate fragrance Research & Development (R&D), Product Development and Innovation, leading end-to-end innovation efforts, as well as perfume capability across the enterprise. In this role, Sumit is responsible for the global innovation strategy for the fragrance category across ELC’s portfolio of brands. His leadership has been instrumental in delivering breakthrough innovation that has been key to driving growth, sustaining the company’s leadership in luxury fragrances, and making it a core pillar for ELC. Sumit has developed and led organizations in the United States, Europe, and Asia, covering both developed and developing markets.<br/><br />Prior to joining ELC, Sumit was Senior Vice President for Global Luxury R&D at Coty, where he was responsible for innovation in fragrances, skin care and cosmetics.`,
  },
  {
    id: 17,
    img: images.sandhyadevanathanjudge.image,
    alt: images.sandhyadevanathanjudge.alt,
    name: "Sandhya Devanathan",
    desg: "Vice President & Head - India",
    company: "Meta",
    desc: `With more than two decades of experience in the banking, payments and tech sectors, Sandhya Devanathan currently serves as the Vice President for Meta India. Since the time she joined Meta in 2016, Devanathan has been instrumental in helping the platform grow across Singapore and Vietnam and in 2020, she also assumed leadership of Gaming at Meta APAC, a move that was integral to ushering inclusivity within the gaming market in the region, consequently increasing revenue and ensuring its success.<br /><br />Outside of work, Devanathan is passionate about helping women in business. She is the executive sponsor for Women@APAC at Meta and has also launched Play Forward, a Meta project aimed at increasing diversity in the gaming industry. Devanathan sits on the global board of Pepper Financial Services and the National Library Board in Singapore and is also a board observer for Jio Platforms. Additionally, she is a member of the Board of Advisors for the College of Integrative Studies at Singapore Management University and the US-India Business Council (USIBC) and a co-chair at the FICCI Committee on Media & Entertainment and the CII National Committee on IT and ITeS.`,
  },
  {
    id: 18,
    img: images.deepicamutyalajudge.image,
    alt: images.deepicamutyalajudge.alt,
    name: "Deepica Mutyala",
    desg: "CEO & FOUNDER",
    company: "LIVE TINTED",
    desc: `Deepica Mutyala is the founder and CEO of Live Tinted, a beauty brand dedicated to celebrating all complexions and championing multicultural beauty. A renowned beauty entrepreneur and creator, Mutyala rose to the forefront in 2015 when her YouTube video, depicting a beauty hack to cover dark undereye circles using red lipstick, went viral — now boasting over 10 million views. Since then, she has been recognized by some of the most prestigious publications for her achievements in the beauty space, including a dedicated feature as a TIME Magazine Next Generation Leader in 2022.<br /><br />Live Tinted is sold across channels in North America, including www.livetinted.com and at some of the top beauty retailers in North America including Ulta Beauty, Sephora and Credo.`,
  },
  {
    id: 19,
    img: images.kiransethijudge.image,
    alt: images.kiransethijudge.alt,
    name: "Dr. Kiran Sethi, MD",
    desg: "Author, Skin Expert & Founder",
    company: "Isya Aesthetics",
    desc: `Dr. Kiran Sethi, MD, is a globally regarded expert in aesthetics, skincare, wellness and IV nutrition. Her arc extends from New York, where she was born and raised, to New Delhi where she currently practices from Isya Aesthetics, her multi-specialty clinic. The Columbia University graduate is also the best-selling author of Skin Sense.`,
  },
];

export const winnersData = [
  {
    title: "IMAGINE",
    img: images.winner1.image,
    alt: images.winner1.alt,
    name: "Sonya Khubchandani De Castelbajac",
    designation: "BAHUT BEAUTY LLP",
    desc: "Armed with a deep love for perfumery and experience working for some of the biggest names in the business, founder Sonya Khubchandani de Castelbajac created deg & bapka, a modern-day revival of the Kannauji art of perfume-making. Discover the story behind the brand, and how Sonya plans to take forward her dream of fostering a community of perfume connoisseurs in India.",
    link: "",
    linktext: "",
    email: "",
    emailtext: "",
  },
  {
    title: "GROW",
    img: images.winner2.image,
    alt: images.winner2.alt,
    name: "Divya Malpani",
    designation: "SKINVEST",
    desc: "22-year-old founder Divya Malpani left the judges highly impressed with her exceptional confidence in both herself and the brand that she founded two years back. Now, discover more about the brand, its one-of-a-kind product line and what keeps Divya motivated as a young founder.",
    link: "https://skinvest.care/",
    linktext: "www.skinvest.care",
    email: "https://instagram.com/skinvest.skincare?igshid=ZWQyN2ExYTkwZQ==",
    emailtext: "@divya.malpani",
  },
  {
    title: "COMMUNITY VOTING",
    img: images.winner4.image,
    alt: images.winner4.alt,
    name: "Rahul Shah",
    designation: "YAAN MAN",
    desc: "With products that are luxurious, innovative, derived from nature and engineered for performance, YAAN MAN is all set to break societal tabboos surrounding men, makeup and skincare, and help their customers feel comfortable in their skin. Now, discover what made our category winner YAAN MAN a community-favorite and know more about why founder Rahul Shah conceptualized this first of its kind beauty brand.",
    link: "https://www.yaanman.in",
    linktext: "www.yaanman.in",
    email: "https://instagram.com/yaanman.official?igshid=ZWQyN2ExYTkwZQ==",
    emailtext: "@yaanman.official",
  },
];

export const prizesData = [
  {
    id: 1,
    title: "IMAGINE",
    catDesc:
      "Pre-launch Beauty Concepts in the categories of skin care, Make Up, hair care, Personal fragrance and home fragrance",
    desc: "Up to $75K",
    details: `<ul>
    <li>National and International Press support</li>
    <li>Exposure on Estée Lauder New Incubation Ventures and Nykaa Social Channels</li>
    <li>Mentorship and resource support via ELC, Nykaa and related partners</li>
    <li>Distribution for one year with<a href="https://www.nykaa.com" target="_blank"> www.nykaa.com </a></li>
    <li>Trend presentation by Intercos</li>
    <li>NPS diagnostic study with N = 500 with 1Lattice</li>
    </ul>`,
  },
  {
    id: 2,
    title: "GROW",
    catDesc:
      "In-market Beauty Concepts in the categories of skin care, Make Up, hair care, Personal fragrance and home fragrance",
    desc: "Up to $150K",
    details: `<ul>
    <li>National and International Press support</li>
    <li>Exposure on Estée Lauder New Incubation Ventures and Nykaa Social Channels</li>
    <li>Distribution for one year with<a href="https://www.nykaa.com" target="_blank"> www.nykaa.com </a></li>
    <li>Mentorship and resource support via ELC, Nykaa and related partners</li>
    <li>Trend presentation by Intercos</li>
    <li>NPS diagnostic study with N = 500 with 1Lattice</li>
    </ul>`,
  },
  {
    id: 3,
    title: "CREATE",
    catDesc:
      "The next generation of Creative Talent (e.g., photographers, filmmakers, etc.) submitting work around the theme of ‘Experiential Beauty in India",
    desc: "Up to $50K",
    details: `<ul>
    <li>National and International Press support</li>
    <li>Exposure on Estée Lauder New Incubation Ventures and Nykaa Social Channels</li>
    <li>Mentorship and resource support via ELC and related partners</li>
    <li>Trend presentation by Intercos</li>
    </ul>`,
  },
  {
    id: 4,
    title: "BREAKTHROUGH",
    catDesc:
      "Innovation in active ingredients that address key issues for the Indian consumer",
    desc: "Up to $50K",
    details: `<ul>
    <li>National and International Press support</li>
    <li>Exposure on Estée Lauder New Incubation Ventures Social Channels</li>
    <li>Mentorship and resource support via ELC and related partners</li>
    <li>Opportunity to discuss a pilot project with ELC R&D team</li>
    </ul>`,
  },
];

export const pressData = [
  {
    id: 1,
    img: images.wwdblack.image,
    alt: images.wwdblack.alt,
    date: "July 25, 2022",
    description:
      "Estée Lauder, Nykaa Team to Seek Next Global Beauty Brand in India",
    author: "BY MAYU SAINI",
    link: "#",
  },
  {
    id: 2,
    img: images.voguebusinessblack.image,
    alt: images.voguebusinessblack.alt,
    date: "July 21, 2022",
    description:
      "The next breakthrough beauty brand? Estée Lauder says it could come from India",
    author: "BY EZREEN BENISSAN",
    link: "#",
  },
  {
    id: 3,
    img: images.vogueblack.image,
    alt: images.vogueblack.alt,
    date: "August 3, 2022",
    description:
      "First beauty start-up funding program without sharing equity from Estée Lauder x Nykaa opens...",
    author: "BY MAYU SAINI",
    link: "#",
  },
  {
    id: 4,
    img: images.wwdblack.image,
    alt: images.wwdblack.alt,
    date: "July 25, 2022",
    description:
      "Estée Lauder, Nykaa Team to Seek Next Global Beauty Brand in India",
    author: "BY MAYU SAINI",
    link: "#",
  },
  {
    id: 5,
    img: images.voguebusinessblack.image,
    alt: images.voguebusinessblack.alt,
    date: "July 21, 2022",
    description:
      "The next breakthrough beauty brand? Estée Lauder says it could come from India",
    author: "BY EZREEN BENISSAN",
    link: "#",
  },
  {
    id: 6,
    img: images.vogueblack.image,
    alt: images.vogueblack.alt,
    date: "August 3, 2022",
    description:
      "First beauty start-up funding program without sharing equity from Estée Lauder x Nykaa opens...",
    author: "BY MAYU SAINI",
    link: "#",
  },
  {
    id: 7,
    img: images.wwdblack.image,
    alt: images.wwdblack.alt,
    date: "July 25, 2022",
    description:
      "Estée Lauder, Nykaa Team to Seek Next Global Beauty Brand in India",
    author: "BY MAYU SAINI",
    link: "#",
  },
  {
    id: 8,
    img: images.voguebusinessblack.image,
    alt: images.voguebusinessblack.alt,
    date: "July 21, 2022",
    description:
      "The next breakthrough beauty brand? Estée Lauder says it could come from India",
    author: "BY EZREEN BENISSAN",
    link: "#",
  },
  {
    id: 9,
    img: images.vogueblack.image,
    alt: images.vogueblack.alt,
    date: "August 3, 2022",
    description:
      "First beauty start-up funding program without sharing equity from Estée Lauder x Nykaa opens...",
    author: "BY MAYU SAINI",
    link: "#",
  },
  {
    id: 10,
    img: images.wwdblack.image,
    alt: images.wwdblack.alt,
    date: "July 25, 2022",
    description:
      "Estée Lauder, Nykaa Team to Seek Next Global Beauty Brand in India",
    author: "BY MAYU SAINI",
    link: "#",
  },
  {
    id: 11,
    img: images.voguebusinessblack.image,
    alt: images.voguebusinessblack.alt,
    date: "July 21, 2022",
    description:
      "The next breakthrough beauty brand? Estée Lauder says it could come from India",
    author: "BY EZREEN BENISSAN",
    link: "#",
  },
  {
    id: 12,
    img: images.vogueblack.image,
    alt: images.vogueblack.alt,
    date: "August 3, 2022",
    description:
      "First beauty start-up funding program without sharing equity from Estée Lauder x Nykaa opens...",
    author: "BY MAYU SAINI",
    link: "#",
  },
];
