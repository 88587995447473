import React from "react";
import "./RegisterFormStepNo.scss";

const RegisterFormStepNo = ({ currentStep, totalSteps }) => {
  return (
    <>
      <div className="step_count_wrapper">
        <div className="section_heading">REGISTRATION</div>
        <div className="step_count">
          <span className="current_step">Step {currentStep}</span>
          {currentStep > 1 && (
            <span className="total_steps"> of {totalSteps}</span>
          )}
        </div>
        <p className="section_subdesc">
          Please provide the following details to complete your registration.
        </p>
      </div>
    </>
  );
};
RegisterFormStepNo.defaultProps = {
  currentStep: "1",
  totalSteps: "12",
};
export default RegisterFormStepNo;
