import React, { useEffect, useState } from "react";
import "./exhibition.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  exhibitionBanner,
  exhibitionLeftArrow,
  exhibitionRightArrow,
  mbExhibitionBanner,
  mbVideoSec3,
  videoSec3,
} from "../../images";
import {
  imgSec4Data,
  imgSec5SliderData,
  imgSec6Data,
  imgSec7SliderData,
  imgSec9Data,
  reelData,
} from "../../data/exhibitionData";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import FsLightbox from "fslightbox-react";
import { useWindowSize } from "react-use";

const Exhibition = () => {
  const { width } = useWindowSize();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const handleWatchClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  const sec4DataList = imgSec4Data.map((item, i) => (
    <div className="col-md-6 sec4_col" key={i}>
      <div className="img_wrapper">
        <img src={item.img} alt={item.alt} className="sec4_img" />
        {item.desc !== "" ? <p className="desc">{item.desc}</p> : null}
        <p className="artist">{item.artist}</p>
      </div>
    </div>
  ));

  const sec5SliderDataList = imgSec5SliderData.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="sec5_slide_img_wrapper">
        <img
          src={width > 600 ? item.img : item.mbImg}
          alt={item.alt}
          className="sec5_slide_img"
        />
        <div className="sec5_slider_text_wrapper">
          <p className="sec5_slider_text">{item.text}</p>
          <p className="sec5_slider_artist">{item.artist}</p>
        </div>
      </div>
    </SwiperSlide>
  ));

  const sec6DataList = imgSec6Data.map((item, i) => (
    <div className="col-md-6 sec6_col" key={i}>
      <div className="img_wrapper">
        <img src={item.img} alt={item.alt} className="sec6_img" />
        {item.desc !== "" ? <p className="desc">{item.desc}</p> : null}
        <p className="artist">{item.artist}</p>
      </div>
    </div>
  ));

  const sec7SliderDataList = imgSec7SliderData.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="sec7_slide_img_wrapper">
        <img
          src={width > 600 ? item.img : item.mbImg}
          alt={item.alt}
          className="sec7_slide_img"
        />
        <div className="sec7_slider_text_wrapper">
          <p className="sec7_slider_text">{item.text}</p>
          <p className="sec7_slider_artist">{item.artist}</p>
        </div>
      </div>
    </SwiperSlide>
  ));

  const sec9DataList = imgSec9Data.map((item, i) => (
    <div className="col-md-6 sec9_col" key={i}>
      <div className="img_wrapper">
        <img src={item.img} alt={item.alt} className="sec9_img" />
        {item.desc !== "" ? <p className="desc">{item.desc}</p> : null}
        <p className="artist">{item.artist}</p>
      </div>
    </div>
  ));

  const reelList = reelData.map((item, i) => (
    <div className="reel_wrapper" key={i}>
      <span className="reel_video" onClick={() => handleWatchClick(item.video)}>
        <img
          src={width > 600 ? item.backgroundImg : item.backgroundMbImg}
          alt="reel "
          className="reel_img"
        />
      </span>
      <p className="reel_desc">{item.desc}</p>
      {width > 600 ? (
        <p
          className="reel_placeholder"
          dangerouslySetInnerHTML={{ __html: item.placeholder }}
        ></p>
      ) : null}
    </div>
  ));

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main className="blog_main">
        <section className="exhibition_sec1">
          <div className="exhibition_banner_image_wrapper">
            {width > 600 ? (
              <img
                src={exhibitionBanner}
                alt="banner"
                className="exhibition_banner_img"
                loading="lazy"
              />
            ) : (
              <img
                src={mbExhibitionBanner}
                alt="banner"
                className="exhibition_banner_img"
                loading="lazy"
              />
            )}
            <div className="exhibition_banner_content">
              <h1 className="exhibition_head">
                the finalists dinner & Exhibition:
              </h1>
              <h2 className="exhibition_subhead">
                1000 years past, <br />
                100 years future
              </h2>
            </div>
          </div>
        </section>

        <section className="exhibition_sec2">
          <div className="heading">BEAUTY IS AN EXPERIENCE</div>
          <div className="desc">
            To celebrate this year’s 2023 BEAUTY&YOU initiative, several
            creators were invited to collaborate on a first of its kind special
            exhibition held at Mumbai’s Snowball Studios in early November. The
            selected creators explored how beauty embodies and connects India’s
            past to its future, defining and refining our views on attraction,
            perception, pattern, gender, and taste on a global scale.
            <br />
            <br />
            From artists re-interpreting age-old traditions to photographers
            documenting the pure spirit of next-gen beauty, an audience of
            groundbreaking thinkers, entrepreneurs, VIPS, and this year’s awards
            finalists were immersed in new visions, new experiences, and new
            ways of creating.
          </div>
        </section>
        <section className="exhibition_sec3">
          <div className="exhibiton_img_container">
            <span
              className="reel_video"
              onClick={() =>
                handleWatchClick("https://www.youtube.com/watch?v=PWco4lSRUTs")
              }
            >
              <img
                src={width > 600 ? videoSec3 : mbVideoSec3}
                alt="image"
                className="exhibiton_img"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="161"
                height="161"
                viewBox="0 0 161 161"
                fill="none"
                className="play_button"
              >
                <circle cx="80.5" cy="80.5" r="80.5" fill="#595361" />
                <path d="M128 81L56 123.435L56 38.5648L128 81Z" fill="white" />
              </svg>
            </span>
          </div>
          <p className="img_desc">
            Opening night’s finalists' dinner where guests dined beneath
            fragrant florals and were surrounded by large-scale prints exploring
            Indian beauty as part of the exhibition.
          </p>
        </section>

        <section className="exhibition_sec4">
          <div className="row sec4_row">{sec4DataList}</div>
        </section>

        <section className="exhibition_sec5">
          <div className="exhibition_sec5Slider_wrapper">
            <Swiper
              grabCursor={true}
              modules={[Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              navigation={{
                prevEl: ".sec5_prev",
                nextEl: ".sec5_next",
              }}
              className="sec5Swiper"
            >
              {sec5SliderDataList}
            </Swiper>
            <div className="sec5_arrow_wrapper">
              <img
                src={exhibitionLeftArrow}
                alt="left arrow"
                className="sec5_prev"
              />
              <img
                src={exhibitionRightArrow}
                alt="left arrow"
                className="sec5_next"
              />
            </div>
          </div>
        </section>

        <section className="exhibition_sec6">
          <div className="row sec6_row">{sec6DataList}</div>
        </section>

        <section className="exhibition_sec7">
          <div className="exhibition_sec7Slider_wrapper">
            <Swiper
              grabCursor={true}
              modules={[Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              navigation={{
                prevEl: ".sec7_prev",
                nextEl: ".sec7_next",
              }}
              className="sec7Swiper"
            >
              {sec7SliderDataList}
            </Swiper>
            <div className="sec7_arrow_wrapper">
              <img
                src={exhibitionLeftArrow}
                alt="left arrow"
                className="sec7_prev"
              />
              <img
                src={exhibitionRightArrow}
                alt="left arrow"
                className="sec7_next"
              />
            </div>
          </div>
        </section>

        <section className="exhibition_sec9">
          <div className="row sec9_row">{sec9DataList}</div>
        </section>

        <section className="line_section">
          <div className="white_line"></div>
        </section>

        <section className="exhibition_sec11">
          <div className="desc">
            This collective experience united a community passionate about the
            future of beauty in India through art, dinner, and ceremony. It was
            an immersive journey through time, because what is beauty, if not
            evolution?
          </div>
          <div className="exhibition_concept_wrapper">
            <p className="concept_text">
              Exhibition concept by
              <br />
              IDEX & CLOSER
            </p>
            <p className="concept_text">
              Exhibition Curation by
              <br />
              Anita Chhiba Diet Paratha
            </p>
            <p className="concept_text">
              Words by
              <br />
              Akanksha Kamath
            </p>
            <p className="concept_text">
              Artists
              <br />
              Anita Chhiba, Dolly Singh, Doyel Joshi, Feat. Artists, Forest
              Essentials, Furmaan Ahmed, Gopalika Virmani Gurwara, James
              Lalthanzuala, Neil Ghose Balser, Nishanth Radhakrishnan, Rukaiya
              Daud, Shaheen Peer, Shivani Joshi, Sonya Khubchandani.
            </p>
            <p className="concept_text">
              Production by
              <br />
              Magnanimus
            </p>
          </div>
        </section>
      </main>
      <section className="exhibition_sec10">
        <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
      </section>

      <Footer />
    </>
  );
};

export default Exhibition;
