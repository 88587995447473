import React, { useEffect, useState } from "react";
import "./MeetTheWinners.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useWindowSize } from "react-use";
import {
  mbMeetWinner,
  meet2023,
  meetScrollDown,
  meetWinner,
  meetimagine,
  meetlogo,
} from "../../images";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import { Fade } from "react-awesome-reveal";
// import { FADE_DELAY } from "../../helpers/constant-words";
import { winnerData } from "../../data/MeetWinnerData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Lazy, Navigation } from "swiper";
import FsLightbox from "fslightbox-react";
import { Link } from "react-router-dom";
import { homepage } from "../../helpers/constant-words";

const MeetTheWinners = () => {
  const { width: windowWidth } = useWindowSize();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const handleWatchClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  const handleScroll = (scrollid, offset) => {
    const id = scrollid;
    const yOffset = offset;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scroll({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const winnerList = winnerData.map((item, i) => (
    <div className="row meetwinner_row mx-auto" key={i}>
      <div
        className={`col-md-6 meetwinner_class p-0 ${
          i % 2 === 0 ? "order-md-2" : "order-md-1"
        }`}
        // data-aos="fade-up"
      >
        {/* <Fade delay={FADE_DELAY}> */}
        <div>
          <div
            className="meetwinner_img_container"
            style={{ backgroundColor: item.color }}
            // data-aos="fade-up"
          >
            <img src={item.image} alt={item.alt} className="meetwinner_img" />
            <div
              className="meet_img_slider"
              style={{ backgroundColor: item.color }}
            >
              <Swiper
                loop={true}
                speed={3300}
                slidesPerView={5}
                spaceBetween={0}
                // waitForTransition={true}
                dir={"rtl"}
                autoplay={{
                  delay: 1,
                  disableOnInteraction: false,
                  // reverseDirection: true,
                  // waitForTransition:true,
                  // pauseOnMouseEnter:true,
                }}
                freeMode={true}
                allowTouchMove={false}
                loopedSlides={SwiperSlide.length}
                // loopedSlides={720}
                // watchSlideVisibility= {true}
                centeredSlides={true}
                modules={[Lazy, Autoplay, Navigation]}
                breakpoints={{
                  0: {
                    // speed: 3050,
                    slidesPerView: 5,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                  },
                  1280: {
                    slidesPerView: 5,
                    spaceBetween: 0,
                  },
                }}
              >
                <SwiperSlide>
                  <img src={meetlogo} alt="logo" className="meet_slider_logo" />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={item.scrollImg}
                    alt={item.scrollalt}
                    className={`meet_slider_imagine ${
                      item.sliderImgClasss ? item.sliderImgClasss : ""
                    }`}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={meetlogo} alt="logo" className="meet_slider_logo" />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={meet2023}
                    alt="2023 winner"
                    className="meet_slider_winner"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </div>

      <div
        className={`col-md-6 meetwinner_class p-0 
      ${i % 2 === 0 ? "order-md-1" : "order-md-2"}`}
        // data-aos="fade-up"
      >
        <div className="details_container">
          {/* <Fade delay={FADE_DELAY}> */}
          {/* <> */}
          <h3
            className="category_head"
            dangerouslySetInnerHTML={{ __html: item.category }}
            // data-aos="fade-up"
          ></h3>
          <div className="detail_wrapper">
            <p
              className="brand"
              // data-aos="fade-up"
              dangerouslySetInnerHTML={{ __html: item.brand }}
            ></p>
            <p
              className="winner_name"
              dangerouslySetInnerHTML={{ __html: item.name }}
              // data-aos="fade-up"
            ></p>
          </div>
          <h6
            className="desc"
            dangerouslySetInnerHTML={{ __html: item.desc }}
            // data-aos="fade-up"
          ></h6>

          {item.web !== "" || item.insta !== "" ? (
            <div className="meetwinner_btn_container">
              {item.web !== "" ? (
                <div className="meetwinner_btn_wrapper">
                  <a
                    href={item.web}
                    className={`project-button ${item.ctaClass}`}
                    // className="project-button"
                    rel="noreferrer"
                    target="_blank"
                  >
                    WEBSITE
                  </a>
                </div>
              ) : null}
              {item.insta !== "" ? (
                <div className="meetwinner_btn_wrapper">
                  <a
                    href={item.insta}
                    className={`project-button ${item.ctaClass}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    INSTAGRAM
                  </a>
                </div>
              ) : null}
            </div>
          ) : null}
          {item.watch !== "" ? (
            <div className="meetwinner_btn_container watch_btn_container">
              <div className="meetwinner_btn_wrapper">
                <span
                  className={`project-button ${item.ctaClass}`}
                  onClick={() => handleWatchClick(item.watch)}
                >
                  WATCH NOW
                </span>
              </div>
            </div>
          ) : null}
          {/* </> */}
          {/* </Fade>  */}
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <Header />
      {/* <Fade> */}
      <section
        className="meetwinner_sec1"
        // data-aos="fade"
      >
        {windowWidth > 992 ? (
          <>
            <div className="meetwinner_banner_image">
              <div
                className="meetwinner_image_wrapper"
                // data-aos="fade"
              >
                <img
                  src={meetWinner}
                  alt="Banner 2"
                  width={629}
                  height={532}
                  className="meetwinner_img"
                  loading="lazy"
                  // data-aos="fade"
                />
                <div className="banner_content_box1">
                  <h1
                    className="winnerbanner_heading"
                    // data-aos="fade"
                  >
                    MEET <br /> THE
                  </h1>
                </div>
                <div className="banner_content_box2">
                  <h1
                    className="winnerbanner_heading"
                    // data-aos="fade"
                  >
                    2023 <br /> WINNERS
                  </h1>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mbmeetwinner_banner_image">
              <div
                className="mbmeetwinner_image_wrapper"
                // data-aos="fade"
              >
                <img
                  src={mbMeetWinner}
                  alt="Banner 2"
                  width={335}
                  height={275}
                  className="mbmeetwinner_img"
                  loading="lazy"
                  // data-aos="fade"
                />
              </div>
              <div className="banner_content_box1">
                <h1
                  className="winnerbanner_heading"
                  // data-aos="fade"
                >
                  MEET THE <br /> 2023 WINNERS
                </h1>
                <div className="meetwinner_content_box1" id="scrollto">
                  <h4 className="meetwinnerbanner_text_1">
                    GET TO KNOW OUR WINNERS
                  </h4>
                  <img
                    src={meetScrollDown}
                    alt="scroll down arrow"
                    className="scroll_down"
                    onClick={() => handleScroll("scrollto", 120)}
                  />
                </div>
              </div>
              {/* <div className="banner_content_box2">
                <h1 className="winnerbanner_heading" data-aos="fade">
                  2023 WINNERS
                </h1>
              </div> */}
            </div>
          </>
        )}
      </section>
      {/* </Fade> */}

      {/* <Fade delay={FADE_DELAY}> */}
      <section
        className="meetwinner_sec2"
        // data-aos="fade-up"
      >
        <div className="my_container">
          <div
            className="meetwinner_content"
            // data-aos="fade-up"
          >
            <p className="meetwinner_text">
              Winners for the Breakthrough, Imagine, Grow, Create and Impact
              categories were independently chosen by our panel of judges
              through a competitive judging process on November 2nd 2023 in
              Mumbai, India. Winners for the Community category were determined
              on the basis of an independent voting process{" "}
              <Link to={homepage}>www.beautyandyouawards.com</Link> via the
              BEAUTY&YOU website and via an analysis of social engagement.
              {/* Winners for the Breakthrough, Imagine, Grow, Create and Impact
              categories were independently chosen by our panel of judges
              through a competitive judging process on November 2nd 2023 in
              Mumbai, India. Winners for the Community category were determined
              on the basis of an independent voting process via{" "}
              <Link to={homepage}>www.beautyandyouawards.com</Link> an via an
              analysis of social engagement. */}
            </p>
          </div>
        </div>
      </section>
      {/* </Fade> */}

      {/* <Fade delay={FADE_DELAY}> */}
      <section
        className="meetwinner_sec3"
        // data-aos="fade-up"
      >
        <div className="row mx-auto">
          <div className="col-md-12 p-0">{winnerList}</div>
        </div>
      </section>
      {/* </Fade> */}

      <section className="ligibox_wrapper">
        <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
      </section>

      <Footer />
    </>
  );
};

export default MeetTheWinners;
