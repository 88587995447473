import { images } from "../images";
import _ from "lodash";

export const masterclassData = [
  {
    img: images.drkiransethimasterclass.image,
    alt: images.drkiransethimasterclass.alt,
    bannerImg: images.drkiransethiinner.image,
    mobBannerImg: images.drkiransethimb.image,
    category: "EXPERT VOICES",
    name: "DR. KIRAN SETHI MD",
    slug: "dr-kiran-sethi",
    designation:
      "Celebrity Skin & Wellness Doctor, Founder of Isya Aesthetics, <br />and Author of Skin Sense",
    innerDesignation:
      "Celebrity Skin & Wellness Doctor, Founder of Isya Aesthetics, and Author of Skin Sense",
    mobDesignation:
      "Celebrity Skin & Wellness Doctor, Founder of Isya Aesthetics, and Author of Skin Sense",
    ytLink: "https://youtu.be/A0OrsQhi7Mg",
    videoId:
      "https://www.youtube.com/embed/A0OrsQhi7Mg?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["PRODUCT DEVELOPMENT"],
    year: "2024",
    description:
      "Dr Kiran Sethi, MD, is a New Delhi-based award-winning celebrity skin, aesthetic, and wellness specialist and author of the bestselling book Skin Sense. A graduate from Columbia University, Dr. Sethi is an industry pioneer in dermatology and aesthetics with certifications from the American Board of Aesthetic Medicine and the American Board of Laser Surgery. Dr Sethi is also a global trainer and speaker for BOTOX, fillers, threads, and lasers. Her arc extends from New York, where she was born and raised, to New Delhi where she currently practices from Isya Aesthetics, her multi-specialty clinic.",
  },
  {
    img: images.amritagursimasterclass.image,
    alt: images.amritagursimasterclass.alt,
    bannerImg: images.amritagursiinner.image,
    mobBannerImg: images.amritagursimb.image,
    category: "STORYTELLERS",
    name: "Amrita Khanna and Gursi Singh",
    mobName: "Amrita Khanna and <br />Gursi Singh",
    slug: "amrita-khanna-and-gursi-singh",
    designation: "Co-Founders and Creative Directors, Lovebirds",
    ytLink: "https://youtu.be/jBz0zMkyGsM",
    videoId:
      "https://www.youtube.com/embed/jBz0zMkyGsM?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["CREATIVE"],
    year: "2024",
    description:
      "Co-founders and creative directors, Amrita Khanna and Gursi Signh, wanted to ensure that Lovebirds’ clothes focusses on ethos rather than trends. Functionality, timelessness, modernity and playfulness are inherent elements of the design narrative of these creative directors. Drawing inspiration more from architecture, graphic design, vintage fashion, the brand’s creative directors create garments that adapt to an individual’s personality, thereby offering something unique, understated, and sophisticated.",
  },
  {
    img: images.jessicajaynemasterclass.image,
    alt: images.jessicajaynemasterclass.alt,
    bannerImg: images.jessicajayneinner.image,
    mobBannerImg: images.jessicajaynemb.image,
    category: "FOUNDER STORIES",
    name: "Jessica Jayne",
    slug: "jessica-jayne",
    designation: "Founder, Pahadi Local",
    ytLink: "https://youtu.be/LSbcTEWi2HQ",
    videoId:
      "https://www.youtube.com/embed/LSbcTEWi2HQ?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND"],
    year: "2024",
    description:
      "A decade ago, Jessica Jayne moved to the mountains from Mumbai and instinctively tapped into the pure and organic beauty salves and wisdom from mountain locals for city dwellers, who, like her, were moving towards conscious consumption. It started with a single bottle of Gutti Ka Tel (Apricot Kernel Oil) that Jayne poured and packed in her mother’s home seven years ago. Pahadi Local is now a pan-India business with a robust catalogue of mountain-made products distributed to over 200 skincare stores by Global SS Beauty. One of the early movers in the Indian skincare space, Pahadi Local’s clean, time-honed and efficacious formulations stand out in a presently saturated market. The portfolio spans ranges by concern and mountain ingredient. The formulations, guided by India’s prestigious Ministry of Ayurveda, showcase Himalayas’ abundant natural wealth.",
  },
  {
    img: images.gautamsethmasterclass.image,
    alt: images.gautamsethmasterclass.alt,
    bannerImg: images.gautamsethinner.image,
    mobBannerImg: images.gautamsethmb.image,
    category: "EXPERT VOICES",
    name: "Gautam Seth",
    slug: "gautam-seth",
    designation:
      "Founder & Creative Director, Atomicc Code; Co-Founder & Co-Creative <br />Director, Klove Studio",
    mobDesignation:
      "Founder & Creative Director, Atomicc Code; Co-Founder & Co-Creative Director, Klove Studio",
    ytLink: "https://youtu.be/VJgT3NtKXWA",
    videoId:
      "https://www.youtube.com/embed/VJgT3NtKXWA?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND"],
    year: "2024",
    description:
      "Gautam Seth is the founder of Atomicc Code, a global spiritual wellness and lifestyle brand, and co-founder of the award-winning New Delhi-based Klove Studio, a lighting design company known for its hand blown glass installations. For the past 20 years, Seth, who originally trained as a chemical engineer, has also been a student of numerous healing modalities. Inspired by the principles of modern Ayurveda, Atomicc Code shines light on an exciting emerging category in the luxury lifestyle space, through the lens of modern India, focussing on premium products, while also drawing from traditions of the past.",
  },
  {
    img: images.malikadattsadanimasterclass.image,
    alt: images.malikadattsadanimasterclass.alt,
    bannerImg: images.malikadattsadaniinner.image,
    mobBannerImg: images.malikadattsadanimb.image,
    category: "STORYTELLERS",
    name: "Malika Datt Sadani",
    slug: "malika-datt-sadani",
    designation: "Founder, The Moms Co.",
    ytLink: "https://youtu.be/nyBAjN8x4JQ",
    videoId:
      "https://www.youtube.com/embed/nyBAjN8x4JQ?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["PRODUCT DEVELOPMENT"],
    year: "2024",
    description:
      "An engineer and MBA graduate by qualification, Malika Datt Sadani is the Founder and CEO of The Moms Co., one of India’s leading companies for toxin-free products catering to expectant and new mothers seeking natural, chemical-free products for their daily personal care regimen. The idea for The Moms Co. came to her in 2012 when she had just moved back to India from London and couldn’t find safe products for her daughters locally. Understanding the gap in the market, she built The Moms Co. which became a category builder and pioneer in the space in a short span, serving over 2 million moms across 20,000 pin codes. In India’s largest DTC transaction to ever take place in the beauty and personal care segment, The Moms Co. was acquired by The Good Glamm Group <br />in October 2021.",
  },
  {
    img: images.radhikaghaimasterclass.image,
    alt: images.radhikaghaimasterclass.alt,
    bannerImg: images.radhikaghaiinner.image,
    mobBannerImg: images.radhikaghaimb.image,
    category: "FOUNDER STORIES",
    name: "Radhika Ghai",
    slug: "radhika-ghai",
    designation: "Founder & CEO, Kindlife; Co-Founder, ShopClues",
    innerDesignation: "Founder & CEO, Kindlife; <br />Co-Founder, ShopClues",
    ytLink: "https://youtu.be/GYsDlWfvy74",
    videoId:
      "https://www.youtube.com/embed/GYsDlWfvy74?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["PRODUCT DEVELOPMENT"],
    year: "2024",
    description:
      "Radhika Ghai is the founder and CEO at Kindlife – a new age beauty and wellness ecosystem enabling commerce, community and brands. She has earned the prestigious ‘First Lady’ award by the Ministry of Women and Child Welfare and given by the President of India for being the first woman to create a Unicorn ($1bn valuation) in India. Ghai previously founded ShopClues in 2011, to create a marketplace bringing the bazaars of India online and enabling online commerce for the 400mn consumers from tier 2 and tier 3 cities. Ghai is currently building Kindlife, India’s fastest growing wellness, nutrition and beauty platform that caters to the affluent, quality conscious and conscientious consumer.",
  },
  {
    img: images.abhabakayamasterclass.image,
    alt: images.abhabakayamasterclass.alt,
    bannerImg: images.abhabakayainner.image,
    mobBannerImg: images.abhabakayamb.image,
    category: "EXPERT VOICES",
    name: "Aabha Bakaya",
    slug: "aabha-bakaya",
    designation: "Founder, Ladies Who Lead",
    ytLink: "https://youtu.be/C1tV5JzDIpE",
    videoId:
      "https://www.youtube.com/embed/C1tV5JzDIpE?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND"],
    year: "2024",
    description:
      "Aabha Bakaya is the Founder & CEO of Ladies Who Lead, a premium members-only platform for women leaders, built with purpose to foster their professional and personal growth through shared experiences and cross-disciplinary exposure. A veteran business journalist, Bakaya has closely observed the gender biases prevalent in the business landscape. To mitigate the opportunity gaps induced by these biases, she founded Ladies Who Lead in 2021. Her vision is to build a diverse community of veteran and emerging women leaders who can co-create an equitable future for women in the workforce, and speed up meaningful change. Through Ladies Who Lead, Bakaya aims to foster a tribe of connections who can exchange a fresh perspective on their decisions and challenges, while enabling themselves to become better leaders.",
  },
  {
    img: images.maxmodestimasterclass.image,
    alt: images.maxmodestimasterclass.alt,
    bannerImg: images.maxmodestiinner.image,
    mobBannerImg: images.maxmodestimb.image,
    category: "STORYTELLERS",
    name: "Maximiliano Modesti",
    slug: "maximiliano-modesti",
    designation: "Founder, Les Ateliers 2m and Co-Founder Kalhath Institute",
    ytLink: "https://youtu.be/87iyiVsSuAI",
    videoId:
      "https://www.youtube.com/embed/87iyiVsSuAI?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING"],
    year: "2024",
    description:
      "Maximiliano Modesti began his career in Paris under Azzedine Alaïa before he came to India to research the market in 1993. After a few years of R&D, experimenting, developing samples and understanding how the local communities work, he founded Les Ateliers 2M in Paris, a design creation and development studio for fashion textiles in 1998. He is an advocate for the recognition of the Indian craft heritage and its cultural and economic significance. The Kalhath Institute, founded in 2016 under the trust supervision of Modesti, works of promoting, training, and sustaining the craft of Indian embroiderers of Uttar Pradesh.",
  },
  {
    img: images.samanthawadwanimasterclass.image,
    alt: images.samanthawadwanimasterclass.alt,
    bannerImg: images.samanthawadwaniinner.image,
    mobBannerImg: images.samanthawadwanimb.image,
    category: "FOUNDER STORIES",
    name: "Samantha Wadhwani",
    slug: "samantha-wadhwani",
    designation: "Celebrity Hairstylist and Founder, Samantha's Salon",
    innerDesignation:
      "Celebrity Hairstylist and Founder, <br/>Samantha's Salon",
    ytLink: "https://youtu.be/-hO67wTEkRw",
    videoId:
      "https://www.youtube.com/embed/-hO67wTEkRw?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["CREATIVE"],
    year: "2024",
    description:
      "Award winning celebrity hairdresser and salon owner Samantha Wadhwani trained in cut and color with Vidal Sassoon and Schwarzkopf in London, Budapest and Macau. It was Wadhwani’s love for formulas and the technical side of hairdressing that inspired her to start her eponymous salon in Bandra nearly 12 years ago.",
  },
  {
    img: images.akashshethmasterclass.image,
    alt: images.akashshethmasterclass.alt,
    bannerImg: images.akashshethinner.image,
    mobBannerImg: images.akashshethmb.image,
    category: "EXPERT VOICES",
    name: "Akash Sheth",
    slug: "akash-sheth",
    designation: "Co-founder, Magnanimous and Co-Founder, The Established",
    ytLink: "https://youtu.be/_8kOADHQtfU",
    videoId:
      "https://www.youtube.com/embed/_8kOADHQtfU?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND"],
    year: "2024",
    description:
      "Akash Sheth is the co-founder of the Magnanimous Group and the new-age digital first publication, The Established. With a passion for innovation and a keen eye for creating new opportunities, he has established himself as a prominent voice in the industry. He spearheads multiple ventures, leveraging his extensive expertise to drive growth and success across all business streams. Under his leadership, Magnanimous conceptualizes and produces some of the most path-breaking narratives in the experiential and digital space. Having worked with almost every luxury brand in the top 100 globally, Sheth and his team have built an exceptional repertoire and niche for themselves.",
  },
  {
    img: images.taramortimermasterclass.image,
    alt: images.taramortimermasterclass.alt,
    bannerImg: images.taramortimerinner.image,
    mobBannerImg: images.taramortimermb.image,
    category: "STORYTELLERS",
    name: "Tara Lal & Mortimer Chatterjee",
    slug: "tara-lal-and-mortimer-chatterjee",
    designation: "Founders, Chatterjee & Lal.",
    ytLink: "https://youtu.be/f9YkIXkmEGE",
    videoId:
      "https://www.youtube.com/embed/f9YkIXkmEGE?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["CREATIVE"],
    year: "2024",
    description:
      "Tara Lal trained as an applied artist and moved into the gallery sector after working with NGO art collectives. Having studied and worked in the UK, Mortimer Chatterjee moved to India to pursue his interest in art from the subcontinent. Chatterjee and Lal both worked for an Indian auction house before setting up their own gallery in 2003. Over the last nineteen years, the gallery has become an important node in the city’s maturing art scene.",
  },
  {
    img: images.sandhyashekarmasterclass.image,
    alt: images.sandhyashekarmasterclass.alt,
    bannerImg: images.sandhyashekarinner.image,
    mobBannerImg: images.sandhyashekarmb.image,
    category: "FOUNDER STORIES",
    name: "Sandhya Shekar",
    slug: "sandhya-shekar",
    designation: "Celebrity makeup Artist and Founder, Mokae Beauty",
    innerDesignation: "Celebrity makeup Artist and Founder, <br />Mokae Beauty",
    ytLink: "https://youtu.be/CFjMUAiZss8",
    videoId:
      "https://www.youtube.com/embed/CFjMUAiZss8?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["CREATIVE"],
    year: "2024",
    description:
      "After a career in the banking industry, Sandhya Shekar pursued her true passion for makeup artistry at the age of 25. Shekar’s clientele includes some of the biggest names in fashion and Bollywood, solidifying her status as a go-to makeup artist in the industry. Shekar was honored with the prestigious Vogue makeup Artist of the Year as well as the Elle makeup Artist of the Year awards, establishing herself as one of the leading fashion makeup artists in India. With an impressive portfolio and a reputation for excellence, Shekar continues to inspire and leave a lasting impact in the world of beauty and will soon launch her holistic beauty brand, Mokae Beauty.",
  },
  {
    img: images.riyaazamlanimasterclass.image,
    alt: images.riyaazamlanimasterclass.alt,
    bannerImg: images.riyaazamlaniinner.image,
    mobBannerImg: images.riyaazamlanimb.image,
    category: "EXPERT VOICES",
    name: "Riyaaz Amlani",
    slug: "riyaaz-amlani",
    designation:
      "Founder & Managing Director, Impresario Entertainment & Hospitality Pvt. Ltd.",
    ytLink: "https://youtu.be/YnVWUkK7nzo",
    videoId:
      "https://www.youtube.com/embed/YnVWUkK7nzo?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND"],
    year: "2024",
    description:
      "With over 20 years of experience in the food and services industry, Riyaaz Amlani, is the Founder & Managing Director of Impresario Entertainment & Hospitality Pvt. Ltd. Over the years, Amlani’s has expanded Impresario’s hospitality footprint by establishing popular restaurants, cafes and bistros across major metropolitan cities like Mumbai, Bangalore, Pune and Delhi. The company’s umbrella of brands includes SOCIAL, antiSOCIAL, Smoke House Deli, Salt Water Cafe, Flea Bazaar Café, Ishaara, Slink & Bardot, Souffle S’il Vous Plait, Mocha, Prithvi Café and The Tasting Room, among many others. Having led many successful brands himself, Amlani has also donned the hat <br />of an investor.",
  },

  // OLD MASTERCLASSES
  {
    img: images.masaba.image,
    alt: images.masaba.alt,
    bannerImg: images.masababanner.image,
    mobBannerImg: images.masabamobbanner.image,
    category: "FOUNDER STORIES",
    name: "Masaba Gupta",
    slug: "masaba-gupta",
    designation:
      "Entrepreneur, Designer, Actor, House of Masaba, Lovechild by Masaba",
    ytLink: "https://youtu.be/n6oCPXSAJyQ",
    videoId:
      "https://www.youtube.com/embed/n6oCPXSAJyQ?si=Qpz-gakwvQ1qKrAQ?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND"],
    year: "2023",
    description:
      "Masaba Gupta is as known for her vivid prints and easy silhouettes as she is for her acting skills in her biographical Netflix show Masaba Masaba. Her unique vision—of an urban India rooted in its aesthetics but timelessly modern and global in outlook—translates into her beauty brand Lovechild, known for its basics as well as trending must-haves.",
  },
  {
    img: images.pratika.image,
    alt: images.pratika.alt,
    bannerImg: images.pratikabanner.image,
    mobBannerImg: images.pratikamobbanner.image,
    category: "FOUNDER STORIES",
    name: "Pritika Swarup",
    slug: "pritika-swarup",
    designation: "Founder and Ceo, Prakti Beauty",
    ytLink: "https://youtu.be/BavBLA9jFE0",
    videoId:
      "https://www.youtube.com/embed/BavBLA9jFE0?si=jrCtsMd_hXJGlZgc?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING", "BRAND", "PRODUCT DEVELOPMENT"],
    year: "2023",
    description:
      "Pritika Swarup is a well known face on international catwalks, but it’s also her philanthropy and brand that she’s making headlines for. Unable to find similar Asian faces and solutions to her specific skin needs, she founded Prakti Beauty, a hybrid skincare brand that marries the best from traditional Ayurvedic practices with Western science-backed formulae to create a truly urban and inclusive brand. She is also the global ambassador for Operation Smile, a cleft lip and palate children's charity that kickstarted in her hometown Virginia.",
  },
  {
    img: images.swapnadutt.image,
    alt: images.swapnadutt.alt,
    bannerImg: images.swapnaduttbanner.image,
    mobBannerImg: images.swapnaduttmobbanner.image,
    category: "STORYTELLING",
    name: "Swapna Dutt Chalasani",
    slug: "swapna-dutt",
    designation: "Producer",
    ytLink: "https://youtu.be/axjWQIlczEU",
    videoId:
      "https://www.youtube.com/embed/axjWQIlczEU?si=gqn6VRCKMo8VuAc-?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING"],
    year: "2023",
    description:
      "Swapna Dutt Chalasani was born in cinema and loves making cinema. As the daughter of well known film producer C Ashwini Dutt, Swapna had the opportunity to start young, at 18 as an associate producer. Today, she produces story driven content with her own production house. Throughout her career, she has earned numerous prestigious awards, solidifying her status as a distinguished figure in the film industry.",
  },
  {
    img: images.navdeeppallapolu.image,
    alt: images.navdeeppallapolu.alt,
    bannerImg: images.navdeeppallapolubanner.image,
    mobBannerImg: images.navdeeppallapolumobbanner.image,
    category: "STORYTELLING",
    name: "Navdeep Pallapolu",
    slug: "navdeep-pallapolu",
    designation: "Actor and entrepreneur",
    ytLink: "https://youtu.be/qdlMXFkfdIg",
    videoId:
      "https://www.youtube.com/embed/qdlMXFkfdIg?si=ZksIxlzBrlcOuFmP?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING"],
    year: "2023",
    description:
      "Navdeep Pallapolu is an accomplished Indian actor, who made his debut in 2004 with the patriotic sports drama Jai. Since then, he has been a prominent figure in the film industry, working in Telugu, Tamil, and Hindi films and TV. His visionary spirit led him to establish C Space, an incubation centre that encourages aspiring writers, directors, and technicians to connect and network with like-minded individuals.",
  },
  {
    img: images.meghnaapparao.image,
    alt: images.meghnaapparao.alt,
    bannerImg: images.meghnaapparaobanner.image,
    mobBannerImg: images.meghnaapparaomobbanner.image,
    category: "EXPERT VOICES",
    name: "Meghna Apparao",
    slug: "meghna-apparao",
    designation: "Director, Meta, India",
    ytLink: "https://youtu.be/UL2TTyFnf98",
    videoId:
      "https://www.youtube.com/embed/UL2TTyFnf98?si=W2BsCp2vbZYpJKwO?loop=1&autoplay=1&mute=1",
    keywords: ["PRODUCT DEVELOPMENT", "BRAND", "STORYTELLING"],
    year: "2023",
    description:
      "As the director of Meta, India, Meghna Apparao has been instrumental in leveraging Indian content on Reels. She’s spent two decades building brands in India and internationally, and today extends that expertise into the ecommerce and retail practice working with the largest online marketplaces and platforms, as well as traditional retailers. Her talks and panels are among the most engaging and insightful, always looking towards a virtual future with limitless possibilities.",
  },
  {
    img: images.smitavallurupalli.image,
    alt: images.smitavallurupalli.alt,
    bannerImg: images.smitavallurupallibanner.image,
    mobBannerImg: images.smitavallurupallimobbanner.image,
    category: "FOUNDER STORIES",
    name: "Smita Vallurupalli",
    slug: "smita-vallurupalli",
    designation: "Singer, entrepreneur, old school",
    ytLink: "https://youtu.be/VjpayhdYpss",
    videoId:
      "https://www.youtube.com/embed/VjpayhdYpss?si=n812oYKDZVolx9uY?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING"],
    year: "2023",
    description:
      "Smita Vallurupalli is a celebrated independent artist, who started her career as a singer when she was 16. Since then she distinguished herself with accolades in both entrepreneurship and public service. From launching Indian handloom brand Aalayam to building a network of an acclaimed chain of hair salons and spas, Bubbles, she has now founded Old School, a range of beauty products inspired by tradition, ritual and time-proven ingredients.",
  },
  {
    img: images.raghavakk.image,
    alt: images.raghavakk.alt,
    bannerImg: images.raghavakkbanner.image,
    mobBannerImg: images.raghavakkmobbanner.image,
    category: "STORYTELLING",
    name: "Raghava KK",
    slug: "raghava-kk",
    designation: "Artist & entrepreneur",
    ytLink: "https://youtu.be/nNh_NJ2S4Ec",
    videoId:
      "https://www.youtube.com/embed/nNh_NJ2S4Ec?si=VBa1_kL1N-Pmaoi4?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING", "BRAND", "CREATIVE"],
    year: "2023",
    description:
      "Raghava KK is a multi-disciplinary artist who has been redefining art and the way in which the world interacts with it since he first started as a cartoonist for a newspaper. Since then, the TEDx speaker has created headlines with disruptive and thought provoking pieces like his NFT that broke records at Sotheby’s and creative collaborations with Viacom and The National Geographic Society.",
  },
  {
    img: images.roma.image,
    alt: images.roma.alt,
    bannerImg: images.romabanner.image,
    mobBannerImg: images.romamobbanner.image,
    category: "EXPERT VOICES",
    name: "Roma Dixit",
    slug: "roma-dixit",
    designation: "Director, 1lattice",
    ytLink: "https://youtu.be/Q1a9DkFiTRk",
    videoId:
      "https://www.youtube.com/embed/Q1a9DkFiTRk?si=WiVL4BTht3WdpGaj?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING", "PRODUCT DEVELOPMENT", "BRAND"],
    year: "2023",
    description:
      "Roma Dixit is a seasoned business leader, and the current Director at 1Lattice. With her background in management consulting and tenures at McKinsey, Bain, and BCG, Dixit has established an outstanding track record across a wide range of industries, specializing in the beauty and personal care sector. At 1Lattice, she works with clients across consulting, advisory and research projects involving commercial due diligence, customer insights, brand dipstick, NPS study, competitor benchmarking, micro market mapping, market sizing and more.",
  },
  {
    img: images.anjalimehta.image,
    alt: images.anjalimehta.alt,
    bannerImg: images.anjalimehtabanner.image,
    mobBannerImg: images.anjalimehtamobbanner.image,
    // category: "FOUNDER STORIES",
    category: "EXPERT VOICES",
    name: "Anjali Patel Mehta",
    slug: "anjali-patel-mehta",
    designation: "Founder, Verandah",
    ytLink: "https://youtu.be/g0TEJUTXdlY",
    videoId:
      "https://www.youtube.com/embed/g0TEJUTXdlY?si=iNazQ9oBubfixMrR?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING", "BRAND"],
    year: "2023",
    description:
      "An ex investment banker, keen traveler, golfer, beach, art and design lover, active mother of two, and alumnae of Wellesley and the Indian School of Business, Anjali Patel Mehta founded Verandah, a zero waste conscious luxury vacation label out of the need to find customised, boho-luxe, chic, fuss-free, travel separates made, using ultra soft sustainable and artisanal fabrics and hand work from India. Prior to starting her business, she spent a decade in global markets across Goldman Sachs, Fortress and Deutsche Bank and her passion for the social venture / philanthropic world drove her to spend time consulting for the New York City Department of education and Acumen Fund. Anjali is also passionate about conservation and livelihoods and also sits on the board of Tigerwatch, an anti poaching non profit in the Ranthambore Tiger reserve in Rajasthan and spends her time off in the forest, when not on the beach, playing golf or hanging out with her family; husband Chippy and children Sumer and Alekha.",
  },
  {
    img: images.jigarshahmasterclass.image,
    alt: images.jigarshahmasterclass.alt,
    bannerImg: images.jigarshahbanner.image,
    mobBannerImg: images.jigarshahmobbanner.image,
    category: "EXPERT VOICES",
    name: "Jigar Shah",
    slug: "jigar-shah",
    designation: "Venture Capitalist & Co-founder, 82°e",
    ytLink: "https://youtu.be/508FmzFnOB4",
    videoId:
      "https://www.youtube.com/embed/508FmzFnOB4?si=YQPEjMkA4Jk4xV8M?loop=1&autoplay=1&mute=1",
    keywords: ["FINANCE", "PRODUCT DEVELOPMENT", "BRAND"],
    year: "2023",
    description:
      "Jigar K Shah is a venture capitalist and the co-founder of 82°E, the holistic skincare brand with Deepika Padukone at its helm. His knack of identifying what India desires balanced with a knowledge of what the country needs makes him an astute observer of how this diverse beauty landscape unfolds.",
  },
  {
    img: images.rahul.image,
    alt: images.rahul.alt,
    bannerImg: images.rahulbanner.image,
    mobBannerImg: images.rahulmobbanner.image,
    category: "FOUNDER STORIES",
    name: "Rahul Shah",
    slug: "rahul-shah",
    designation: "Founder, Yaan Man",
    ytLink: "https://youtu.be/czSOpoomdIQ",
    videoId:
      "https://www.youtube.com/embed/czSOpoomdIQ?si=DPV8mx3iSgjn_54U?loop=1&autoplay=1&mute=1",
    keywords: ["PRODUCT DEVELOPMENT", "BRAND", "STORYTELLING"],
    year: "2023",
    description:
      "As the founder of India’s first makeup brand Yaan Man, Rahul Shah won the Beauty & You award in 2022 in the Community category. His aim is to break the societal taboo that Indian men do not need makeup and skincare products, led him to create not just an intuitive range of products, but also growing community of men who recognised that men’s skincare needs have evolved significantly and that they have every right to look good.",
  },
  {
    img: images.roseminmadhavjilisting.image,
    alt: images.roseminmadhavjilisting.alt,
    bannerImg: images.roseminmadhavjibanner.image,
    mobBannerImg: images.roseminmadhavjimobbanner.image,
    category: "EXPERT VOICES",
    name: "Rosemin Madhavji",
    slug: "rosemin-madhavji",
    designation: "Media Presenter / Brand Ambassador and Founder of Rr&co.",
    ytLink: "https://youtu.be/rO2F8MK4SS8",
    videoId:
      "https://www.youtube.com/embed/rO2F8MK4SS8?si=wjYqbTERw8g5lUiI?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING", "BRAND", "CREATIVE"],
    year: "2023",
    description:
      "Rosemin Madhavji has spent more than a decade working with the world’s top designer brands— Burberry, Juicy Couture and Gucci under the helm of Tom Ford. With her degree in Luxury Fashion Management, Madhavji extends her global expertise in Dubai.  In 2007, she founded RR&Co. Bespoke Luxury Management in Dubai, a showroom that’s now a hub for editors and celebrities for photo shoots, and a hybrid agency, liaising between European head offices and their local franchise partners. She has also hosted a Live segment “Fashion Thursdays” on Dubai One TV’s Studio One.",
  },
  {
    img: images.kshitij.image,
    alt: images.kshitij.alt,
    bannerImg: images.kshitijbanner.image,
    mobBannerImg: images.kshitijmobbanner.image,
    category: "EXPERT VOICES",
    name: "Kshitij Kankaria",
    slug: "kshitij-kankaria",
    designation: "Editor, Dirty Magazine & Creative Director, Soak",
    innerDesignation: "Editor, Dirty Magazine & Creative <br />Director, Soak",
    ytLink: "https://youtu.be/y77mh96rWTM",
    videoId:
      "https://www.youtube.com/embed/y77mh96rWTM?si=lptXkYniO-vFTHwJ?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING", "BRAND", "CREATIVE"],
    year: "2023",

    description:
      "Kshitij Kankaria is known for his unique vision and storytelling, often subversive but always relevant and provocative. From purely editorial landscapes to building brand legacies and visual pillars, Kankaria is one of the country’s most sought after visual narrators. His ability to navigate between personal vision and public enjoyment has been lauded by Indian and international brands and publications.",
  },
  {
    img: images.drharshana.image,
    alt: images.drharshana.alt,
    bannerImg: images.drharshanabanner.image,
    mobBannerImg: images.drharshanamobbanner.image,
    category: "EXPERT VOICES",
    name: "Dr Harshna Bijlani",
    slug: "dr-harshna-bijlani",
    designation: "Co-founder & Medical Head, the Ageless Clinic",
    ytLink: "https://youtu.be/n1xHcRSHmZg",
    videoId:
      "https://www.youtube.com/embed/n1xHcRSHmZg?si=ikTQS9Dc57QphG9d?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND"],
    year: "2023",

    description:
      "The Ageless Clinic was co-founded by Dr Harshna Bijlani as a medical aesthetic clinic 15 years ago to provide a wide range of services that were once difficult to access in India. Today, celebrities swear by Dr Bijlani’s consultations and treatments. Her loyal clients are aware of the cutting-edge technology her clinic always provides, along with her expertise and knowledge of what works for some and what doesn’t.",
  },
  {
    img: images.shamika.image,
    alt: images.shamika.alt,
    bannerImg: images.shamikabanner.image,
    mobBannerImg: images.shamikamobbanner.image,
    category: "FOUNDER STORIES",
    name: "Shamika Haldipurkar",
    slug: "shamika-haldipurkar",
    designation: "Founder and Ceo, D'you",
    ytLink: "https://youtu.be/RE_y-uCKslI",
    videoId:
      "https://www.youtube.com/embed/RE_y-uCKslI?si=mQqzauGXB4HmGhfx?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND", "FINANCE", "PRODUCT DEVELOPMENT"],
    year: "2023",
    description:
      "Shamika Haldipurkar is the founder of d’you, a new age skincare brand for the youth of India. Haldipurkar focuses on creating innovative high performance skincare and beauty products for the Indian consumer. Her products are targeted for specific concerns and are considered to be multitasking gamechangers.",
  },
  {
    img: images.drjaishree.image,
    alt: images.drjaishree.alt,
    bannerImg: images.drjaishreebanner.image,
    mobBannerImg: images.drjaishreemobbanner.image,
    category: "EXPERT VOICES",
    name: "Dr Jaishree Sharad",
    slug: "dr-jaishree-sharad",
    designation: "Cosmetic Dermatologist, Skinfiniti, Author",
    ytLink: "https://youtu.be/5BHhi2DkfAI",
    videoId:
      "https://www.youtube.com/embed/5BHhi2DkfAI?si=PKI9e4HL8KizFGu-?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING", "BRAND", "CREATIVE"],
    year: "2023",
    description:
      "Dr. Jaishree Sharad is a practicing cosmetic dermatologist in Mumbai for the past 23 years, and is known to be on speed dial for most Bollywood celebrities. As the author of three books along with a robust social media following, Dr Sharad makes accurate and responsible skincare very accessible and enjoyable. She is also a TEDx speaker.",
  },
  {
    img: images.hariharanlisting.image,
    alt: images.hariharanlisting.alt,
    bannerImg: images.hariharanbanner.image,
    mobBannerImg: images.hariharanmobbanner.image,
    category: "EXPERT VOICES",
    name: "Hariharan Premkumar",
    slug: "hariharan-premkumar",
    designation: "Managing Director & Head of India at Dsg Consumer Partners",
    ytLink: "https://youtu.be/mtgpZC-67Yo",
    videoId:
      "https://www.youtube.com/embed/mtgpZC-67Yo?si=uGa6FzL792xBUyZd?loop=1&autoplay=1&mute=1",
    keywords: ["FINANCE"],
    year: "2023",
    description:
      "As the India head of Southeast Asia’s first exclusively consumer brand focused fund DSG Consumer Partners, Hariharan Premkumar has spent a long time analyzing the Indian  brandscape. The fertile market has birthed a wide variety of solutions, and Premkumar has been at the forefront to identify which of them are winning ones. At DSGCP he has led investments in Super Bottoms, Sleepycat, Power Gummies, Vistarooms, Nutrova, Belora Cosmetics, XYXX, Solethreads and Supertails.",
  },
  {
    img: images.katrinakaif.image,
    alt: images.katrinakaif.alt,
    bannerImg: images.katrinabanner.image,
    mobBannerImg: images.katrinamobbanner.image,
    category: "FOUNDER STORIES",
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    designation: "Actor & Co-founder | Kay Beauty",
    ytLink: "https://youtu.be/dpVt9-N3Lbs",
    videoId:
      "https://www.youtube.com/embed/dpVt9-N3Lbs?si=7aygbqO2c3ylGA3k?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING"],
    year: "2022",
    description:
      "Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as one of India’s most loved make-up lines making her the first ever Bollywood celebrity to launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to create a diverse and inclusive brand that would resonate with makeup lovers and specifically cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award for the same.",
  },
  {
    img: images.sabyasachimukherjee.image,
    alt: images.sabyasachimukherjee.alt,
    bannerImg: images.sabyasachimukherjeebanner.image,
    mobBannerImg: images.sabyasachimukherjeemobbanner.image,
    category: "FOUNDER STORIES",
    name: "Sabyasachi Mukherjee",
    slug: "sabyasachi-mukherjee",
    designation: "Founder & Ceo | Sabyasachi",
    ytLink: "https://youtu.be/YCNAUpHDQVY",
    videoId:
      "https://www.youtube.com/embed/YCNAUpHDQVY?si=TYfzxgfKz7rPsT7r?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING"],
    year: "2022",

    description:
      "Sabyasachi is India’s largest and most influential luxury designer brand with strong Indian roots and a global perspective.",
  },
  {
    img: images.falguninayar.image,
    alt: images.falguninayar.alt,
    bannerImg: images.falguninayarbanner.image,
    mobBannerImg: images.falguninayarmobbanner.image,
    category: "FOUNDER STORIES",
    name: "Falguni Nayar",
    slug: "falguni-nayar",
    designation: "Founder & Ceo | Nykaa",
    ytLink: "https://youtu.be/6yyYMDrjpGg",
    videoId:
      "https://www.youtube.com/embed/6yyYMDrjpGg?si=knEkWOkay4IVzdW-?loop=1&autoplay=1&mute=1",
    keywords: ["FINANCE"],
    year: "2022",
    description:
      "Falguni Nayar founded Nykaa in 2012 with the vision of building a multi-brand, omni-channel beauty-focused retail business. By empowering customer choices and enabling brands to reach the breadth and depth of the country, Nykaa has emerged as India’s leading beauty retailer playing a critical role in developing the beauty market in India.",
  },
  {
    img: images.deepicamutyala.image,
    alt: images.deepicamutyala.alt,
    bannerImg: images.deepicamutyalabanner.image,
    mobBannerImg: images.deepicamutyalamobbanner.image,
    category: "FOUNDER STORIES",
    name: "Deepica Mutyala",
    slug: "deepica-mutyala",
    designation: "Ceo & Founder | Live Tinted",
    ytLink: "https://youtu.be/eHaazvnhS4o",
    videoId:
      "https://www.youtube.com/embed/eHaazvnhS4o?si=aFsA5szqbjGBCuao?loop=1&autoplay=1&mute=1",
    keywords: ["CREATIVE", "PRODUCT DEVELOPMENT"],
    year: "2022",
    description:
      "Deepica Mutyala is the founder & CEO of Live Tinted and a renowned South-Asian American beauty entrepreneur on a mission to champion multicultural beauty through community, conversation, and products.",
  },
  {
    img: images.nicolakilnera.image,
    alt: images.nicolakilnera.alt,
    bannerImg: images.nicolakilnerabanner.image,
    mobBannerImg: images.nicolakilneramobbanner.image,
    category: "FOUNDER STORIES",
    name: "Nicola Kilner",
    slug: "nicola-kilner",
    designation: "Ceo & Co-founder | Deciem",
    ytLink: "https://youtu.be/wFDMrCAy-YA",
    videoId:
      "https://www.youtube.com/embed/wFDMrCAy-YA?si=Ehg9QiVxkFf8rlui?loop=1&autoplay=1&mute=1",
    keywords: ["CREATIVE", "PRODUCT DEVELOPMENT"],
    year: "2022",

    description:
      "Famed for its commitment to innovative formulas and honest pricing, DECIEM (parent company of The Ordinary) has become known as one of the most disruptive brands in beauty for its innovative formulas, groundbreaking campaigns and commitment to innovation at every level.",
  },
  {
    img: images.mirakulkarni.image,
    alt: images.mirakulkarni.alt,
    bannerImg: images.mirakulkarnibanner.image,
    mobBannerImg: images.mirakulkarnimobbanner.image,
    category: "FOUNDER STORIES",
    name: "Mira Kulkarni",
    slug: "mira-kulkarni",
    designation: "Chief Managing Director | Forest Essentials India",
    innerDesignation: "Chief Managing Director | Forest <br />Essentials India",
    ytLink: "https://youtu.be/dvTSij4Mlfo",
    videoId:
      "https://www.youtube.com/embed/dvTSij4Mlfo?si=3dBRA1FQB0ZQxARw?loop=1&autoplay=1&mute=1",
    keywords: ["PRODUCT DEVELOPMENT"],
    year: "2022",
    description:
      "Forest Essentials is an authentic, traditional Skincare Brand with its foundations in the ancient science of Ayurveda. A pioneer of Luxurious Ayurveda, today it has become the quintessential Indian Beauty Brand that combines the ancient beauty rituals of Ayurveda with a stylish, modern aesthetic for a more relevant emphasis on efficacy, sensorial experience and pleasure of usage.",
  },
  {
    img: images.mohitdhawan.image,
    alt: images.mohitdhawan.alt,
    bannerImg: images.mohitdhawanbanner.image,
    mobBannerImg: images.mohitdhawanmobbanner.image,
    category: "EXPERT VOICES",
    name: "Mohit Dhawan",
    slug: "mohit-dhawan",
    designation:
      "Senior Vice President and Head, Investments, M&a and Strategy | Hero Enterprises",
    ytLink: "https://youtu.be/trz80ogZAh4",
    videoId:
      "https://www.youtube.com/embed/trz80ogZAh4?si=kz_V1hHqCUtu3-i4?loop=1&autoplay=1&mute=1",
    keywords: ["FINANCE"],
    year: "2022",
    description:
      "Mohit Dhawan leads the Investment Office at Hero Enterprise since 2018. Hero Investment Office invests in omni-channel consumer businesses, financial services, healthcare delivery and technology enabled manufacturing and logistics businesses. In addition to Investment Office, Mohit also leads the group level mergers and acquisitions and capital raising activities.",
  },
  {
    img: images.diipabullerkhosla.image,
    alt: images.diipabullerkhosla.alt,
    bannerImg: images.diipabullerkhoslabanner.image,
    mobBannerImg: images.diipabullerkhoslamobbanner.image,
    category: "FOUNDER STORIES",
    name: "Diipa Büller-Khosla",
    slug: "diipa-buller-khosla",
    designation: "Founder | Indē Wild",
    ytLink: "https://youtu.be/M5Qh2mvXerA",
    videoId:
      "https://www.youtube.com/embed/M5Qh2mvXerA?si=XnrNVy5jwfylYjZN?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND", "STORYTELLING"],
    year: "2022",
    description:
      "Diipa Büller-Khosla is the founder of Indē Wild, a skincare brand combining timeless superfood ingredients rooted in Ayurveda and revolutionary conscious chemistry.",
  },
  {
    img: images.drewelliott.image,
    alt: images.drewelliott.alt,
    bannerImg: images.drewelliottbanner.image,
    mobBannerImg: images.drewelliottmobbanner.image,
    category: "expert voices",
    name: "Drew Elliott",
    slug: "drew-elliott",
    designation: "SVP, Global Creative Director | M·A·C Cosmetics",
    innerDesignation: "SVP, Global Creative Director | <br />M·A·C Cosmetics",
    ytLink: "https://youtu.be/s4vbiwe3Nhs",
    videoId:
      "https://www.youtube.com/embed/s4vbiwe3Nhs?si=zEwgsZATZRm-dO4L?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND", "PRODUCT DEVELOPMENT"],
    year: "2022",
    description:
      "Drew Elliott is SVP, Global Creative Director for M·A·C (Make-up Art Cosmetics). M·A·C is the pioneering makeup authority for all. Since its creation in Toronto, Canada, over 30 years ago, the brand’s popularity has grown through a tradition of word-of-mouth endorsement from makeup artists, models, photographers and journalists around the world.",
  },
  {
    img: images.ryanpiela.image,
    alt: images.ryanpiela.alt,
    bannerImg: images.ryanpielabanner.image,
    mobBannerImg: images.ryanpielamobbanner.image,
    category: "EXPERT VOICES",
    name: "Ryan Piela",
    slug: "ryan-piela",
    designation:
      "Ryan Piela Executive Director | The Estée Lauder Companies' New Incubation Ventures Team",
    innerDesignation:
      "Ryan Piela Executive Director | The Estée Lauder Companies' New Incubation <br />Ventures Team",
    ytLink: "https://youtu.be/4clPyXkScV0",
    videoId:
      "https://www.youtube.com/embed/4clPyXkScV0?si=khGD0-9xGeYkzhhW?loop=1&autoplay=1&mute=1",
    keywords: ["FINANCE"],
    year: "2022",
    description:
      "Ryan Piela is currently the Executive Director in The Estée Lauder Companies' New Incubation Ventures team, where he's helping build and execute on the early-stage investment and incubation strategy. Prior to Estée, Ryan worked both in venture and as a consultant advising Fortune 500 companies on innovation, startup disruption, and venture capital.",
  },
  {
    img: images.prachipawar.image,
    alt: images.prachipawar.alt,
    bannerImg: images.prachipawarbanner.image,
    mobBannerImg: images.prachipawarmobbanner.image,
    category: "EXPERT VOICES",
    name: "Prachi Pawar",
    slug: "prachi-pawar",
    designation: "Vice President | Sequoia India",
    ytLink: "https://youtu.be/a2VrUqnLlB4",
    videoId:
      "https://www.youtube.com/embed/a2VrUqnLlB4?si=gSfN59ZjstslTs4t?loop=1&autoplay=1&mute=1",
    keywords: ["FINANCE"],
    year: "2022",
    description:
      "Prachi Pawar works with companies in the consumer internet, agri tech and gaming sectors in India. Prior to joining Sequoia India, she headed category and supply operations at fashion rental startup, Flyrobe in Mumbai and was a management consultant with Deloitte Consulting where she worked with media and tech clients.",
  },
  {
    img: images.rohanvaziralli.image,
    alt: images.rohanvaziralli.alt,
    bannerImg: images.rohanvazirallibanner.image,
    mobBannerImg: images.rohanvazirallimobbanner.image,
    category: "EXPERT VOICES",
    name: "Rohan Vaziralli",
    slug: "rohan-vaziralli",
    designation: "General Manager | ELCA Cosmetics",
    ytLink: "https://youtu.be/zJ-O4CB77BI",
    videoId:
      "https://www.youtube.com/embed/zJ-O4CB77BI?si=hNb9hPRENBv19Rvp?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND"],
    year: "2022",
    description:
      "Rohan Vaziralli is the General Manager at ELCA Cosmetics (the India affiliate of The Estée Lauder Companies), with 23 years in Prestige Cosmetics with experience ranging in Manufacturing, Supply Chain, Business Development and Affiliate Development.",
  },
  {
    img: images.ingetheron.image,
    alt: images.ingetheron.alt,
    bannerImg: images.ingetheronbanner.image,
    mobBannerImg: images.ingetheronmobbanner.image,
    category: "FOUNDER STORIES",
    name: "Inge Theron",
    slug: "inge-theron",
    designation: "Founder and Creative Director | Facegym",
    ytLink: "https://youtu.be/IGDZBB_yBkw",
    videoId:
      "https://www.youtube.com/embed/IGDZBB_yBkw?si=ql8VXllN_b2ZNeMP?loop=1&autoplay=1&mute=1",
    keywords: ["BRAND", "STORYTELLING"],
    year: "2022",
    description:
      "Inge Theron, Founder and Creative Director of FaceGym, is a woman on a mission to educate a generation on skin health, skin metabolism and facial fitness. Firmly establishing herself as a disruptive female entrepreneur, Theron has created one of the world’s fastest growing brands. Uniquely positioned at the intersection of beauty, wellness, and fitness, FaceGym is a world first. A revolutionary, never-seen-before concept, FaceGym is the only gym studio for the face, powered by clinically proven, award-winning skincare, high-performance tool innovations and one-of-a-kind, skin-transforming workout moves.",
  },
  {
    img: images.aksahmehta.image,
    alt: images.aksahmehta.alt,
    bannerImg: images.aksahmehtabanner.image,
    mobBannerImg: images.aksahmehtamobbanner.image,
    category: "FOUNDER STORIES",
    name: "Akash Mehta",
    slug: "akash-mehta",
    designation: "CEO & Co-founder | Fable & Mane",
    ytLink: "https://youtu.be/88_wW3U-YQI",
    videoId:
      "https://www.youtube.com/embed/88_wW3U-YQI?si=cooKnC10eEbyjGGB?loop=1&autoplay=1&mute=1",
    keywords: ["STORYTELLING", "PRODUCT DEVELOPMENT"],
    year: "2022",
    description:
      "Fable and Mane is a modern hair wellness brand of potent plant-based products inspired by ancient Indian beauty secrets. Founded by Akash and Nikita Mehta, a duo of plant-powered siblings who are on a mission to bring ancient Ayurvedic wisdom to Western beauty, Fable & Mane is formed from personal experience and heritage. All products are cruelty-free, vegan, silicone-free and formulated to enrich the hair as well as spirit. Beyond formulating responsibly, the Mehta siblings are committed to giving back to the wild through ‘The Fable Fund’ which focuses its efforts on tiger conservation and wildlife education.",
  },
  {
    img: images.shankarprasad.image,
    alt: images.shankarprasad.alt,
    bannerImg: images.shankarprasadbanner.image,
    mobBannerImg: images.shankarprasadmobbanner.image,
    category: "FOUNDER STORIES",
    name: "Shankar Prasad",
    slug: "shankar-prasad",
    designation: "Founder & CEO | Plum",
    ytLink: "https://youtu.be/RFW_et6Cfn4",
    videoId:
      "https://www.youtube.com/embed/RFW_et6Cfn4?si=S90xOs8W1y-sVECz?loop=1&autoplay=1&mute=1",
    keywords: ["PRODUCT DEVELOPMENT"],
    year: "2022",
    description:
      "Plum is a leading, 100% vegan, cruelty-free & toxin-free beauty brand with a strong portfolio in skincare, haircare, personal care, and makeup. The brand is driven by a strong leadership & investor team focused on building value for - People, the Planet, and Profit-sharing participants.",
  },
  {
    img: images.rohitchawla.image,
    alt: images.rohitchawla.alt,
    bannerImg: images.rohitchawlabanner.image,
    mobBannerImg: images.rohitchawlamobbanner.image,
    category: "EXPERT VOICES",
    name: "Rohit Chawla",
    slug: "rohit-chawla",
    designation: "Founder & CEO | Innovist",
    ytLink: "https://youtu.be/uSuqAJ-Q7Bw",
    videoId:
      "https://www.youtube.com/embed/uSuqAJ-Q7Bw?si=1eIVunCik_k2-Ifj?loop=1&autoplay=1&mute=1",
    keywords: ["PRODUCT DEVELOPMENT"],
    year: "2022",
    description:
      "Rohit Chawla is the founder and CEO of Innovist. Innovist is a science-first house of brands focused on beauty & personal care. It has built some of the fastest growing D2C brands in India such as Bare Anatomy (hair care) Chemist at Play (Skincare) and SunScoop (Sunscreen).",
  },
  {
    img: images.jaffreyzamanjudgelisting.image,
    alt: images.jaffreyzamanjudge.alt,
    bannerImg: images.jaffreyzamanbanner.image,
    mobBannerImg: images.jaffreyzamanmobbanner.image,
    category: "EXPERT VOICES",
    name: "Jaffrey Zaman",
    slug: "jaffrey-zaman",
    designation: "Managing Director, Intercos India",
    ytLink: "https://youtu.be/O9rWZMUoM4g",
    videoId:
      "https://www.youtube.com/embed/O9rWZMUoM4g?si=pxN1nBtzC7BGluYH?loop=1&autoplay=1&mute=1",
    keywords: ["PRODUCT DEVELOPMENT"],
    year: "2022",
    description:
      "As the Managing Director of Intercos India, Jaffrey Zaman is passionate about all things beauty. From product to packaging, from storytelling to marketing, Intercos is an international behemoth, manufacturing colour cosmetics, nail polish, skincare, personal care and haircare across the world. Within it Mr Zaman has been enthusiastic about tracking the growth of beauty in the ever evolving Indian market.",
  },
  {
    img: images.anjulaacharia.image,
    alt: images.anjulaacharia.alt,
    bannerImg: images.anjulaachariabanner.image,
    mobBannerImg: images.anjulaachariamobbanner.image,
    category: "FOUNDER STORIES",
    name: "Anjula Acharia",
    slug: "anjula-acharia",
    designation: "Founder & CEO | A-Series Investments and Management",
    innerDesignation: "Founder & CEO | A-Series Investments <br />and Management",
    ytLink: "https://youtu.be/fCTAJp1evAw",
    videoId:
      "https://www.youtube.com/embed/fCTAJp1evAw?si=QlmJI2W1ogM_dCGx?loop=1&autoplay=1&mute=1",
    keywords: ["FINANCE", "BRAND"],
    year: "2022",
    description:
      "Anjula Acharia is the founder and CEO of A-Series Investments and Management. Acharia works as a talent strategist and strategic investor and advisor to a multitude of consumer tech and CPG startups, including two early stage investments which have grown into billion-dollar unicorns.",
  },
];

export const categoriesData = [
  "all",
  "FOUNDER STORIES",
  "EXPERT VOICES",
  "STORYTELLERS",
];

export const keywordsData = [
  "all",
  "FINANCE",
  "PRODUCT DEVELOPMENT",
  "STORYTELLING",
  "BRAND",
  "CREATIVE",
];
export const yearsData = ["all", "2024", "2023", "2022"];

export const find = (categories = [], keywords = [], years = []) => {
  console.log(categories, keywords);
  const filteredProducts = _.filter(masterclassData, (data) => {
    const hasCategory =
      _.includes(categories, "all") || _.includes(categories, data.category);
    const hasYear = _.includes(years, "all") || _.includes(years, data.year);
    const hasKeywords =
      _.includes(keywords, "all") ||
      _.intersection(keywords, data.keywords).length > 0;
    return hasCategory && hasKeywords && hasYear;
  });

  return filteredProducts;
};

export const findBySlug = (slug) => {
  return _.find(masterclassData, { slug: slug });
};
