import { finalist2024URL } from "../helpers/constant-words";
import { createArrow, growArrow, images, imagineArrow } from "../images";
import _ from "lodash";

// dynamic data start
export const votingCategoriesArr = [
  {
    id: 1,
    title: "community voting",
    subTitle: "Grow award",
    desc: `IMAGINE finalists are pre-launch companies that are in the
    process of finalizing their businesses and brands. Please click
    through the image carousel for each finalist to view their full
    company video`,
    slug: "grow",
    templateColor: "#9fa6d4",
    arrowColor: growArrow,
  },
  {
    id: 2,
    title: "community voting",
    subTitle: "imagine award",
    desc: `IMAGINE finalists are pre-launch companies that are in the
    process of finalizing their businesses and brands. Please click
    through the image carousel for each finalist to view their full
    company video`,
    slug: "imagine",
    templateColor: "#ff6b20",
    arrowColor: imagineArrow,
  },
  {
    id: 3,
    title: "community voting",
    subTitle: "Create award",
    desc: `IMAGINE finalists are pre-launch companies that are in the
    process of finalizing their businesses and brands. Please click
    through the image carousel for each finalist to view their full
    company video`,
    slug: "create",
    templateColor: "#fdbc00",
    arrowColor: createArrow,
  },
];

export const votingArr = [
  {
    id: 1,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 2,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 3,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine3.image,
    alt: images.imagine3.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 4,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine4.image,
    alt: images.imagine4.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 5,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine5.image,
    alt: images.imagine5.alt,
    heroImg: images.imaginevoting1.image,
    mbimg: images.mbimagine1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },

  {
    id: 6,
    category_id: 1,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 7,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 8,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 9,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 10,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.growvoting1.image,
    mbimg: images.mbgrow1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },

  {
    id: 11,
    category_id: 2,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 12,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 13,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 14,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 15,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 16,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 17,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
  {
    id: 18,
    category_id: 3,
    name: "Katrina Kaif",
    slug: "katrina-kaif",
    comapnyName: "beautywise",
    img: images.imagine2.image,
    alt: images.imagine2.alt,
    heroImg: images.createvoting1.image,
    mbimg: images.mbcreate1.image,
    desg: "FOUNDER & CEO | SABYASACHI",
    desc: `Amongst India’s most bankable celebrities, Katrina is Bollywood’s number one actress
     and has a knack for the number game. The superstar-turned-entrepreneurial genius, Katrina Kaif 
     established her own beauty brand in 2019; ‘Kay Beauty’ which has fast gained popularity as 
     one of India’s most loved make-up lines making her the first ever Bollywood celebrity to 
     launch their own beauty line. With her own line, she believes the aim with Kay Beauty is to
      create a diverse and inclusive brand that would resonate with makeup lovers and specifically 
      cater to Asian skin requirements. Katrina has won the “Beauty Entrepreneur of the Year award 
      for the same.`,
    // info: imagineURL,
    watch: "https://www.youtube.com/embed/YCNAUpHDQVY",
    web: "javascript:void(0)",
    insta: "javascript:void(0)",
  },
];
// dynamic data end

export const findAllVoting = (category_slug = null) => {
  if (category_slug) {
    const category = findCategoryWhere({ slug: category_slug });
    return _.filter(votingArr, { category_id: category[0]?.id });
  } else {
    return votingArr;
  }
};

export const getHeaderVoting = (category_id = null) => {
  const category = findCategoryWhere({ id: category_id });
  return _.filter(votingArr, { category_id: category_id }).map((item) => {
    return {
      title: item.title,
      slug: `${finalist2024URL}/${category?.[0]?.slug}/${item.slug}`,
    };
  });
};

export const findVotingBySlug = (slug) => {
  return _.find(votingArr, { slug: slug });
};

export const findCategoryBySlug = (slug) => {
  return _.find(votingCategoriesArr, { slug: slug });
};

export const findCategoryWhere = (filter = {}) => {
  return _.filter(votingCategoriesArr, filter);
};
export const findVotingWhere = (filter = {}) => {
  return _.filter(votingArr, filter);
};
