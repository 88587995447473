import React, { useState, useContext, useEffect } from "react";
import "../RegisterForm.scss";
import RegisterFormStepNo from "../../../components/RegisterFormStepNo/RegisterFormStepNo";
import LogoutText from "../../../components/LogoutText/LogoutText";
import Joi from "joi";
import http from "../../../helpers/http";
import config from "../../../config/config";
import userContext from "../../../context/User/UserContext";

const productCategories = [
  {
    categoryName: "Acne",
  },
  {
    categoryName: "Hyperpigmentation",
  },
  {
    categoryName: "Dark Circles",
  },
  {
    categoryName: "Inflammation",
  },
  {
    categoryName: "Skin Texture",
  },
  {
    categoryName: "Anti-Aging",
  },
  {
    categoryName: "Anti-Pollution",
  },
  {
    categoryName: "Microbiome",
  },
  {
    categoryName: "Fermentation",
  },
  {
    categoryName: "Skin Physiology",
  },
  {
    categoryName: "Fragrance and sensory",
  },
  {
    categoryName: "Specific Indian-based ingredients or sources",
  },
  {
    categoryName: "Other",
  },
];
var selectedProductCategories;

const Step23 = ({
  previousStep,
  currentStep,
  nextStep,
  goToStep,
  handleFormBgColor,
}) => {
  const [checkProductCategoryState, setCheckProductCategoryState] = useState(
    []
  );
  const [proCateOther, setProCateOther] = useState(false);
  const handleProductCategoryCheckboxChange = (position) => {
    console.log({ position });
    selectedProductCategories = [];
    const updatedCheckProductCategoryState = checkProductCategoryState.map(
      (item, index) => (index === position ? !item : item)
    );

    setCheckProductCategoryState(updatedCheckProductCategoryState);

    updatedCheckProductCategoryState.forEach((item, index) => {
      if (item) {
        selectedProductCategories.push(
          productCategories[index]["categoryName"]
        );
      }
    });

    setProCateOther(selectedProductCategories.includes("Other"));

    setValues((prevState) => {
      return {
        ...prevState,
        ["addressing_ingredient"]: selectedProductCategories.join(","),
      };
    });
  };
  const User = useContext(userContext);
  const [values, setValues] = useState({
    addressing_ingredient: User.data.addressing_ingredient,

    other_addressing_ingredient: User.data.other_addressing_ingredient,
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues({
      addressing_ingredient: User.data.addressing_ingredient,

      other_addressing_ingredient: User.data.other_addressing_ingredient,
    });
  }, [currentStep]);
  let base_url = config.api_url + "2024/forms/registration/" + User.data._id;

  const validate = async (data) => {
    let schemaObj = {
      addressing_ingredient: Joi.string().required().label("This"),
    };

    if (data.addressing_ingredient.split(",").includes("Other")) {
      schemaObj.other_addressing_ingredient = Joi.string()
        .trim()
        .required()
        .label("This");
    }

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = await validate(values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    let form_values = values;

    if (!form_values.addressing_ingredient.split(",").includes("Other")) {
      form_values.other_addressing_ingredient = "";
    }

    setLoading(true);
    let status = currentStep + 1;
    const { data } = await http.put(base_url, {
      ...form_values,
      status: status,
    });

    if (data) {
      User.update({ ...User.data, ...form_values, status: status });
      // handleFormBgColor(dirtColor);
      nextStep();
      window.scrollTo(0, 0);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    let dbCategory = User.data.addressing_ingredient.split(",");
    let dbSelCat = productCategories.map(({ categoryName }) => {
      return dbCategory.includes(categoryName);
    });

    setCheckProductCategoryState(dbSelCat);

    setProCateOther(dbCategory.includes("Other"));
  }, [currentStep]);

  return (
    <>
      <div className="form_wrapper">
        <RegisterFormStepNo currentStep={currentStep - 19} totalSteps={9} />
        <form onSubmit={handleSubmit}>
          <div className="form_flex">
            <div className="form_field_wrapper form_field_wrapper_full_width">
              <p className="section_subdesc word_break">
                What issue area or product process / technology does your active
                ingredient address? (choose any that apply)*
              </p>
              <div className="radio_row_flex">
                {productCategories.map(({ categoryName }, index) => (
                  <div className="checkbox_input_wrapper" key={index}>
                    <input
                      type="checkbox"
                      id={`breakproductCat-checkbox-${index}`}
                      value={categoryName}
                      name={categoryName}
                      className="checkox_input"
                      checked={checkProductCategoryState[index]}
                      onChange={() =>
                        handleProductCategoryCheckboxChange(index)
                      }
                    />
                    <label
                      htmlFor={`breakproductCat-checkbox-${index}`}
                      className="checkbox_label"
                    >
                      <span className="checkbox_outer">
                        <span className="checkbox_right_tick"></span>
                      </span>
                      <span className="label_text">{categoryName}</span>{" "}
                    </label>
                  </div>
                ))}
              </div>
              {/* <div className="checkbox_input_wrapper">
                <input
                  type="checkbox"
                  id="proCateOther"
                  value="Other"
                  name="Other"
                  className="checkox_input"
                  checked={proCateOther}
                  onChange={() => setProCateOther(!proCateOther)}
                />
                <label htmlFor="proCateOther" className="checkbox_label">
                  <span className="checkbox_outer">
                    <span className="checkbox_right_tick"></span>
                  </span>
                  <span className="label_text">Other</span>{" "}
                </label>
              </div> */}
              <p className="error_message error_zero_padding">
                {formError.addressing_ingredient}
              </p>
            </div>
            {/* {windowWidth >= 767 ? (
              <div className="form_field_wrapper"></div>
            ) : null} */}
            {/* <div
              className={`form_field_wrapper ${
                !proCateOther ? "form_field_wrapper_margin_zero" : null
              } `}
            > */}
            <div className="form_field_wrapper">
              {proCateOther && (
                <>
                  <input
                    type="text"
                    name="other_addressing_ingredient"
                    value={values.other_addressing_ingredient}
                    onChange={handleChange}
                    className="form_input"
                    placeholder="If you selected Other, please describe"
                  />
                  <p className="error_message">
                    {formError.other_addressing_ingredient}
                  </p>
                </>
              )}
            </div>
            {/* </div> */}

            <div className="form_field_wrapper form_field_wrapper_full_width">
              <div className="btn_wrapper">
                <button
                  className="project-button form_cta black-filled"
                  type="submit"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading ? "SAVING.." : "Next Step"}
                </button>
                <span
                  className="project-button form_cta"
                  onClick={() => {
                    previousStep();
                    window.scrollTo(0, 0);
                  }}
                >
                  PREVIOUS STEP
                </span>
              </div>
              <LogoutText />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step23;
