// common
export { default as arrowleft } from "./common/arrowleft.svg";
export { default as arrowright } from "./common/arrowright.svg";
export { default as accordiondown } from "./common/accordion-down.svg";
export { default as accordionup } from "./common/accordion-up.svg";
export { default as commonfooterstrip } from "./common/commonfooterstrip.svg";
export { default as whitecommonstrip } from "./common/white-commonfooter.svg";
export { default as idexfooter } from "./common/IDEX_footer.svg";
export { default as brandlogowhite } from "./common/brandlogowhite.svg";
export { default as calenderlogo } from "./common/calenderlogo.svg";
export { default as dropddownicon } from "./common/arrow.svg";
export { default as inputdownarrow } from "./common/dropdown-red.svg";
export { default as fblogo } from "./common/fblogo.svg";
export { default as instalogo } from "./common/instalogo.svg";
export { default as linkedinlogo } from "./common/linkedinlogo.svg";
export { default as instalogowhite } from "./common/instalogo-white.svg";
export { default as linkedinlogowhite } from "./common/linkedinlogo-white.svg";
export { default as tiktoklogo } from "./common/tiktoklogo.svg";
export { default as snaplogo } from "./common/snaplogo.svg";
export { default as ytlogo } from "./common/ytlogo.svg";
export { default as ytlogowhite } from "./common/ytlogo-white.svg";
export { default as percentLogo } from "./common/percentLogo.svg";
export { default as calenlogo } from "./common/calenlogo.svg";
export { default as finalistsright } from "./common/finalists-arrow-right.svg";
export { default as finalistsleft } from "./common/finalists-arrow-left.svg";
export { default as crossclose } from "./common/cross-close.svg";
export { default as programpolicypdf } from "./common/2024_final_program_rules_Sept_2_2024.pdf";
export { default as termsandconditionsPDF } from "./common/2024-15-july-terms-of-use.pdf";
export { default as whitelogo } from "./common/BNY-white-logo.svg";
export { default as blacklogo } from "./common/BNY-black-logo.svg";
export { default as navyleft } from "./common/navy-nav-left.svg";
export { default as navyright } from "./common/navy-nav-right.svg";
export { default as whiteleft } from "./common/white-nav-left.svg";
export { default as whiteright } from "./common/white-nav-right.svg";
export { default as masternavright } from "./common/m-nav-right.svg";
export { default as masternavleft } from "./common/m-nav-left.svg";
export { default as greenLogo } from "./common/brandlogogreen.svg";
export { default as nykaalogo } from "./common/nykaa-logo.svg";
export { default as hamburgeropen } from "./common/hamburger-open.svg";
export { default as hamburgerclose } from "./common/hamburger-close.svg";

// home page images
export { default as bofIcon } from "./home/bof-icon.svg";

// winners page images
export { default as winnerbanimg } from "./winners/winners_banimg.gif";
export { default as winner } from "./winners/winner_1.png";
export { default as mbwinnerbanner } from "./winners/mb_banner.gif";

// Meet the winners
export { default as meetWinner } from "./meetwinners/meetwinner_2.png";
export { default as mbMeetWinner } from "./meetwinners/mb_meetwinner_2.png";
export { default as meetScrollDown } from "./meetwinners/meet_down_arrow.png";
export { default as meetlogo } from "./meetwinners/logo.png";
export { default as meetimagine } from "./meetwinners/imagin_img.png";
export { default as meet2023 } from "./meetwinners/2023_winner.png";

// winners2024 page images
export { default as winnersBanner } from "./winners2024/winners_banner.jpg";
export { default as mbWinnersBanner } from "./winners2024/mb_winners_banner.jpg";
export { default as winners2024Banner } from "./meet-winners-2024/banner.jpg";
export { default as mbWinners2024Banner } from "./meet-winners-2024/mb_banner.jpg";

// prizes page images
export { default as prizebanimg } from "./prizes/Prizes_banner.jpg";
export { default as mbprizerbanner } from "./prizes/mb_prizebanner.jpg";
export { default as leftLine } from "./prizes/arrow_line.png";
export { default as rightLine } from "./prizes/arrow_line.png";
export { default as rightArrow } from "./prizes/Right_arrow.png";
export { default as leftArrow } from "./prizes/left_arrow.png";
export { default as paginationicon } from "./prizes/pagination_icon.png";
export { default as prizeBeautyLogo } from "./prizes/b&y_black_logo.svg";

// press page images
export { default as pressbanimg } from "./press/press_banner.jpg";
export { default as mbpressbanner } from "./press/mbpress_banner.jpg";
export { default as selectdownarrow } from "./press/down_arrow.png";
export { default as bnypressdoc } from "./press/bw-beautyandyou-press-release-20236723-9am-estak-editsclean.pdf";
export { default as bnypressdocNew } from "./press/2023_Beauty_Insights.pdf";

//judges page images
export { default as plusiconblack } from "./judges/plus-icon-black.svg";

// homepage
export { default as insta } from "./homepage/insta.svg";
export { default as linke } from "./homepage/linke.svg";
export { default as demovideo } from "./homepage/demo-video.mp4";
export { default as bannerVideo } from "./homepage/banner_video.gif";
export { default as mbbannerVideo } from "./homepage/banner_video_mb.gif";

// Masterclass
export { default as masterclassrightarrow } from "./masterclass/right-arrow.svg";
export { default as sliderightarrow } from "./masterclass/right-arrow-masterclass.svg";
export { default as slideleftarrow } from "./masterclass/left-arrow-masterclass.svg";

// FAQ page
export { default as faqbanner } from "./faq/faq_banner.jpg";
export { default as mbfaqbanner } from "./faq/faq_mbbanner.jpg";
export { default as faqopen } from "./faq/faq_open.png";
export { default as faqClose } from "./faq/faq_close.png";

// aboutus page
export { default as aboutBanner } from "./about-us/about_us_banner_1.jpg";
export { default as mbAboutBanner } from "./about-us/mb_about_us_banner_1.jpg";

// finalist page
export { default as finalistBanner } from "./finalist/finalist2024/finalist_banner.jpg";
export { default as mbFinalistBanner } from "./finalist/finalist2024/mb_finalist_banner.png";
export { default as finalist2024Banner } from "./finalist/finalist2024/2024-finalists-banner.png";
export { default as mbFinalist2024Banner } from "./finalist/finalist2024/2024-finalists-banner-mb.png";
export { default as finalist } from "./finalist/finalist_desktop_banner.jpg";
export { default as mbFinalist } from "./finalist/Finalists_GIF_GRID_Template_mobile_v2_2.gif";
export { default as finalistDownArrow } from "./finalist/finalist_down_arrow.png";
export { default as imagine1 } from "./finalist/imagine_1.png";
export { default as finalistRight } from "./finalist/right_arrow.svg";
// export { default as finalistGif } from "./finalist/finalist.gif";

// finalist innerpage
export { default as imagineFinalist } from "./finalist/imagine_finalist_1.png";
export { default as imagineArrow } from "./finalist/imagine.svg";
export { default as growArrow } from "./finalist/grow.svg";
export { default as breakArrow } from "./finalist/break.svg";
export { default as createArrow } from "./finalist/create.svg";

// finalist page
export { default as voting } from "./voting/community_voting_desktop_1.png";
export { default as mbvoting } from "./voting/community_voting_mobile_1.png";
export { default as votingDownArrow } from "./voting/voting_down_arrow.png";

// voting
export { default as votingClose } from "./voting/close.png";
export { default as closeSVG } from "./voting/close-icon.svg";

// awardsevening

export { default as awardsBanner } from "./awardsevening/award_banner_1.jpg";
export { default as mbAwardsBanner } from "./awardsevening/mb_awards_banner_2.jpg";
export { default as awardVideoSec3 } from "./awardsevening/award_sec3_banner.jpg";
export { default as mbAwardVideoSec3 } from "./awardsevening/mb_award_sec3_banner.jpg";
export { default as awardsEveningLeftArrow } from "./awardsevening/left_arrow.png";
export { default as awardsEveningRightArrow } from "./awardsevening/right_arrow.png";

// luminaries
export { default as lumiBanner } from "./luminaries/lumi_banner.jpg";
export { default as mbLumiBanner } from "./luminaries/mb_lumi_banner.jpg";
export { default as lumiVideoSec3 } from "./luminaries/Day1_Dinner_BeautyYou_Mumbai.jpg";
export { default as mblumiVideoSec3 } from "./luminaries/mb_Day1_Dinner_BeautyYou_Mumbai.jpg";
export { default as lumiPlayIcon } from "./luminaries/play_icon.svg";
export { default as blackLumiPlayIcon } from "./luminaries/black_play_icon.svg";
export { default as lumiEveningLeftArrow } from "./luminaries/left_arrow.png";
export { default as lumiEveningRightArrow } from "./luminaries/right_arrow.png";

// business of fashion

export { default as bofBanner } from "./bof/BOF_banner.jpg";
export { default as mbBofBanner } from "./bof/bof_mb_banner.jpg";
export { default as bofVideoBannerSec3 } from "./bof/bof_video_banner_1.jpg";
export { default as mbBofVideoBannerSec3 } from "./bof/bof_mb_video_banner_1.jpg";
export { default as bofVideoBannerSec5 } from "./bof/bof_video_banner_2.jpg";
export { default as mbBofVideoBannerSec5 } from "./bof/bof_mb_video_banner_2.jpg";
export { default as bofVideoBannerSec7 } from "./bof/bof_video_banner_3.jpg";
export { default as mbBofVideoBannerSec7 } from "./bof/bof_mb_video_banner_3.jpg";

// exhibition
export { default as exhibitionBanner } from "./exhibition/exhibition_banner.jpg";
export { default as mbExhibitionBanner } from "./exhibition/mb_exhibition_banner.jpg";
export { default as videoSec3 } from "./exhibition/Day1_Dinner_BeautyYou_Mumbai.jpg";
export { default as mbVideoSec3 } from "./exhibition/mb_video_banner.jpg";
export { default as exhibitionLeftArrow } from "./exhibition/left_arrow.png";
export { default as exhibitionRightArrow } from "./exhibition/right_arrow.png";
export { default as sec5SliderImg } from "./exhibition/sec5_slider_img.png";

// event night
export { default as eventNightBanner } from "./eventnight/event-night_banner.jpg";
export { default as mbEventNightBanner } from "./eventnight/event-night_banner_mb.jpg";
export { default as eventNightvideoSec3 } from "./eventnight/videoSec3_img.jpg";
export { default as mbEventNightvideoSec3 } from "./eventnight/videoSec3_img_mb.jpg";
export { default as eventNightPlayIcon } from "./eventnight/play_icon.svg";

export let images = {
  sponsor1: {
    image: require("./common/sponsor-img.png"),
    alt: "sponsor 1",
  },
  // HOMEPAGE IMAGES
  banner0101: {
    image: require("./home/banner-01-01.jpg"),
    alt: "banner 01 01",
  },
  banner0101mb: {
    image: require("./home/banner-01-01-mb.jpg"),
    alt: "banner 01 01 mb",
  },
  banner0102: {
    image: require("./home/banner-01-02.jpg"),
    alt: "banner 01 02",
  },
  banner01: {
    image: require("./home/banner-01-1.jpg"),
    alt: "banner 02",
  },
  banner01mb: {
    image: require("./home/mb-banner-01-1.jpg"),
    alt: "banner 02 mb",
  },
  banner02: {
    image: require("./home/banner-02.jpg"),
    alt: "banner 02",
  },
  banner02mb: {
    image: require("./home/banner-02-mb.jpg"),
    alt: "banner 02 mb",
  },
  banner03: {
    image: require("./home/banner-03.jpg"),
    alt: "banner 03",
  },
  banner03mb: {
    image: require("./home/banner-03-mb.jpg"),
    alt: "banner 03 mb",
  },
  banner04: {
    image: require("./home/banner-04.jpg"),
    alt: "banner 04",
  },
  banner04mb: {
    image: require("./home/banner-04-mb.jpg"),
    alt: "banner 04 mb",
  },
  banner0501: {
    image: require("./home/banner-05-01.jpg"),
    alt: "banner 05",
  },
  banner0502: {
    image: require("./home/banner-05-02.jpg"),
    alt: "banner 05",
  },
  banner05mb: {
    image: require("./home/banner-05-mb.jpeg"),
    alt: "banner 05 mb",
  },
  banner05new: {
    image: require("./home/banner-05-new.jpg"),
    alt: "banner 05",
  },
  banner05newmb: {
    image: require("./home/banner-05-mb-new.jpg"),
    alt: "banner 05 mb",
  },
  banner06: {
    image: require("./home/banner-06.jpg"),
    alt: "banner 06",
  },
  banner06mb: {
    image: require("./home/banner-06-mb.jpg"),
    alt: "banner 06 mb",
  },
  banner0701: {
    image: require("./home/banner-07-01.jpg"),
    alt: "banner 07 01",
  },
  banner0701mb: {
    image: require("./home/banner-07-01-mb.jpg"),
    alt: "banner 06 mb",
  },
  banner0702: {
    image: require("./home/banner-07-02.jpg"),
    alt: "banner 07 02",
  },
  banner08: {
    image: require("./home/banner-08-1.jpg"),
    alt: "banner 08",
  },
  banner08mb: {
    image: require("./home/banner-08-mb.jpg"),
    alt: "banner 08 mb",
  },
  banner09: {
    image: require("./home/banner-09.jpg"),
    alt: "banner 09",
  },
  banner09mb: {
    image: require("./home/banner-09-mb.jpg"),
    alt: "banner 09 mb",
  },
  banner10: {
    image: require("./home/banner-10.jpg"),
    alt: "banner 10",
  },
  banner10mb: {
    image: require("./home/banner-10-mb.jpg"),
    alt: "banner 10 mb",
  },
  banner11: {
    image: require("./home/winner2024_banner.jpg"),
    alt: "banner 05",
  },
  banner11mb: {
    image: require("./home/mb_winner2024_banner_1.jpg"),
    alt: "banner 05 mb",
  },
  shanarandhavaportrait: {
    image: require("./home/shana-randhava-portrait.jpg"),
    alt: "shana randhava portrait",
  },
  gauravguptaportrait: {
    image: require("./home/gaurav-gupta-portrait.jpg"),
    alt: "gaurav gupta portrait",
  },
  katrinakaifpotrait: {
    image: require("./home/katrina-kaif-potrait.jpg"),
    alt: "katrina kaif potrait",
  },
  anchitpotrait: {
    image: require("./home/anchit-potrait.jpg"),
    alt: "Anchit potrait",
  },
  rohnavaziralliportrait: {
    image: require("./home/rohna-vaziralli-portrait.jpg"),
    alt: "Rohna Vaziralli Portrait",
  },
  anaitashroffportrait: {
    image: require("./home/anaita-shroff-portrait.jpg"),
    alt: "anaita shroff portrait",
  },
  divyaMalpani: {
    image: require("./home/DIVYA_MALPANI.jpg"),
    alt: "DIVYA MALPANI",
  },
  khanakGupta: {
    image: require("./home/KHANAK_GUPTA.jpg"),
    alt: "KHANAK GUPTA",
  },
  shanapotraithomejudge: {
    image: require("./home/shana-potrait-home-judge.jpg"),
    alt: "shana potrait home judge",
  },
  // winner page images
  winner1: {
    image: require("./winners/winner_1.png"),
    alt: "Sonya Khubchandani",
  },
  winner2: {
    image: require("./winners/winner_2.png"),
    alt: "Divya Malpani",
  },
  winner3: {
    image: require("./winners/winner_3.png"),
    alt: "Divya Malpani",
  },
  winner4: {
    image: require("./winners/winner_4.png"),
    alt: "Divya Malpani",
  },

  // press page images
  pressBanner: {
    image: require("./press/press_banner_1.jpg"),
    alt: "press banner",
  },
  pressBannerMb: {
    image: require("./press/mbpress_banner-1.jpg"),
    alt: "press banner",
  },
  press1: {
    image: require("./press/WWW.png"),
    alt: "WWW",
  },
  press2: {
    image: require("./press/Vogue_Business.png"),
    alt: "Vogue Business",
  },
  press3: {
    image: require("./press/Vogue.png"),
    alt: "Vogue",
  },
  bnyoupress: {
    image: require("./press/b&y-press.jpg"),
    alt: "b&y-press",
  },
  vogueblack: {
    image: require("./press/vogue-black.png"),
    alt: "vogue",
  },
  wwdblack: {
    image: require("./press/wwd-black.png"),
    alt: "wwd",
  },
  businessinsiderblack: {
    image: require("./press/businessinsider-black.png"),
    alt: "businessinsider",
  },
  businesslineblack: {
    image: require("./press/businessline-black.png"),
    alt: "businessline",
  },
  fashionnetworkblack: {
    image: require("./press/fashionnetwork-black.png"),
    alt: "fashionnetwork",
  },
  forbesblack: {
    image: require("./press/forbes-black.png"),
    alt: "forbes",
  },
  thevoiceoffashionblack: {
    image: require("./press/the-voice-of-fashion-black.png"),
    alt: "the choice of fashion",
  },
  inc42black: {
    image: require("./press/inc42-black.png"),
    alt: "inc42",
  },
  indoasianblack: {
    image: require("./press/indoasian-black.png"),
    alt: "indoasian",
  },
  legalworldblack: {
    image: require("./press/legalworld-black.png"),
    alt: "legalworld",
  },
  theeconomictimesblack: {
    image: require("./press/theeconomictimes-black.png"),
    alt: "theeconomictimes",
  },
  voguebusinessblack: {
    image: require("./press/voguebusiness-black.png"),
    alt: "voguebusiness",
  },

  // Masterclass images
  abhabakayamasterclass: {
    image: require("./masterclass/abha-bakaya-masterclass.jpg"),
    alt: "abha bakaya masterclass",
  },
  abhabakayainner: {
    image: require("./masterclass/abha-bakaya-inner.jpg"),
    alt: "abha bakaya inner",
  },
  abhabakayamb: {
    image: require("./masterclass/abha-bakaya-mb.jpg"),
    alt: "abha bakaya mb",
  },
  akashshethmasterclass: {
    image: require("./masterclass/akash-sheth-masterclass.jpg"),
    alt: "akash sheth masterclass",
  },
  akashshethinner: {
    image: require("./masterclass/akash-sheth-inner.jpg"),
    alt: "akash sheth inner",
  },
  akashshethmb: {
    image: require("./masterclass/akash-sheth-mb.jpg"),
    alt: "akash sheth mb",
  },
  amritagursimasterclass: {
    image: require("./masterclass/amrita-gursi-masterclass.jpg"),
    alt: "amrita gursi masterclass",
  },
  amritagursiinner: {
    image: require("./masterclass/amrita-gursi-inner.jpg"),
    alt: "amrita gursi inner",
  },
  amritagursimb: {
    image: require("./masterclass/amrita-gursi-mb.jpg"),
    alt: "amrita gursi mb",
  },
  drkiransethimasterclass: {
    image: require("./masterclass/dr-kiran-sethi-masterclass.jpg"),
    alt: "dr kiran sethi masterclass",
  },
  drkiransethiinner: {
    image: require("./masterclass/dr-kiran-sethi-inner.jpg"),
    alt: "dr kiran sethi inner",
  },
  drkiransethimb: {
    image: require("./masterclass/dr-kiran-sethi-mb.jpg"),
    alt: "dr kiran sethi mb",
  },
  gautamsethmasterclass: {
    image: require("./masterclass/gautam-seth-masterclass.jpg"),
    alt: "gautam seth masterclass",
  },
  gautamsethinner: {
    image: require("./masterclass/gautam-seth-inner.jpg"),
    alt: "gautam seth inner",
  },
  gautamsethmb: {
    image: require("./masterclass/gautam-seth-mb.jpg"),
    alt: "gautam seth mb",
  },
  jessicajaynemasterclass: {
    image: require("./masterclass/jessica-jayne-masterclass.jpg"),
    alt: "jessica jayne masterclass",
  },
  jessicajayneinner: {
    image: require("./masterclass/jessica-jayne-inner.jpg"),
    alt: "jessica jayne inner",
  },
  jessicajaynemb: {
    image: require("./masterclass/jessica-jayne-mb.jpg"),
    alt: "jessica jayne mb",
  },
  malikadattsadanimasterclass: {
    image: require("./masterclass/malika-datt-sadani-masterclass.jpg"),
    alt: "malika datt sadani masterclass",
  },
  malikadattsadaniinner: {
    image: require("./masterclass/malika-datt-sadani-inner.jpg"),
    alt: "malika datt sadani inner",
  },
  malikadattsadanimb: {
    image: require("./masterclass/malika-datt-sadani-mb.jpg"),
    alt: "malika datt sadani mb",
  },
  maxmodestimasterclass: {
    image: require("./masterclass/max-modesti-masterclass.jpg"),
    alt: "max modesti masterclass",
  },
  maxmodestiinner: {
    image: require("./masterclass/max-modesti-inner.jpg"),
    alt: "max modesti inner",
  },
  maxmodestimb: {
    image: require("./masterclass/max-modesti-mb.jpg"),
    alt: "max modesti mb",
  },
  radhikaghaimasterclass: {
    image: require("./masterclass/radhika-ghai-masterclass.jpg"),
    alt: "radhika ghai masterclass",
  },
  radhikaghaiinner: {
    image: require("./masterclass/radhika-ghai-inner.jpg"),
    alt: "radhika ghai inner",
  },
  radhikaghaimb: {
    image: require("./masterclass/radhika-ghai-mb.jpg"),
    alt: "radhika ghai mb",
  },
  riyaazamlanimasterclass: {
    image: require("./masterclass/riyaaz-amlani-masterclass.jpg"),
    alt: "riyaaz amlani masterclass",
  },
  riyaazamlaniinner: {
    image: require("./masterclass/riyaaz-amlani-inner.jpg"),
    alt: "riyaaz amlani inner",
  },
  riyaazamlanimb: {
    image: require("./masterclass/riyaaz-amlani-mb.jpg"),
    alt: "riyaaz amlani mb",
  },
  samanthawadwanimasterclass: {
    image: require("./masterclass/samantha-wadwani-masterclass.jpg"),
    alt: "samantha wadwani masterclass",
  },
  samanthawadwaniinner: {
    image: require("./masterclass/samantha-wadwani-inner.jpg"),
    alt: "samantha wadwani inner",
  },
  samanthawadwanimb: {
    image: require("./masterclass/samantha-wadwani-mb.jpg"),
    alt: "samantha wadwani mb",
  },
  sandhyashekarmasterclass: {
    image: require("./masterclass/sandhya-shekar-masterclass.jpg"),
    alt: "sandhya shekar masterclass",
  },
  sandhyashekarinner: {
    image: require("./masterclass/sandhya-shekar-inner.jpg"),
    alt: "sandhya shekar inner",
  },
  sandhyashekarmb: {
    image: require("./masterclass/sandhya-shekar-mb.jpg"),
    alt: "sandhya shekar mb",
  },
  taramortimermasterclass: {
    image: require("./masterclass/tara-mortimer-masterclass.jpg"),
    alt: "tara mortimer masterclass",
  },
  taramortimerinner: {
    image: require("./masterclass/tara-mortimer-inner.jpg"),
    alt: "tara mortimer inner",
  },
  taramortimermb: {
    image: require("./masterclass/tara-mortimer-mb.jpg"),
    alt: "tara mortimer mb",
  },
  katrinakaif: {
    image: require("./masterclass/katrina-kaif.png"),
    alt: "Katrina Kaif",
  },
  sabyasachimukherjee: {
    image: require("./masterclass/sabhyasachi.png"),
    alt: "Sabyasachi Mukherjee",
  },
  falguninayar: {
    image: require("./masterclass/falguninayar.png"),
    alt: "Falguni Nayar",
  },
  deepicamutyala: {
    image: require("./masterclass/deepica-mutyala.png"),
    alt: "Deepica Mutyala",
  },
  nicolakilnera: {
    image: require("./masterclass/nicola-kilner.png"),
    alt: "Nicola Kilner",
  },
  mirakulkarni: {
    image: require("./masterclass/mira-kulkarni.png"),
    alt: "Mira Kulkarni",
  },
  mohitdhawan: {
    image: require("./masterclass/mohit.png"),
    alt: "Mohit Dhawan",
  },
  diipabullerkhosla: {
    image: require("./masterclass/diipa-buller-khosla.png"),
    alt: "Diipa Buller Khosla",
  },
  drewelliott: {
    image: require("./masterclass/drew-elliott.png"),
    alt: "Drew Elliott",
  },
  ryanpiela: {
    image: require("./masterclass/ryan-piela.png"),
    alt: "Ryan Piela",
  },
  prachipawar: {
    image: require("./masterclass/prachi-pawar.png"),
    alt: "Prachi Pawar",
  },
  rohanvaziralli: {
    image: require("./masterclass/rohan-vaziralli.png"),
    alt: "ROHAN VAZIRALLI",
  },

  // Masterclass inner images
  katrinabanner: {
    image: require("./masterclassinner/katrina-kaif-desktop.jpeg"),
    alt: "Katrina Kaif Banner",
  },
  katrinamobbanner: {
    image: require("./masterclassinner/katrina-kaif-mobile.jpeg"),
    alt: "Katrina Kaif Banner",
  },
  sabyasachimukherjeebanner: {
    image: require("./masterclassinner/sabhyasachi-desktop.jpeg"),
    alt: "Sabyasachi Mukherjee Banner",
  },
  sabyasachimukherjeemobbanner: {
    image: require("./masterclassinner/sabhyasachi-mobile.jpeg"),
    alt: "Sabyasachi Mukherjee Banner",
  },
  falguninayarbanner: {
    image: require("./masterclassinner/falguninayar-desktop.jpg"),
    alt: "Falguni Nayar Banner",
  },
  falguninayarmobbanner: {
    image: require("./masterclassinner/falguninayar-mobile.jpg"),
    alt: "Falguni Nayar Banner",
  },
  deepicamutyalabanner: {
    image: require("./masterclassinner/deepica-mutyala-desktop.jpg"),
    alt: "Deepica Mutyala Banner",
  },
  deepicamutyalamobbanner: {
    image: require("./masterclassinner/deepica-mutyala-mobile.jpg"),
    alt: "Deepica Mutyala Banner",
  },
  nicolakilnerabanner: {
    image: require("./masterclassinner/nicola-kilner-desktop.jpeg"),
    alt: "Nicola Kilnera Banner",
  },
  nicolakilneramobbanner: {
    image: require("./masterclassinner/nicola-kilner-mobile.jpeg"),
    alt: "Nicola Kilnera Banner",
  },
  mirakulkarnibanner: {
    image: require("./masterclassinner/mira-kulkarni-desktop.jpeg"),
    alt: "Mira Kulkarni Banner",
  },
  mirakulkarnimobbanner: {
    image: require("./masterclassinner/mira-kulkarni-mobile.jpeg"),
    alt: "Mira Kulkarni Banner",
  },
  mohitdhawanbanner: {
    image: require("./masterclassinner/mohit-desktop.jpeg"),
    alt: "Mohit Dhawan Banner",
  },
  mohitdhawanmobbanner: {
    image: require("./masterclassinner/mohit-mobile.jpeg"),
    alt: "Mohit Dhawan Banner",
  },
  diipabullerkhoslabanner: {
    image: require("./masterclassinner/diipa-buller-khosla-desktop.jpg"),
    alt: "Diipa Buller Khosla Banner",
  },
  diipabullerkhoslamobbanner: {
    image: require("./masterclassinner/diipa-buller-khosla-mobile.jpg"),
    alt: "Diipa Buller Khosla Banner",
  },
  drewelliottbanner: {
    image: require("./masterclassinner/drew-elliott-desktop.jpeg"),
    alt: "Drew Elliott Banner",
  },
  drewelliottmobbanner: {
    image: require("./masterclassinner/drew-elliott-mobile.jpeg"),
    alt: "Drew Elliott Banner",
  },
  ryanpielabanner: {
    image: require("./masterclassinner/ryan-piela-desktop.jpeg"),
    alt: "Ryan Piela Banner",
  },
  ryanpielamobbanner: {
    image: require("./masterclassinner/ryan-piela-mobile.jpeg"),
    alt: "Ryan Piela Banner",
  },
  prachipawarbanner: {
    image: require("./masterclassinner/prachi-pawar-desktop.jpeg"),
    alt: "Prachi Pawar Banner",
  },
  prachipawarmobbanner: {
    image: require("./masterclassinner/prachi-pawar-mobile.jpeg"),
    alt: "Prachi Pawar Banner",
  },
  rohanvazirallibanner: {
    image: require("./masterclassinner/rohan-vaziralli-desktop.jpeg"),
    alt: "ROHAN VAZIRALLI Banner",
  },
  rohanvazirallimobbanner: {
    image: require("./masterclassinner/rohan-vaziralli-mobile.png"),
    alt: "ROHAN VAZIRALLI Banner",
  },
  videothumbnail: {
    image: require("./masterclassinner/video_thumbnai.png"),
    alt: "video_thumbnai",
  },

  // Masterclass Thumbnail, Inner Desktop banner and mobile banner
  ingetheron: {
    image: require("./masterclass/inge-theron.png"),
    alt: "Inge Theron",
  },
  ingetheronbanner: {
    image: require("./masterclassinner/inge-theron-desktop.jpeg"),
    alt: "Inge Theron Banner",
  },
  ingetheronmobbanner: {
    image: require("./masterclassinner/inge-theron-mobile.jpeg"),
    alt: "Inge Theron Banner",
  },
  aksahmehta: {
    image: require("./masterclass/aksah-mehta.png"),
    alt: "Aksah Mehta",
  },
  aksahmehtabanner: {
    image: require("./masterclassinner/aksah-mehta-desktop.jpeg"),
    alt: "Aksah Mehta Banner",
  },
  aksahmehtamobbanner: {
    image: require("./masterclassinner/aksah-mehta-mobile.jpeg"),
    alt: "Aksah Mehta Banner",
  },
  shankarprasad: {
    image: require("./masterclass/shankar-prasad.png"),
    alt: "Shankar Prasad",
  },
  shankarprasadbanner: {
    image: require("./masterclassinner/shankar-prasad-desktop.jpeg"),
    alt: "Shankar Prasad Banner",
  },
  shankarprasadmobbanner: {
    image: require("./masterclassinner/shankar-prasad-mobile.jpeg"),
    alt: "Shankar Prasad Banner",
  },
  rohitchawla: {
    image: require("./masterclass/rohit.png"),
    alt: "Rohit Chawla",
  },
  rohitchawlabanner: {
    image: require("./masterclassinner/rohit-desktop.jpeg"),
    alt: "Rohit Chawla Banner",
  },
  rohitchawlamobbanner: {
    image: require("./masterclassinner/rohit-mobile.jpeg"),
    alt: "Rohit Chawla Banner",
  },
  shamika: {
    image: require("./masterclass/shamika-listing.png"),
    alt: "Shamika Haldipurkar",
  },
  shamikabanner: {
    image: require("./masterclassinner/shamika-desktop.jpeg"),
    alt: "Shamika Haldipurkar Banner",
  },
  shamikamobbanner: {
    image: require("./masterclassinner/shamika-mobile.jpeg"),
    alt: "Shamika Haldipurkar Banner",
  },
  anjulaacharia: {
    image: require("./masterclass/anjula.png"),
    alt: "Anjula Acharia",
  },
  anjulaachariabanner: {
    image: require("./masterclassinner/anjula-desktop.jpeg"),
    alt: "Anjula Acharia Banner",
  },
  anjulaachariamobbanner: {
    image: require("./masterclassinner/anjula-mobile.jpeg"),
    alt: "Anjula Acharia Banner",
  },
  swapnadutt: {
    image: require("./masterclass/Swapna_Dutt.png"),
    alt: "swapna dutt chalasani",
  },
  swapnaduttbanner: {
    image: require("./masterclassinner/Swapna_desktop.jpg"),
    alt: "Anjula Acharia Banner",
  },
  swapnaduttmobbanner: {
    image: require("./masterclassinner/Swapna_mobile.jpg"),
    alt: "Anjula Acharia Banner",
  },
  navdeeppallapolu: {
    image: require("./masterclass/Navdeep.png"),
    alt: "navdeep pallapolu",
  },
  navdeeppallapolubanner: {
    image: require("./masterclassinner/Navdeep_desktop.jpg"),
    alt: "Anjula Acharia Banner",
  },
  navdeeppallapolumobbanner: {
    image: require("./masterclassinner/Navdeep_mobile.jpg"),
    alt: "Anjula Acharia Banner",
  },
  meghnaapparao: {
    image: require("./masterclass/Meghna.png"),
    alt: "meghna apparao",
  },
  meghnaapparaobanner: {
    image: require("./masterclassinner/Meghna_desktop.jpg"),
    alt: "Anjula Acharia Banner",
  },
  meghnaapparaomobbanner: {
    image: require("./masterclassinner/Meghna_mobile.jpg"),
    alt: "Anjula Acharia Banner",
  },
  smitavallurupalli: {
    image: require("./masterclass/Smita.png"),
    alt: "smita vallurupalli",
  },
  smitavallurupallibanner: {
    image: require("./masterclassinner/Smita_desktop.jpeg"),
    alt: "Anjula Acharia Banner",
  },
  smitavallurupallimobbanner: {
    image: require("./masterclassinner/Smita_mobile.jpeg"),
    alt: "Anjula Acharia Banner",
  },
  raghavakk: {
    image: require("./masterclass/raghava_kk.png"),
    alt: "Raghava KK",
  },
  raghavakkbanner: {
    image: require("./masterclassinner/raghava_kk_desktop.jpeg"),
    alt: "Anjula Acharia Banner",
  },
  raghavakkmobbanner: {
    image: require("./masterclassinner/raghava_kk_mobile.jpeg"),
    alt: "Anjula Acharia Banner",
  },
  drharshana: {
    image: require("./masterclass/drharshana-listing.png"),
    alt: "Dr Harshana",
  },
  drharshanabanner: {
    image: require("./masterclassinner/drharshana-desktop.jpeg"),
    alt: "Dr Harshana",
  },
  drharshanamobbanner: {
    image: require("./masterclassinner/drharshana-mobile.jpeg"),
    alt: "Dr Harshana",
  },
  kshitij: {
    image: require("./masterclass/kshitih-listing.png"),
    alt: "Dr Kshitij",
  },
  kshitijbanner: {
    image: require("./masterclassinner/kshitih-desktop.jpeg"),
    alt: "Dr Kshitij",
  },
  kshitijmobbanner: {
    image: require("./masterclassinner/kshitih-mobile.jpeg"),
    alt: "Dr Kshitij",
  },
  drjaishree: {
    image: require("./masterclass/drjaishree-listing.png"),
    alt: "Dr Jaishree",
  },
  drjaishreebanner: {
    image: require("./masterclassinner/drjaishree-desktop.jpeg"),
    alt: "Dr Jaishree",
  },
  drjaishreemobbanner: {
    image: require("./masterclassinner/drjaishree-mobile.jpeg"),
    alt: "Dr Jaishree",
  },
  masaba: {
    image: require("./masterclass/masaba.png"),
    alt: "Dr Harshana",
  },
  masababanner: {
    image: require("./masterclassinner/masaba-desktop.jpg"),
    alt: "Dr Harshana",
  },
  masabamobbanner: {
    image: require("./masterclassinner/masaba-mobile.jpg"),
    alt: "Dr Harshana",
  },
  rahul: {
    image: require("./masterclass/rahul-listing.png"),
    alt: "Rahul Shah",
  },
  rahulbanner: {
    image: require("./masterclassinner/rahul-desktop.jpeg"),
    alt: "Rahul Shah",
  },
  rahulmobbanner: {
    image: require("./masterclassinner/rahul-mobile.jpeg"),
    alt: "Rahul Shah",
  },
  pratika: {
    image: require("./masterclass/pritika-swarup-listing.png"),
    alt: "Pritika Swarup",
  },
  pratikabanner: {
    image: require("./masterclassinner/pratika-swarup-desktop.jpg"),
    alt: "Pritika Swarup",
  },
  pratikamobbanner: {
    image: require("./masterclassinner/pratika-swarup-mobile.jpg"),
    alt: "Pritika Swarup",
  },
  roma: {
    image: require("./masterclass/Roma.png"),
    alt: "Roma Dixit",
  },
  romabanner: {
    image: require("./masterclassinner/roma-desktop.jpeg"),
    alt: "Roma Dixit",
  },
  romamobbanner: {
    image: require("./masterclassinner/roma-mobile.jpeg"),
    alt: "Roma Dixit",
  },
  anjalimehta: {
    image: require("./masterclass/anjali-mehta.png"),
    alt: "Pritika Swarup",
  },
  anjalimehtabanner: {
    image: require("./masterclassinner/anjali-mehta-desktop.jpeg"),
    alt: "Pritika Swarup",
  },
  anjalimehtamobbanner: {
    image: require("./masterclassinner/anjali-mehta-mobile.jpeg"),
    alt: "Pritika Swarup",
  },
  jigarshahmasterclass: {
    image: require("./masterclass/jigar-shah.png"),
    alt: "Jigar Shah",
  },
  jigarshahbanner: {
    image: require("./masterclassinner/jigar-shah-desktop.jpeg"),
    alt: "Jigar Shah",
  },
  jigarshahmobbanner: {
    image: require("./masterclassinner/jigar-shah-mobile.jpeg"),
    alt: "Jigar Shah",
  },

  // Judges page images
  judgesBanner: {
    image: require("./judges/judges-banner-1.jpg"),
    alt: "judges banner",
  },
  mbJudgesBanner: {
    image: require("./judges/mb-judges-banner-1.jpg"),
    alt: "judges banner",
  },
  shanarandhavajudge: {
    image: require("./judges/shana-randhava-judge.jpg"),
    alt: "Judge Shana Randha",
  },
  rohanvazirallijudge: {
    image: require("./judges/rohan-vaziralli-judge.jpg"),
    alt: "Rohan Vaziralli Judge",
  },
  sabyasachijudge: {
    image: require("./judges/sabyasachi-judge.jpg"),
    alt: "Sabyasachi Judge",
  },
  jaffreyzamanjudge: {
    image: require("./judges/jaffrey-zaman-judge.jpg"),
    alt: "Jaffrey Zaman Judge",
  },
  anchitnayarjudge: {
    image: require("./judges/anchit-nayar-judge.jpg"),
    alt: "Anchit Nayar Judge",
  },
  gauravguptajudge: {
    image: require("./judges/gaurav-gupta-judge.jpg"),
    alt: "gaurav gupta judge",
  },
  anaitashroffadajaniajudge: {
    image: require("./judges/anaita-shroff-adajania-judge.jpg"),
    alt: "Anaita Shroff Adajania Judge",
  },
  sumitbhasinjudge: {
    image: require("./judges/sumit-judge.jpg"),
    alt: "Sumit Bhasin Judge",
  },
  samrathbedijudge: {
    image: require("./judges/samrath-bedi-judge.jpg"),
    alt: "Samrath Bedi Judge",
  },
  sujatajudge: {
    image: require("./judges/Sujata.jpg"),
    alt: "Sujata",
  },
  shrutichandrajudge: {
    image: require("./judges/shruti-chandra-judge.jpg"),
    alt: "shruti-chandra-judge",
  },
  rochellepintojudge: {
    image: require("./judges/rochelle-pinto-judge.jpg"),
    alt: "Rochelle Pinto Judge",
  },
  drjaishreesharadjudge: {
    image: require("./judges/dr-jaishree-sharad-judge.jpg"),
    alt: "Dr Jaishree Sharad Judge",
  },
  diipabullerkhoslajudge: {
    image: require("./judges/diipa-buller-khosla-judge.jpg"),
    alt: "Diipa Büller-Khosla Judge",
  },
  gianandreaferrarijudge: {
    image: require("./judges/gianandrea-ferrari-judge.jpg"),
    alt: "Gianandrea Ferrari Judge",
  },
  katrinakaifjudge: {
    image: require("./judges/katrina-kaif-judge.jpg"),
    alt: "Katrina Kaif Judge",
  },
  sandhyadevanathanjudge: {
    image: require("./judges/sandhya-devanathan-judge.jpg"),
    alt: "Sandhya Devanathan Judge",
  },
  deepicamutyalajudge: {
    image: require("./judges/deepica-mutyala-judge.jpg"),
    alt: "Deepica Mutyala Judge",
  },
  kiransethijudge: {
    image: require("./judges/kiran-sethi-judge.jpg"),
    alt: "Kiran Sethi Judge",
  },
  taruntahilianijudge: {
    image: require("./judges/tarun-tahiliani-judge.jpg"),
    alt: "Tarun Tahiliani Judge",
  },
  roseminmadhavjilisting: {
    image: require("./masterclass/rosemin-listing.png"),
    alt: "Judge Rosemin Madhavji",
  },
  roseminmadhavjibanner: {
    image: require("./masterclassinner/rosemin-desktop.jpeg"),
    alt: "Judge Rosemin Madhavji",
  },
  roseminmadhavjimobbanner: {
    image: require("./masterclassinner/rosemin-mobile.jpeg"),
    alt: "Judge Rosemin Madhavji",
  },
  jaffreyzamanjudgelisting: {
    image: require("./masterclass/jaffrey-listing.png"),
    alt: "Judge Jaffrey Zaman",
  },
  jaffreyzamanbanner: {
    image: require("./masterclassinner/jaffrey-desktop.jpeg"),
    alt: "Jaffrey Zaman Banner",
  },
  jaffreyzamanmobbanner: {
    image: require("./masterclassinner/jaffrey-mobile.jpeg"),
    alt: "Jaffrey Zaman Banner",
  },
  hariharanlisting: {
    image: require("./masterclass/hariharan-premkumar-listing.png"),
    alt: "Hariharan Premkumar",
  },
  hariharanbanner: {
    image: require("./masterclassinner/hariharan-desktop.jpeg"),
    alt: "Hariharan Premkumar",
  },
  hariharanmobbanner: {
    image: require("./masterclassinner/hariharan-mobile.jpeg"),
    alt: "Hariharan Premkumar",
  },

  // NEW masterclasses
  newMasterclassBanner: {
    image: require("./masterclass/masterclass-banner.jpg"),
    alt: "masterclass banner",
  },
  newMasterclassBannerMb: {
    image: require("./masterclass/masterclass-banner-mb.jpg"),
    alt: "masterclass banner",
  },

  login: {
    image: require("./login/login-1.jpg"),
    alt: "login",
  },

  // Thank you pages
  thankyouBack: {
    image: require("./thankyou/signup-thankyou-bg.jpg"),
    alt: "thank you background",
  },
  thankyouMbBack: {
    image: require("./thankyou/mbsignup-thankyou-bg.jpg"),
    alt: "thank you background",
  },

  // Application Criteria page
  applicationCriteriaBanner: {
    image: require("./applicationcriteria/application-criteria-banner-1.jpg"),
    alt: "application criteria banner",
  },
  mbApplicationCriteriaBanner: {
    image: require("./applicationcriteria/mb-application-criteria-banner-1.jpg"),
    alt: "application criteria banner",
  },

  // About us page
  shanaAbout: {
    image: require("./about-us/shanaAbout.jpg"),
    alt: "shana",
  },
  aboutNIV: {
    image: require("./about-us/niv_img.png"),
    alt: "niv",
  },
  aboutNykaa: {
    image: require("./about-us/nykaa.png"),
    alt: "nykaa",
  },
  aboutAnchitnykaa: {
    image: require("./about-us/Anchitnykaa.jpg"),
    alt: "Anchit nykaa",
  },
  idexAbout: {
    image: require("./about-us/idex.png"),
    alt: "idex",
  },
  latticeAbout: {
    image: require("./about-us/lattice.png"),
    alt: "lattice",
  },

  // Launch images

  launchimg: {
    image: require("./launch/launch-img.jpg"),
    alt: "Launch",
  },

  // finalist page
  imagine1: {
    image: require("./finalist/finalist2024/giti_datt.png"),
    alt: "Giti Datt",
  },
  imagine2: {
    image: require("./finalist/finalist2024/SONIA_BADRESHIA .png"),
    alt: "Dr. Sonia",
  },
  imagine3: {
    image: require("./finalist/finalist2024/Aditi_Mundra_&_Riya_Ranka.png"),
    alt: "Aditi Mundra & Riya",
  },
  imagine4: {
    image: require("./finalist/finalist2024/aman_dahele.png"),
    alt: "Aman Dahele",
  },
  imagine5: {
    image: require("./finalist/finalist2024/hetali_kamdar.png"),
    alt: "Hetali Kamdar",
  },

  mbimagine1: {
    image: require("./finalist/mb_imagine_1.png"),
    alt: "imagine",
  },

  grow1: {
    image: require("./finalist/finalist2024/Anousha_Chauhan_&_Shreyansh_Chauhan.png"),
    alt: "Anousha Chauhan & Shreyansh Chauhan",
  },
  grow2: {
    image: require("./finalist/finalist2024/MEHER_JADWANI.png"),
    alt: "Meher Jadwani",
  },
  grow3: {
    image: require("./finalist/finalist2024/jean_christophe_bonafous.png"),
    alt: "Jean Christophe Bonnafous",
  },
  grow4: {
    image: require("./finalist/finalist2024/sanshi_aggarwal.png"),
    alt: "Sanshi Aggarwal",
  },
  grow5: {
    image: require("./finalist/finalist2024/KHAYL_REIS_LALANI _&_KARIM LALANI.png"),
    alt: "Khayl Reis Lalani and Karim Lalani",
  },
  grow6: {
    image: require("./finalist/finalist2024/SHENAI_BRIDGLALL.png"),
    alt: "Shenai Bridglall",
  },

  mbgrow1: {
    image: require("./finalist/mb_imagine_1.png"),
    alt: "grow",
  },

  create1: {
    image: require("./finalist/finalist2024/Diya_Ahuja.png"),
    alt: "Diya Ahuja",
  },
  create2: {
    image: require("./finalist/finalist2024/Shivani_Joshi_James_Lalthanzuala_&_Nishanth_Radhakrishnan.png"),
    alt: "Shivani Joshi and James Lalthanzuala and Nishanth Radhakrishnan",
  },
  create3: {
    image: require("./finalist/finalist2024/Doyel_Joshi_&_Neil_Balser.png"),
    alt: "Doyel Joshi and Neil Balser",
  },
  create4: {
    image: require("./finalist/finalist2024/priya_minhas.png"),
    alt: "Priya Minhas",
  },
  create5: {
    image: require("./finalist/finalist2024/henri_dacosta.png"),
    alt: "Henry DaCosta",
  },
  create6: {
    image: require("./finalist/finalist2024/furmaan_ahmed.png"),
    alt: "Furmaan Ahmed",
  },
  create7: {
    image: require("./finalist/finalist2024/Sachin_Gogna.png"),
    alt: "Sachin Gogna",
  },

  mbcreate1: {
    image: require("./finalist/mb_imagine_1.png"),
    alt: "create",
  },

  // finalist innerpage
  imaginefinalist1: {
    image: require("./finalist/Gitti_Datt.jpg"),
    alt: "Giti Datt",
  },
  imaginefinalist2: {
    image: require("./finalist/Dr_Sonia.jpg"),
    alt: "Dr. Sonia",
  },
  imaginefinalist3: {
    image: require("./finalist/Aditi_Mundra_&_Riya.jpg"),
    alt: "Aditi Mundra & Riya",
  },
  imaginefinalist4: {
    image: require("./finalist/Aman_Dahele.jpg"),
    alt: "Aman Dahele",
  },
  imaginefinalist5: {
    image: require("./finalist/HETALI_KAMDAR.jpg"),
    alt: "Hetali Kamdar",
  },

  growfinalist1: {
    image: require("./finalist/finalist2024/jean_christophe_bonafous_inner.jpg"),
    alt: "Meher Jadwani",
  },
  growfinalist2: {
    image: require("./finalist/Meher_Jadwani.jpg"),
    alt: "Meher Jadwani",
  },
  growfinalist3: {
    image: require("./finalist/Jean_Christophe_Bonnafous.jpg"),
    alt: "Jean Christophe Bonnafous",
  },
  growfinalist4: {
    image: require("./finalist/Sanshi_Aggarwal_1.jpg"),
    alt: "Sanshi Aggarwal",
  },
  growfinalist5: {
    image: require("./finalist/Karim_Lalani_and_Khayl_Reis Lalani.jpg"),
    alt: "Khayl Reis Lalani and Karim Lalani",
  },
  growfinalist6: {
    image: require("./finalist/Shenai_Bridglall.jpg"),
    alt: "Shenai Bridglall",
  },
  growfinalist7: {
    image: require("./finalist/Meera_Iyer_&_Dr_Chytra_Anand.jpg"),
    alt: "Dr. Chytra Anand and Meera Iyer",
  },
  growfinalist8: {
    image: require("./finalist/Sanchaya_Soundhar.jpg"),
    alt: "Sanchaya Soundhar",
  },

  breakfinalist1: {
    image: require("./finalist/Shara_Ticku.jpg"),
    alt: "Shara Ticku",
  },
  breakfinalist2: {
    image: require("./finalist/Komal_Baldwa.jpg"),
    alt: "Komal Baldwa",
  },
  breakfinalist3: {
    image: require("./finalist/Raja_Palaniswamy.jpg"),
    alt: "Raja Palaniswamy",
  },
  breakfinalist4: {
    image: require("./finalist/Dr._Avani_Mainkar_&_Kedar_Vaze.jpg"),
    alt: "Dr. Avani Mainkar and Kedar Vaze",
  },
  breakfinalist5: {
    image: require("./finalist/Surabhi_Nimkar_Roshni_Jaura_&_Vedant_Hooda.jpg"),
    alt: "Surabhi Nimkar and Roshni Jaura and Vedant Hooda",
  },
  breakfinalist6: {
    image: require("./finalist/Rupesh_pawar_and_khanak.jpg"),
    alt: "Khanak Gupta",
  },
  breakfinalist7: {
    image: require("./finalist/Rhea_Goenka.jpg"),
    alt: "Rhea Goenka",
  },

  createfinalist1: {
    image: require("./finalist/Diya_Ahuja.jpg"),
    alt: "Diya Ahuja",
  },
  createfinalist2: {
    image: require("./finalist/Shivani_Joshi_James_Lalthanzuala_&_Radhakrishnan_Nishanth.jpg"),
    alt: "Shivani Joshi and James Lalthanzuala and Nishanth Radhakrishnan",
  },
  createfinalist3: {
    image: require("./finalist/Doyel_Joshi.jpg"),
    alt: "Doyel Joshi and Neil Balser",
  },
  createfinalist4: {
    image: require("./finalist/Priya_Minhas.jpg"),
    alt: "Priya Minhas",
  },
  createfinalist5: {
    image: require("./finalist/Henry_DaCosta.jpg"),
    alt: "Henry DaCosta",
  },
  createfinalist6: {
    image: require("./finalist/Furmaan_Ahmed.jpg"),
    alt: "Furmaan Ahmed",
  },
  createfinalist7: {
    image: require("./finalist/Sachin_Gogna.jpg"),
    alt: "Sachin Gogna",
  },

  // voting page
  vimagine1: {
    image: require("./finalist/imagine_1.png"),
    alt: "imagine",
  },
  vimagine2: {
    image: require("./finalist/imagine_2.png"),
    alt: "imagine",
  },
  vimagine3: {
    image: require("./finalist/imagine_3.png"),
    alt: "imagine",
  },
  vimagine4: {
    image: require("./finalist/imagine_4.png"),
    alt: "imagine",
  },
  vimagine5: {
    image: require("./finalist/imagine_5.png"),
    alt: "imagine",
  },
  mbvimagine1: {
    image: require("./finalist/mb_imagine_1.png"),
    alt: "imagine",
  },
  mbvimagine2: {
    image: require("./finalist/mb_imagine_2.png"),
    alt: "imagine",
  },
  mbvimagine3: {
    image: require("./finalist/mb_imagine_3.png"),
    alt: "imagine",
  },
  mbvimagine4: {
    image: require("./finalist/mb_imagine_4.png"),
    alt: "imagine",
  },
  mbvimagine5: {
    image: require("./finalist/mb_imagine_5.png"),
    alt: "imagine",
  },
  vgrow1: {
    image: require("./finalist/imagine_1.png"),
    alt: "grow",
  },
  vgrow2: {
    image: require("./finalist/imagine_2.png"),
    alt: "grow",
  },
  vgrow3: {
    image: require("./finalist/imagine_3.png"),
    alt: "grow",
  },
  vgrow4: {
    image: require("./finalist/imagine_4.png"),
    alt: "grow",
  },
  vgrow5: {
    image: require("./finalist/imagine_5.png"),
    alt: "grow",
  },
  mbvgrow1: {
    image: require("./finalist/mb_imagine_1.png"),
    alt: "grow",
  },
  mbvgrow2: {
    image: require("./finalist/mb_imagine_2.png"),
    alt: "grow",
  },
  mbvgrow3: {
    image: require("./finalist/mb_imagine_3.png"),
    alt: "grow",
  },
  mbvgrow4: {
    image: require("./finalist/mb_imagine_4.png"),
    alt: "grow",
  },
  mbvgrow5: {
    image: require("./finalist/mb_imagine_5.png"),
    alt: "grow",
  },
  vbreak1: {
    image: require("./finalist/imagine_1.png"),
    alt: "breakthrough",
  },
  vbreak2: {
    image: require("./finalist/imagine_2.png"),
    alt: "breakthrough",
  },
  vbreak3: {
    image: require("./finalist/imagine_3.png"),
    alt: "breakthrough",
  },
  vbreak4: {
    image: require("./finalist/imagine_4.png"),
    alt: "breakthrough",
  },
  vbreak5: {
    image: require("./finalist/imagine_5.png"),
    alt: "breakthrough",
  },
  mbvbreak1: {
    image: require("./finalist/mb_imagine_1.png"),
    alt: "breakthrough",
  },
  mbvbreak2: {
    image: require("./finalist/mb_imagine_2.png"),
    alt: "breakthrough",
  },
  mbvbreak3: {
    image: require("./finalist/mb_imagine_3.png"),
    alt: "breakthrough",
  },
  mbvbreak4: {
    image: require("./finalist/mb_imagine_4.png"),
    alt: "breakthrough",
  },
  mbvbreak5: {
    image: require("./finalist/mb_imagine_5.png"),
    alt: "breakthrough",
  },
  vcreate1: {
    image: require("./finalist/imagine_1.png"),
    alt: "create",
  },
  vcreate2: {
    image: require("./finalist/imagine_2.png"),
    alt: "create",
  },
  vcreate3: {
    image: require("./finalist/imagine_3.png"),
    alt: "create",
  },
  vcreate4: {
    image: require("./finalist/imagine_4.png"),
    alt: "create",
  },
  vcreate5: {
    image: require("./finalist/imagine_5.png"),
    alt: "create",
  },
  mbvcreate1: {
    image: require("./finalist/mb_imagine_1.png"),
    alt: "create",
  },
  mbvcreate2: {
    image: require("./finalist/mb_imagine_2.png"),
    alt: "create",
  },
  mbvcreate3: {
    image: require("./finalist/mb_imagine_3.png"),
    alt: "create",
  },
  mbvcreate4: {
    image: require("./finalist/mb_imagine_4.png"),
    alt: "create",
  },
  mbvcreate5: {
    image: require("./finalist/mb_imagine_5.png"),
    alt: "create",
  },

  // voting innerpage
  imaginevoting1: {
    image: require("./finalist/imagine_finalist_1.png"),
    alt: "finalist",
  },
  growvoting1: {
    image: require("./finalist/grow_finalist_1.png"),
    alt: "grow",
  },
  createvoting1: {
    image: require("./finalist/create_finalist_1.png"),
    alt: "create",
  },

  // Meet the winners
  meetwinner1: {
    image: require("./meetwinners/GROW_Call_of_the_Valley.png"),
    alt: "grow award",
  },
  meetwinner2: {
    image: require("./meetwinners/IMAGINE_BABOSKI.png"),
    alt: "imagine award",
  },
  meetwinner3: {
    image: require("./meetwinners/BREAKTHROUGH_BIOME_LABORATORIES.png"),
    alt: "breakthrough award",
  },
  meetwinner4: {
    image: require("./meetwinners/BREAKTHROUGH_KEVAFRAGRANCES.png"),
    alt: "breakthrough award",
  },
  meetwinner5: {
    image: require("./meetwinners/CREATE_PRIYA.png"),
    alt: "create award",
  },
  meetwinner6: {
    image: require("./meetwinners/IMPACT_C16 BIOSCIENCES.png"),
    alt: "impact award",
  },
  meetwinner7: {
    image: require("./meetwinners/IMPACT_SOHRAI.png"),
    alt: "impact award",
  },
  meetwinner8: {
    image: require("./meetwinners/COMMUNITY_BEAUTYWISE.png"),
    alt: "community award",
  },
  meetwinner9: {
    image: require("./meetwinners/COMMUNITY_HAVAH.png"),
    alt: "community award",
  },
  scrollImgImagine: {
    image: require("./meetwinners/imagin_img.png"),
    alt: "Imagine award",
  },
  scrollImgGrow: {
    image: require("./meetwinners/grow_img.png"),
    alt: "Grow award",
  },
  scrollImgBreak: {
    image: require("./meetwinners/breakthrough_img.png"),
    alt: "breakthrough award",
  },
  scrollImgCreate: {
    image: require("./meetwinners/create_img.png"),
    alt: "create award",
  },
  scrollImgImpact: {
    image: require("./meetwinners/impact_img.png"),
    alt: "impact award",
  },
  scrollImgCommunity: {
    image: require("./meetwinners/community_img.png"),
    alt: "community award",
  },

  // Awards Evening
  awardSec4Img1: {
    image: require("./awardsevening/award_sec4_Felicity_Ingram_1.jpg"),
    alt: "Felicity",
  },
  awardSec4Img2: {
    image: require("./awardsevening/award_sec4_Felicity_Ingram_2.jpg"),
    alt: "Felicity",
  },
  awardSec4Img3: {
    image: require("./awardsevening/award_sec4_Felicity_Ingram_3.jpg"),
    alt: "Felicity",
  },
  awardSec5banner1: {
    image: require("./awardsevening/award_sec5_banner.jpg"),
    alt: "Felicity",
  },
  mbAwardSec5banner1: {
    image: require("./awardsevening/mb_award_sec5_banner1.jpg"),
    alt: "Felicity",
  },
  awardSec6Img1: {
    image: require("./awardsevening/award_sec6_Felicity_1.jpg"),
    alt: "Felicity",
  },
  awardSec6Img2: {
    image: require("./awardsevening/award_sec6_Felicity_2.jpg"),
    alt: "Felicity",
  },
  awardSec6Img3: {
    image: require("./awardsevening/award_sec6_Felicity_3.jpg"),
    alt: "Felicity",
  },
  awardSec7banner1: {
    image: require("./awardsevening/award_sec7_banner1.jpg"),
    alt: "banner 1",
  },
  mbAwardSec7banner1: {
    image: require("./awardsevening/mb_award_sec7_banner1.jpg"),
    alt: "banner 1",
  },
  awardSec9Img1: {
    image: require("./awardsevening/award_sec9_Felicity_1.jpg"),
    alt: "banner 1",
  },

  // exhibition page
  sec4Img1: {
    image: require("./exhibition/Felicity_Ingram_metalmagazine_1.jpg"),
    alt: "image 1",
  },
  sec4Img2: {
    image: require("./exhibition/Felicity_Ingram_metalmagazine_2.jpg"),
    alt: "image 1",
  },
  sec4Img3: {
    image: require("./exhibition/Felicity_Ingram_metalmagazine_3.jpg"),
    alt: "image 1",
  },
  sec5Img1: {
    image: require("./exhibition/sec5_banner_1.jpg"),
    alt: "image 1",
  },
  mbSec5Img1: {
    image: require("./exhibition/mb_sec5_banner_1.jpg"),
    alt: "image 1",
  },
  sec6Img1: {
    image: require("./exhibition/sec6_Felicity_Ingram_metalmagazine_1.jpg"),
    alt: "image 1",
  },
  sec6Img2: {
    image: require("./exhibition/sec6_Felicity_Ingram_metalmagazine_4.jpg"),
    alt: "image 1",
  },
  sec6Img3: {
    image: require("./exhibition/sec6_Felicity_Ingram_metalmagazine_3.jpg"),
    alt: "image 1",
  },
  sec7Img1: {
    image: require("./exhibition/sec7_banner_1.jpg"),
    alt: "image 1",
  },
  mbSec7Img1: {
    image: require("./exhibition/mb_sec7_banner_1.jpg"),
    alt: "image 1",
  },
  sec9Img1: {
    image: require("./exhibition/sec9_Felicity_Ingram_metalmagazine_1.jpg"),
    alt: "image 1",
  },
  sec9Img2: {
    image: require("./exhibition/sec9_Felicity_Ingram_metalmagazine_2.jpg"),
    alt: "image 1",
  },
  reelImg: {
    image: require("./exhibition/Felicity_1.png"),
    alt: "image 1",
  },
  mbReelImg: {
    image: require("./exhibition/mb_Felicity_1.png"),
    alt: "image 1",
  },

  // business of fashion

  bofSec4Img1: {
    image: require("./bof/bof_sec4_img_1.jpg"),
    alt: "image",
  },
  bofSec4Img2: {
    image: require("./bof/bof_sec4_img_2.jpg"),
    alt: "image",
  },
  bofSec6Img1: {
    image: require("./bof/bof_sec6_img_1.jpg"),
    alt: "image",
  },
  bofSec6Img2: {
    image: require("./bof/bof_sec6_img_2.jpg"),
    alt: "image",
  },
  bofSec8Img1: {
    image: require("./bof/bof_sec8_img_1.jpg"),
    alt: "image",
  },
  bofSec8Img2: {
    image: require("./bof/bof_sec8_img_2.jpg"),
    alt: "image",
  },

  // winners 2024 images
  winners01: {
    image: require("./winners2024/grow_award.jpg"),
    alt: "image",
  },
  winners02: {
    image: require("./winners2024/imagine_award.png"),
    alt: "image",
  },
  winners03: {
    image: require("./winners2024/create_award.png"),
    alt: "image",
  },
  winners04: {
    image: require("./winners2024/grow_award_1.png"),
    alt: "image",
  },
  winners05: {
    image: require("./winners2024/imagine_award_1.png"),
    alt: "image",
  },
  winners06: {
    image: require("./winners2024/create_award_1.png"),
    alt: "image",
  },

  winnersGrowAward: {
    image: require("./meet-winners-2024/grow_award.jpg"),
    alt: "image",
  },
  winnersImagineAward: {
    image: require("./meet-winners-2024/imagine_award.jpg"),
    alt: "image",
  },
  winnersImagineAward02: {
    image: require("./meet-winners-2024/imagine_award_winner.jpg"),
    alt: "image",
  },
  winnersCreateAward: {
    image: require("./meet-winners-2024/create_award.jpg"),
    alt: "image",
  },
};

export let newImages = {
  newBanner03: {
    image: require("./home/banner-03.jpg"),
    alt: "banner 03",
  },
  newBanner05: {
    image: require("./home/banner-05.jpg"),
    alt: "banner 05",
  },
  newBanner06: {
    image: require("./home/banner-06.jpg"),
    alt: "banner 06",
  },
  newBanner08: {
    image: require("./home/banner-08.jpg"),
    alt: "banner 08",
  },
  aboutBNY: {
    image: require("./home/about-bny.jpg"),
    alt: "about bny",
  },
  aboutBNYMB: {
    image: require("./home/about-bny-mb.jpg"),
    alt: "about bny mb",
  },
  homeanaitashroffadajania: {
    image: require("./home/anaita-shroff-adajania.jpg"),
    alt: "shana randhava portrait",
  },
  homesumitbhasin: {
    image: require("./home/sumit-bhasin.jpg"),
    alt: "sumit bhasin",
  },
  homeanitachhiba: {
    image: require("./home/anita-chhiba.jpg"),
    alt: "anita chhiba",
  },
  oursupport: {
    image: require("./home/our-support-1.jpg"),
    alt: "our support",
  },
  ourmasterclasses: {
    image: require("./home/our-masterclasses-1.jpg"),
    alt: "our masterclasses",
  },
  // Prizes images
  prizeBanner: {
    image: require("./home/prize-banner-1.jpg"),
    alt: "prize banner",
  },
  mbPrizeBanner: {
    image: require("./home/mb-prize-banner-1.jpg"),
    alt: "prize banner",
  },

  deepicaMytyala: {
    image: require("./home/deepica_mytyala.jpg"),
    alt: "Anaita Shroff Adajania",
  },
  anaitaShroffAdajania: {
    image: require("./home/Anaita_Shroff_Adajania.jpg"),
    alt: "Anaita Shroff Adajania",
  },
  annitaChibba: {
    image: require("./home/annita_chibba.jpg"),
    alt: "Anaita Shroff Adajania",
  },

  // Luminaries page images
  lumiSec4Img1: {
    image: require("./luminaries/Felicity_Ingram_metalmagazine39_17_2.jpg"),
    alt: "Felicity",
  },
  lumiSec4Img2: {
    image: require("./luminaries/Felicity_Ingram_metalmagazine39_17_19.jpg"),
    alt: "Felicity",
  },
  lumiSec4Img3: {
    image: require("./luminaries/Felicity_Ingram_metalmagazine39_17_26.jpg"),
    alt: "Felicity",
  },
  lumiSec5banner1: {
    image: require("./luminaries/lumi_sec5_banner1.jpg"),
    alt: "Felicity",
  },
  mblumiSec5banner1: {
    image: require("./luminaries/mb_lumi_sec5_banner1.jpg"),
    alt: "Felicity",
  },
  lumiSec6Img1: {
    image: require("./luminaries/Felicity_Ingram_metalmagazine39_17_22.jpg"),
    alt: "Felicity",
  },
  lumiSec6Img2: {
    image: require("./luminaries/Felicity_Ingram_metalmagazine39_17_23.jpg"),
    alt: "Felicity",
  },
  lumiSec6Img3: {
    image: require("./luminaries/Felicity_Ingram_metalmagazine39_17_27.jpg"),
    alt: "Felicity",
  },
  lumiSec7banner1: {
    image: require("./luminaries/lumi_sec7_banner1.jpg"),
    alt: "Felicity",
  },
  mblumiSec7banner1: {
    image: require("./luminaries/mb_lumi_sec7_banner1.jpg"),
    alt: "Felicity",
  },
  lummiSec9Img1: {
    image: require("./luminaries/Felicity_Ingram_metalmagazine39_17_30.jpg"),
    alt: "Felicity",
  },
  lummiSec9Img2: {
    image: require("./luminaries/Felicity_Ingram_metalmagazine39_17_29.jpg"),
    alt: "Felicity",
  },

  // Event Night Images
  eventNightSec401Img1: {
    image: require("./eventnight/slider01_img_1.jpg"),
    alt: "Felicity",
  },
  eventNightSec402Img1: {
    image: require("./eventnight/slider02_img_1.jpg"),
    alt: "Felicity",
  },
  eventNightSec5Img1: {
    image: require("./eventnight/videoSec5_img.jpg"),
    alt: "Felicity",
  },
  mbeventNightSec5Img1: {
    image: require("./eventnight/videoSec5_img.jpg"),
    alt: "Felicity",
  },
  eventNightSec601Img1: {
    image: require("./eventnight/slider04_img_1.jpg"),
    alt: "Felicity",
  },
  eventNightSec602Img1: {
    image: require("./eventnight/slider05_img_1.jpg"),
    alt: "Felicity",
  },
  eventNightSec7Img1: {
    image: require("./eventnight/videoSec7_img.jpg"),
    alt: "Felicity",
  },
  mbeventNightSec7Img1: {
    image: require("./eventnight/videoSec5_img_mb.jpg"),
    alt: "Felicity",
  },
  eventNightSec9Img1: {
    image: require("./eventnight/slider06_img_1.jpg"),
    alt: "Felicity",
  },
};
