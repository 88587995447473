import { images } from "../images";

export const bofSec4Data = [
  {
    id: 1,
    img: images.bofSec4Img1.image,
    alt: images.bofSec4Img1.alt,
    desc: ``,
    artist: "",
  },
  {
    id: 2,
    img: images.bofSec4Img2.image,
    alt: images.bofSec4Img2.alt,
    desc: `BoF's Imran Amed on stage with Katrina Kaif.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const bofSec6Data = [
  {
    id: 1,
    img: images.bofSec6Img1.image,
    alt: images.bofSec6Img1.alt,
    title: "",
    desc: ``,
    artist: "",
  },
  {
    id: 2,
    img: images.bofSec6Img2.image,
    alt: images.bofSec6Img2.alt,
    title: "FROM LEFT TO RIGHT:",
    desc: `Imran Amed, founder and CEO of The Business of Fashion; Masaba Gupta, founder of Love Child; Anita Lal, founder of Good Earth; Diipa Büller-Khosla founder of Indē Wild; Priya Rao, executive editor of BoF’s The Business of Beauty.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const bofSec8Data = [
    {
      id: 1,
      img: images.bofSec8Img1.image,
      alt: images.bofSec8Img1.alt,
      title: "",
      desc: ``,
      artist: "",
    },
    {
      id: 2,
      img: images.bofSec8Img2.image,
      alt: images.bofSec8Img2.alt,
      title: "FROM LEFT TO RIGHT:",
      desc: `Imran Amed, founder and CEO of The Business of Fashion; Bandana Tewari, lifestyle journalist, sustainability activist and former Vogue India fashion features editor; Lakshmi Menon, model; Anaita Shroff Adajania, founder of Style Cell.`,
      artist: "PHOTO BY INDIGITAL.TV.",
    },
  ];
