import React, { useEffect } from "react";
import "./aboutus.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useWindowSize } from "react-use";
import { aboutBanner, images, mbAboutBanner, whitelogo } from "../../images";
import CommonBanner from "../../components/CommonBanner/CommonBanner";

const Aboutus = () => {
  const { width } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main className="aboutus">
        <section className="about_sec1">
          <CommonBanner
            imgSrc={width > 600 ? aboutBanner : mbAboutBanner}
            title={`ABOUT`}
            logoImg={whitelogo}
          />
        </section>

        <section className="about_sec2">
          <div className="my_container">
            <div className="aboutus_content">
              <p className="aboutus_text1">
                Created by The Estée Lauder Companies’ New Incubation Ventures
                and launched with lead partner Nykaa, BEAUTY&YOU India is a
                first of its kind program that aims to discover, spotlight, and
                propel the next generation of India-focused beauty founders,
                innovators, and creatives.
              </p>
              <p className="aboutus_text1">
                With one of the world’s most vibrant economies and a
                cutting-edge startup community, India represents an exciting
                opportunity for the beauty industry. The Estée Lauder Companies
                created BEAUTY&YOU India as a platform to support aspiring
                India-focused entrepreneurs with capital, mentorship, resources
                and key partners.
              </p>
            </div>
          </div>
        </section>

        <section className="about_sec3">
          <div className="my_container">
            <div className="content_wrapper">
              <h4 className="title">OUR GOAL</h4>
              <h6 className="desc">
                To help accelerate and amplify the extraordinary creativity of
                India-focused beauty founders and creators who are approaching
                the market with a fresh take on what it means to be beautiful.
              </h6>
            </div>
          </div>
        </section>

        <section className="about_sec4">
          <div className="row custom_row_style">
            <div className="col-lg-6 order-lg-1 img_text_wrap">
              <img
                src={images.shanarandhavaportrait.image}
                alt={images.shanarandhavaportrait.alt}
                className="shana_img"
              />
            </div>
            <div className="col-lg-6 order-lg-2 p-0">
              <div className="niv_wrapper">
                <div className="content_box">
                  <img
                    src={images.aboutNIV.image}
                    alt={images.aboutNIV.alt}
                    className="niv_img"
                  />
                  <p className="section_subdesc">
                    New Incubation Ventures (NIV) is the strategic early-stage
                    investing and incubation arm for The Estée Lauder Companies.
                    <br />
                    <br />
                    NIV partners with forward-thinking founders to create, fund
                    and support the beauty brands and business models that will
                    shape the future of beauty across the world. Through this,
                    it also builds an actionable pipeline of diversified brands
                    and new engines of growth for the Estée Lauder Companies
                    portfolio.
                    <br />
                    <br />
                    The Estée Lauder Companies Inc. is one of the world’s
                    leading manufacturers and marketers of prestige skin care,
                    makeup, fragrance and hair care products. The company’s
                    products are sold in approximately 150 countries and
                    territories under brand names including: Estée Lauder,
                    Aramis, Clinique, Lab Series, Origins, M·A·C, La Mer, Bobbi
                    Brown, Aveda, Jo Malone London, Bumble and bumble, Darphin
                    Paris, TOM FORD BEAUTY, Smashbox, AERIN Beauty, Le Labo,
                    Editions de Parfums Frédéric Malle, GLAMGLOW, KILIAN PARIS,
                    Too Faced, Dr.Jart+, and the DECIEM family of brands,
                    including The Ordinary and NIOD.
                  </p>
                  <div className="text_container">
                    <h6 className="name">Shana Randhava</h6>
                    <p className="designation">
                      SENIOR VICE PRESIDENT, ELC, NEW INCUBATION VENTURES
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about_sec5">
          <div className="row custom_row_style">
            <div className="col-lg-6 order-lg-2 img_text_wrap">
              <img
                src={images.aboutAnchitnykaa.image}
                alt={images.aboutAnchitnykaa.alt}
                className="anchit_img"
              />
            </div>
            <div className="col-lg-6 order-lg-1 p-0">
              <div className="nykaa_wrapper">
                <div className="content_box">
                  <p className="section_desc">LEAD PARTNER</p>
                  <img
                    src={images.aboutNykaa.image}
                    alt={images.aboutNykaa.alt}
                    className="nykaa_img"
                  />
                  <p className="section_subdesc">
                    Born out of a desire to make beauty a mainstream choice,
                    Nykaa began in 2012 as a digitally native, consumer-tech
                    company. Through founder Falguni Nayar’s vision to bring
                    inspiration and joy to people everywhere, it tapped into an
                    underserved beauty retail market, disrupting the ecosystem
                    and putting India in the global spotlight. Today, Nykaa has
                    expanded its offerings to include lifestyle and B2B as well
                    by introducing online platforms Nykaa Fashion, Nykaa Man and
                    Superstore.
                    <br />
                    <br />
                    Over the years, Nykaa has steadily captured the hearts of
                    Indian consumers, ushering visits to both its online and 175
                    offline destinations and building loyal communities through
                    engaging and educational content. Nykaa continues to build
                    its house of brands with a sharp focus on innovation and
                    consumer delight. Beauty brands such as Kay Beauty, Nykaa
                    Naturals, Nykaa Cosmetics and Wanderlust along with fashion
                    brands such as Nykd, Gajra Gang, Likha, RSVP and Pipa Bella
                    have become household names as they consistently deliver
                    inspiration and high-performing products to the consumers.
                    <br />
                    <br />
                    Nykaa’s unwavering commitment to authenticity and putting
                    the customer at the heart of everything that they do has
                    made it the retailer of choice for international brands
                    entering India. Its Global Store, a gateway into the world
                    of coveted international brands, leverages the company’s
                    proven supply chain and marketing capabilities to offer a
                    truly seamless shopping experience.
                    <br />
                    <br />
                    For its role in building India’s beauty and lifestyle retail
                    markets, Nykaa has been awarded several Indian and
                    International accolades. Nykaa has also been recognised as
                    the Consumer Company of the Year by VCCircle Awards and was
                    proudly featured in the TIME100 Most Influential Companies
                    List.
                  </p>
                  <div className="text_container">
                    <h6 className="name">Anchit Nayar</h6>
                    <p className="designation">
                      EXECUTIVE DIRECTOR & CEO, NYKAA BEAUTY
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about_sec6">
          <div className="my_container">
            <div className="row">
              <div className="col-sm-3 col-md-3 p-0"></div>
              <div className="col-sm-6 col-md-6 p-0">
                <div className="about_brand_wrap box1">
                  <img
                    src={images.idexAbout.image}
                    alt={images.idexAbout.alt}
                    className="idex_img"
                    loading="lazy"
                  />
                  <p className="section_subdesc">
                    IDEX invests, incubates and accelerates fast growing,
                    emerging businesses and technology platforms with a focus on
                    prestige consumer brands across multiple platforms. IDEX
                    leverages a significant proprietary deal flow alongside a
                    unique screening process and deep technological diligence to
                    spot the right opportunities. Alongside our investment, our
                    teams create, build and scale.
                  </p>
                  <a
                    href="https://www.idexcap.com"
                    target="_blank"
                    rel="noreferrer"
                    className="brand_site"
                  >
                    www.idexcap.com
                  </a>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 p-0"></div>
              {/* <div className="col-sm-6 col-md-6 p-0">
                <div className="about_brand_wrap box2">
                  <img
                    src={images.latticeAbout.image}
                    alt={images.latticeAbout.alt}
                    className="lattice_img"
                    loading="lazy"
                  />
                  <p className="section_subdesc">
                    1Lattice is a technology-enabled decision support
                    organization. Our mission is to disrupt business decisions
                    through AI/ML and produce meaningful insights which can be
                    trusted by decision-makers. With a holistic approach
                    focusing on the ‘What and Why’, 1Lattice stands for the
                    ‘one-ness’ of data and the people network. 1Lattice is your
                    360-degree business decision support partner with a
                    tech-enabled service stack of consulting, advisory, and
                    research. Powered by advanced tech tools and unique
                    data-gathering approaches, it’s your one-stop shop for
                    streamlining all your key business practices.
                  </p>
                  <a
                    href="https://www.1lattice.com"
                    target="_blank"
                    rel="noreferrer"
                    className="brand_site"
                  >
                    www.1lattice.com
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Aboutus;
