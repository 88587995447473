import React from "react";
import "./pagebanner.scss";

const CommonBanner = ({
  imgSrc,
  title,
  bGPosition = "top center",
  logoImg,
}) => {
  return (
    <div className="banner_img_wrapper">
      <img
        style={{ objectPosition: bGPosition }}
        width={1440}
        height={840}
        src={imgSrc}
        alt="Banner"
        className="banner-img"
      />
      <div className={`text_container ${logoImg && "text_with_logo"}`}>
        <h2
          className="section_heading banner-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {logoImg && <img src={logoImg} alt="" className="bny_logo" />}
      </div>
    </div>
  );
};

export default CommonBanner;
