import React, { useEffect, useState } from "react";
import "./awardsevening.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  awardVideoSec3,
  awardsBanner,
  awardsEveningLeftArrow,
  awardsEveningRightArrow,
  demovideo,
  mbAwardVideoSec3,
  mbAwardsBanner,
} from "../../images";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import FsLightbox from "fslightbox-react";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import { Fade } from "react-awesome-reveal";
// import { FADE_DELAY } from "../../helpers/constant-words";
import { useWindowSize } from "react-use";
import {
  awardSec4Data,
  awardSec5SliderData,
  awardSec6Data,
  awardSec7SliderData,
  awardSec9Data,
} from "../../data/awardsEveningData";
import { Link } from "react-router-dom";

const AwardsEvening = () => {
  const { width } = useWindowSize();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const handleWatchClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  const sec4DataList = awardSec4Data.map((item, i) => (
    <div className="col-md-6 sec4_col" key={i}>
      <div className="img_wrapper">
        <img src={item.img} alt={item.alt} className="sec4_img" />
        {item.desc !== "" ? <p className="desc">{item.desc}</p> : null}
        <p className="artist">{item.artist}</p>
      </div>
    </div>
  ));

  const sec5SliderDataList = awardSec5SliderData.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="sec5_slide_img_wrapper">
        <img
          src={width > 600 ? item.img : item.mbImg}
          alt={item.alt}
          className="sec5_slide_img"
        />
        {/* <img src={item.img} alt={item.alt} className="sec5_slide_img" /> */}
        <div className="sec5_slider_text_wrapper">
          <p className="sec5_slider_text">{item.text}</p>
          <p className="sec5_slider_artist">{item.artist}</p>
        </div>
      </div>
    </SwiperSlide>
  ));

  const sec6DataList = awardSec6Data.map((item, i) => (
    <div className="col-md-6 sec6_col" key={i}>
      <div className="img_wrapper">
        <img src={item.img} alt={item.alt} className="sec6_img" />
        {item.desc !== "" ? <p className="desc">{item.desc}</p> : null}
        <p className="artist">{item.artist}</p>
      </div>
    </div>
  ));

  const sec7SliderDataList = awardSec7SliderData.map((item, i) => (
    <SwiperSlide key={i}>
      <div className="sec7_slide_img_wrapper">
        <img
          src={width > 600 ? item.img : item.mbImg}
          alt={item.alt}
          className="sec7_slide_img"
        />
        {/* <img src={item.img} alt={item.alt} className="sec7_slide_img" /> */}
        <div className="sec7_slider_text_wrapper">
          <p className="sec7_slider_text">{item.text}</p>
          <p className="sec7_slider_artist">{item.artist}</p>
        </div>
      </div>
    </SwiperSlide>
  ));

  const sec9DataList = awardSec9Data.map((item, i) => (
    <div className="col-md-6 sec9_col" key={i}>
      <div className="img_wrapper">
        <img src={item.img} alt={item.alt} className="sec9_img" />
        {item.desc !== "" ? <p className="desc">{item.desc}</p> : null}
        <p className="artist">{item.artist}</p>
      </div>
    </div>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main className="blog_main">
        <section className="awards_sec1">
          <div className="awards_banner_image_wrapper">
            {width > 600 ? (
              <img
                src={awardsBanner}
                alt="banner"
                className="awards_banner_img"
                loading="lazy"
              />
            ) : (
              <img
                src={mbAwardsBanner}
                alt="banner"
                className="awards_banner_img"
                loading="lazy"
              />
            )}
            <div className="awards_banner_content">
              {width > 600 ? (
                <h1 className="awards_head">
                  {/* the awards <br/> evening */}
                  CELEBRATING THE <span className="break">
                    2023 BEAUTY&YOU
                  </span>{" "}
                  WINNERS
                </h1>
              ) : (
                <h1 className="awards_head">
                  CELEBRATING
                  <span className="break">THE 2023 </span>{" "}
                  <span className="break">BEAUTY&YOU</span> WINNERS
                </h1>
              )}
            </div>
          </div>
        </section>

        <section className="awards_sec2">
          <div className="desc">
            This year’s BEAUTY&YOU event brought together the next generation of
            Indian founders, storytellers, and creators to reimagine how we
            think about beauty, connect with consumers, and support local
            communities – all to celebrate and elevate the immersive, sensorial
            experience that is beauty.
            <br />
            <br />
            On November 2nd, 2023, after a competitive judging process of over
            600 applications from 150+ cities around the world, the winners for
            the Breakthrough, Imagine, Grow, Create, and Impact categories were
            awarded at Mumbai’s Snowball Studios. Winners for the Community
            category were chosen using an independent online voting process on{" "}
            <Link to="/" className="desc_link">
              www.beautyandyouawards.com
            </Link>{" "}
            and BEAUTY&YOU’s social engagement.
            <br />
            <br />
            The result was a night of celebration and future-forward
            connections, driving new brands and fueling unwavering passions for
            change and innovation.
          </div>
        </section>

        {/* 
          <section className="awards_sec3">
            <div className="awards_img_container">
              <img src={sec3Img} alt="image" className="awards_img" />
            </div>
            <p className="img_desc">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard.
            </p>
          </section>
        */}

        <section className="awards_sec3">
          <div className="awards_img_container">
            <span
              className="reel_video"
              onClick={() =>
                handleWatchClick("https://www.youtube.com/watch?v=83yhNncTcy4")
              }
            >
              <img
                src={width > 600 ? awardVideoSec3 : mbAwardVideoSec3}
                alt="image"
                className="awards_img"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="161"
                height="161"
                viewBox="0 0 161 161"
                fill="none"
                className="play_button"
              >
                <circle cx="80.5" cy="80.5" r="80.5" fill="#595361" />
                <path d="M128 81L56 123.435L56 38.5648L128 81Z" fill="white" />
              </svg>
            </span>
          </div>
          <p className="img_desc">
            Overview of the 2023 BEAUTY&YOU awards ceremony.
          </p>
        </section>

        <section className="awards_sec4">
          <div className="row sec4_row">
            {sec4DataList}
            {/* <div className={`col-md-6 ${styles.sec4_col}`}>
            <div className={styles.img_wrapper}>
              <img src={sec4Img1} alt="image 1" className={styles.sec4_img} />
            </div>
          </div> */}
          </div>
        </section>

        <section className="awards_sec5">
          <div className="awards_sec5Slider_wrapper">
            <Swiper
              grabCursor={true}
              // loop={true}
              modules={[Pagination, Navigation]}
              pagination={{
                // el: ".swiper-pagination",
                clickable: true,
              }}
              navigation={{
                prevEl: ".sec5_prev",
                nextEl: ".sec5_next",
              }}
              className="sec5Swiper"
            >
              {sec5SliderDataList}
              {/* <SwiperSlide>
              <div className="sec5_slide_img_wrapper">
                <img
                  src={sec5SliderImg}
                  alt="image"
                  className="sec5_slide_img"
                />
                <p className="sec5_slider_text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard.
                </p>
              </div>
            </SwiperSlide> */}
            </Swiper>
            <div className="sec5_arrow_wrapper">
              <img
                src={awardsEveningLeftArrow}
                alt="left arrow"
                className="sec5_prev"
              />
              <img
                src={awardsEveningRightArrow}
                alt="left arrow"
                className="sec5_next"
              />
            </div>
          </div>
        </section>

        <section className="awards_sec6">
          <div className="row sec6_row">{sec6DataList}</div>
        </section>

        <section className="awards_sec7">
          <div className="awards_sec7Slider_wrapper">
            <Swiper
              grabCursor={true}
              // loop={true}
              modules={[Pagination, Navigation]}
              pagination={{
                // el: ".swiper-pagination",
                clickable: true,
              }}
              navigation={{
                prevEl: ".sec7_prev",
                nextEl: ".sec7_next",
              }}
              className="sec7Swiper"
            >
              {sec7SliderDataList}
            </Swiper>
            <div className="sec7_arrow_wrapper">
              <img
                src={awardsEveningLeftArrow}
                alt="left arrow"
                className="sec7_prev"
              />
              <img
                src={awardsEveningRightArrow}
                alt="left arrow"
                className="sec7_next"
              />
            </div>
          </div>
        </section>

        <section className="awards_sec9">
          <div className="row sec9_row">{sec9DataList}</div>
        </section>

        {/* <section className="award_line_section">
          <div className="white_line"></div>
        </section> */}

        <section className="awards_sec11">
          <div className="desc">
            <p className="desc_details head">
              Creative disruption. <br />
              Entrepreneurial sensibilities. <br />
              New ideas.
            </p>
            <p className="desc_details para">
              BEAUTY&YOU 2023’s winners set the stage for a future made better
              through boundless innovation, asking us all to dream of where our
              unique visions can take us.
              <br />
              <br />
              We look forward to seeing where the experience of beauty will take
              us in 2024.
              <br />
              For a complete list of this year’s winners, click{" "}
              <Link to="/meet-the-winners" className="desc_link">
                here
              </Link>
              .
            </p>
          </div>
        </section>
      </main>
      <section className="awards_sec10">
        <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
      </section>

      <Footer />
    </>
  );
};

export default AwardsEvening;
