import React, { useEffect, useState } from "react";
import "./meetWinners2024.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useWindowSize } from "react-use";
import { mbWinners2024Banner, winners2024Banner } from "../../images";
import FsLightbox from "fslightbox-react";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { winner2024Data } from "../../data/winners2024";

const MeetWinners2024 = () => {
  const { width } = useWindowSize();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const handleWatchClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  const winnerList = winner2024Data.map((item, i) => (
    <div className="row winners_row mx-auto" key={i}>
      <div
        className={`col-md-6 winners_class p-0 ${
          i % 2 === 0 ? "order-md-2" : "order-md-1"
        }`}
      >
        <div className="winners_img_container">
          <img src={item.image} alt={item.alt} className="winners_img" />
        </div>
      </div>

      <div
        className={`col-md-6 winners_class p-0 
      ${i % 2 === 0 ? "order-md-1" : "order-md-2"}`}
      >
        <div className="details_container">
          <h3
            className="category_head"
            style={{ color: item.color ? item.color : "" }}
            dangerouslySetInnerHTML={{ __html: item.category }}
          ></h3>
          <div className="detail_wrapper">
            <p
              className="brand"
              dangerouslySetInnerHTML={{ __html: item.brand }}
            ></p>
            <p
              className="winner_name"
              dangerouslySetInnerHTML={{ __html: item.name }}
            ></p>
          </div>
          <h6
            className="desc"
            dangerouslySetInnerHTML={{ __html: item.desc }}
          ></h6>

          <div className="winners_btn_container">
            <div className="winners_btn_wrapper">
              {item.web !== "" && (
                <a
                  href={item.web}
                  className="winners_cta"
                  rel="noreferrer"
                  target="_blank"
                >
                  WEBSITE
                </a>
              )}
            </div>
            <div className="winners_btn_wrapper">
              <a
                href={item.insta}
                className="winners_cta"
                rel="noreferrer"
                target="_blank"
              >
                INSTAGRAM
              </a>
            </div>
            <div className="winners_btn_wrapper">
              <span
                className="winners_cta"
                onClick={() => handleWatchClick(item.watch)}
              >
                WATCH NOW
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section className="winners2024_sec1">
        <CommonBanner
          imgSrc={width > 600 ? winners2024Banner : mbWinners2024Banner}
          title={`2024 ${width < 600 ? "<br />" : ""}WINNERS`}
        />
      </section>

      <section className="winners2024_sec2">
        <div className="my_container">
          <div className="winners_content">
            <p className="winners_text">
              “The winners of BEAUTY&YOU 2024 represent a diverse blend of
              creative founders and breakthrough innovation. Their ideas and
              vision truly exemplified creativity and entrepreneurial spirit,
              representative of beauty's future. For the third year in a row, we
              witnessed some extraordinary concepts and the winners stand out as
              pioneers poised to make a significant impact - each bringing a
              unique perspective to India's dynamic beauty landscape.“ Shana
              Randhava, Senior Vice President, New Incubation Ventures, The
              Estée Lauder Companies.
            </p>
          </div>
        </div>
      </section>

      <section className="winners2024_sec3">
        <div className="row mx-auto">
          <div className="col-md-12 p-0">{winnerList}</div>
        </div>
      </section>

      <section className="winners_ligibox_wrapper">
        <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
      </section>

      <Footer />
    </>
  );
};

export default MeetWinners2024;
