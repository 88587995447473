import { newImages } from "../images";

export const imgSec4Data01 = [
  {
    id: 1,
    img: newImages.eventNightSec401Img1.image,
    alt: newImages.eventNightSec401Img1.alt,
    desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 2,
    img: newImages.eventNightSec401Img1.image,
    alt: newImages.eventNightSec401Img1.alt,
    desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 3,
    img: newImages.eventNightSec401Img1.image,
    alt: newImages.eventNightSec401Img1.alt,
    desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 4,
    img: newImages.eventNightSec401Img1.image,
    alt: newImages.eventNightSec401Img1.alt,
    desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const imgSec4Data02 = [
  {
    id: 1,
    img: newImages.eventNightSec402Img1.image,
    alt: newImages.eventNightSec402Img1.alt,
    desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 2,
    img: newImages.eventNightSec402Img1.image,
    alt: newImages.eventNightSec402Img1.alt,
    desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const imgSec5SliderData = [
  {
    id: 1,
    img: newImages.eventNightSec5Img1.image,
    mbImg: newImages.mbeventNightSec5Img1.image,
    alt: newImages.eventNightSec5Img1.alt,
    text: `View of the experiential table showcasing traditional Ayurvedic ingredients and objects used in practice. Curated by Sonya Khubchandani and Forest Essentials.`,
  },
];

export const imgSec6Data01 = [
    {
      id: 1,
      img: newImages.eventNightSec601Img1.image,
      alt: newImages.eventNightSec601Img1.alt,
      desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
      artist: "PHOTO BY INDIGITAL.TV.",
    },
    {
      id: 2,
      img: newImages.eventNightSec601Img1.image,
      alt: newImages.eventNightSec601Img1.alt,
      desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion. Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
      artist: "PHOTO BY INDIGITAL.TV.",
    },
];
export const imgSec6Data02 = [
    {
      id: 1,
      img: newImages.eventNightSec602Img1.image,
      alt: newImages.eventNightSec602Img1.alt,
      desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
      artist: "PHOTO BY INDIGITAL.TV.",
    },
    {
      id: 2,
      img: newImages.eventNightSec602Img1.image,
      alt: newImages.eventNightSec602Img1.alt,
      desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
      artist: "PHOTO BY INDIGITAL.TV.",
    },
    {
      id: 3,
      img: newImages.eventNightSec602Img1.image,
      alt: newImages.eventNightSec602Img1.alt,
      desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
      artist: "PHOTO BY INDIGITAL.TV.",
    },
];

export const imgSec7SliderData = [
  {
    id: 1,
    img: newImages.eventNightSec7Img1.image,
    mbImg: newImages.mbeventNightSec7Img1.image,
    alt: newImages.eventNightSec7Img1.alt,
    text: `View of the experiential table showcasing traditional Ayurvedic ingredients and objects used in practice; Curated by Forest Essentials.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const imgSec9Data = [
  {
    id: 1,
    img: newImages.eventNightSec9Img1.image,
    alt: newImages.eventNightSec9Img1.alt,
    desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 2,
    img: newImages.eventNightSec9Img1.image,
    alt: newImages.eventNightSec9Img1.alt,
    desc: `View of a kuppi featured on the experiential table curated by Sonya Khubchandani and Forest Essentials.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];
