import React, { useEffect } from "react";
import "./privacyandcookiepolicy.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { useLocation } from "react-router-dom";

const PrivacyAndCookiePolicy = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    window.OneTrust.NoticeApi.Initialized.then(function() {

      window.OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/privacy-notices/3e411a06-0949-410e-ba4b-57c7d141218e.json"]);

      });
    
  }, [pathname]);

  const handleScroll = (scrollid, offset) => {
    const id = scrollid;
    const yOffset = offset;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <>
      <Header />
      <main>
        <section className="privacysec">
          <div className="my_container">
            <div className="row m-0">
              <div className="col-lg-3 col1 p-0">
                <h1 className="section_heading contact_heading">
                  Privacy Policy
                </h1>
              </div>
              <div className="col-lg-9 col2">
              <div
                  id="otnotice-3e411a06-0949-410e-ba4b-57c7d141218e"
                  class="otnotice"
                ></div>
                {/* <p className="section_desc ">June 5, 2023</p>
                <p className="section_desc single_para">
                  The Estée Lauder Companies respects your privacy and values
                  the relationship we have with you.
                </p>
                <p className="section_desc single_para">
                  The Estée Lauder Companies has a diverse portfolio of prestige
                  brands. You can find a list of these brands on
                  ELCompanies.com, each referred to in this Privacy Policy as a
                  “Brand”.
                </p>
                <p className="section_desc single_para">
                  This Privacy Policy describes how our Brands in India collect,
                  use, disclose and safeguard your personal information. Unless
                  otherwise indicated, all Brands in India follow this Privacy
                  Policy.
                </p>
                <p className="section_desc single_para">
                  References in this Privacy Policy to “we”, “us”, “our”, “ELC”
                  are references to the entity or entities in India responsible
                  for establishing the purposes for the processing of your
                  personal information. See the Data Controllers section below
                  for details.
                </p>

                <div className="term_content_box">
                  <p className="section_subheading"> TABLE OF CONTENTS</p>{" "}
                  <p className="section_desc term_box_para">
                    <ul className="bullet_points_wrapper">
                      <li>Information we process</li>
                      <li>How we collection information</li>
                      <li>How we use information</li>
                      <li>
                        Our Legal Basis for Processing Personal Information
                      </li>
                      <li>How we share information</li>
                      <li>How you control your information</li>
                      <li>How we use cookies</li>
                      <li>How we use information to advertise</li>
                      <li>International transfers</li>
                      <li>How we protect information</li>
                      <li>How long we retain information</li>
                      <li>How we treat children’s information</li>
                      <li>Updates to our privacy policy</li>
                      <li>Your data controllers</li>
                      <li>How to contact us</li>
                    </ul>
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading"> INFORMATION WE PROCESS</p>
                  <p className="section_desc single_para">
                    We may collect or process the following types of information
                    about you. The specific information we collect about you
                    will vary depending on the how you interact with us.
                    <ul className="bullet_points_wrapper">
                      <li>
                        <span className="bold_text">
                          Contact information and personal identifiers,
                        </span>{" "}
                        such as your name, address, email address, telephone
                        number, and username or social media handle.
                      </li>
                      <li>
                        <span className="bold_text">Device identifiers,</span>{" "}
                        such as information about your device like your MAC
                        address, IP address, or other online identifiers.
                      </li>
                      <li>
                        <span className="bold_text">
                          Demographic information,
                        </span>{" "}
                        such as your age, sex, and gender.
                      </li>
                      <li>
                        <span className="bold_text">
                          Physical characteristics,
                        </span>{" "}
                        such as your hair type and color, skin type, eye color,
                        and facial geometry if you use certain of our virtual
                        try-on applications.
                      </li>
                      <li>
                        <span className="bold_text">
                          Commercial information,
                        </span>{" "}
                        such as the products or services you have purchased,
                        returned or considered, and your product preferences.
                      </li>
                      <li>
                        <span className="bold_text">Payment information,</span>{" "}
                        such as your method of payment and payment card
                        information (including payment card number, delivery
                        address and billing address).
                      </li>
                      <li>
                        <span className="bold_text">
                          Identity verification information,
                        </span>{" "}
                        such as photo identification for in-store pick-ups at
                        one of our retail stores, loyalty member ID, and
                        authentication information (like passwords).
                      </li>
                      <li>
                        <span className="bold_text">
                          Online or network activity information,
                        </span>{" "}
                        such as information regarding your interaction with our
                        websites, mobile applications, digital properties, and
                        advertisements, information about your browsing and
                        search history on our websites or mobile applications,
                        and log file information like your browser type and
                        webpages you visit.
                      </li>
                      <li>
                        <span className="bold_text">
                          Geolocation information,
                        </span>{" "}
                        such as information that can help identify your physical
                        location (like your GPS coordinates or the approximate
                        location of your device).
                      </li>
                      <li>
                        <span className="bold_text">
                          Audio and visual information,
                        </span>{" "}
                        such as recordings of your voice when you call our
                        customer service and images we record through video
                        surveillance in our retail stores.
                      </li>
                      <li>
                        <span className="bold_text">
                          Professional or employment-related information,
                        </span>{" "}
                        such as professional licenses or certifications in
                        connection with our professional programs.
                      </li>
                      <li>
                        <span className="bold_text">
                          Health and medical information,
                        </span>{" "}
                        such as skincare concerns, diagnoses, medical reports
                        and history.
                      </li>
                      <li>
                        <span className="bold_text">User Content,</span> such as
                        your communications with us and any other content you
                        provide (including photographs and images, videos,
                        reviews, articles, survey responses, and comments).
                      </li>
                      <li>
                        <span className="bold_text">Inferences</span> drawn from
                        or created based on any of the information identified
                        above.
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">
                    {" "}
                    HOW WE COLLECT INFORMATION
                  </p>
                  <p className="section_desc single_para">
                    We may collect personal information about you from various
                    sources. For example:
                    <ul className="bullet_points_wrapper">
                      <li>
                        <span className="bold_text">Directly from you, </span>
                        {""}
                        such as when you make a purchase on one of our websites
                        or in one of our retail stores, contact us with a
                        question or complaint, use one of our mobile
                        applications or virtual try on experiences, create an
                        account on one of our websites, register for one of our
                        brand loyalty programs or marketing lists, respond to a
                        survey, participate in a contest or other promotion,
                        make an appointment or sign-up to attend an event.
                      </li>
                      <li>
                        <span className="bold_text">
                          From your friends or family members,{" "}
                        </span>
                        such as when your friend or family member sends you a
                        gift or makes a referral.
                      </li>
                      <li>
                        <span className="bold_text">
                          When you interact with our websites or emails.{" "}
                        </span>
                        When you visit our websites, or when you open or click
                        on emails we send you, we (and third parties we work
                        with) may automatically collect information from your
                        browser or device, such as device identifiers and online
                        and other network activity information using
                        technologies such as cookies, pixel tags, and similar
                        technologies. Cookies are small text files that websites
                        place on your Internet-connected device to uniquely
                        identify your browser or to store information or
                        settings in your browser. Pixel tags are small images
                        which are embedded into our websites or emails. We use
                        pixel tags to collect information about your browser or
                        device, how you interact with our websites, or whether
                        you open or click on the emails we send you. Pixel tags
                        also enable us (and third parties we work with) to place
                        cookies on your browser.
                      </li>
                      <li>
                        <span className="bold_text">
                          Through in-store and other offline technologies,{" "}
                        </span>
                        such as video surveillance, traffic counting devices and
                        WiFi technology in and around our retail stores, and
                        call recording technology when you speak to customer
                        service.
                      </li>
                      <li>
                        <span className="bold_text">
                          From our business partners and service providers, {""}
                        </span>
                        such as demographic companies, analytics providers,
                        advertising companies and networks, third-party
                        retailers or distributors, and other third parties that
                        we choose to collaborate or work with.
                      </li>
                      <li>
                        <span className="bold_text">
                          From social media platforms and networks, {""}
                        </span>
                        such as Facebook, Instagram, Twitter, Pinterest, and
                        Google. For example, we may obtain your information from
                        a social media platform or network if you interact with
                        us on social media or choose to log-in to our websites
                        using your social media credentials.
                      </li>
                      <li>
                        <span className="bold_text">
                          From other ELC Brands {""}
                        </span>
                        that you have interacted with.
                      </li>
                    </ul>
                    <p className="section_desc single_para">
                      We may combine the information we obtain from the above
                      sources. For example, we may combine information we
                      collect in our stores with information we collect online.
                    </p>
                    <p className="section_desc single_para">
                      Should you fail to provide us with the personal
                      information requested, you may not be able to take
                      advantage of some of the products or services we provide
                    </p>
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">HOW WE USE INFORMATION</p>
                  <p className="section_desc single_para">
                    We may use the information we have about you:
                    <ul className="bullet_points_wrapper">
                      <li>
                        To provide products and services to you, such as
                        fulfilling orders and processing payments, creating,
                        servicing and/or maintaining your account or loyalty
                        program membership, identifying concerns and assisting
                        with product recommendations, and managing current or
                        past purchases.
                      </li>
                      <li>
                        To communicate with you, including to respond to your
                        inquiries or complaints, and to help you place an order.
                      </li>
                      <li>
                        To administer your participation in special events,
                        contests, sweepstakes, surveys or promotions.
                      </li>
                      <li>
                        For marketing and advertising, such as to send you
                        postal mail, text messages, email, push notifications or
                        other messages, show you advertisements for products
                        and/or services tailored to your interests or profile on
                        social media and other websites.
                      </li>
                      <li>
                        To operate and understand your use of our websites and
                        mobile applications, such as to remember your
                        information so you do not have to re-enter it,
                        understand your preferred method of purchasing with us;
                        determine what browser and devices you use to visit our
                        websites or mobile applications; and to evaluate and
                        improve our services, advertisements, websites, and
                        mobile applications. For example, we use Google
                        Analytics on our websites. For specific details on how
                        Google collects and uses your personal information when
                        we use its services, please visit: How Google Uses
                        Information From Sites Or Apps That Use Our Services.
                      </li>
                      <li>
                        To operate and improve our business, including to
                        conduct analytics, provide quality assurance and process
                        adverse event or product related claims, conduct
                        research and development, and perform accounting,
                        auditing and other internal business functions.
                      </li>
                      <li>
                        For legal and security purposes, such as to detect,
                        prevent, and prosecute harmful, fraudulent, or illegal
                        activity, loss prevention, identify and repair bugs on
                        our websites or mobile applications, and to comply with
                        applicable legal requirements, relevant industry
                        standards and our policies.
                      </li>
                    </ul>
                    <p className="section_desc single_para">
                      We also may use your information in other ways for which
                      we provide specific notice at the time of collection.
                    </p>
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">
                    OUR LEGAL BASIS FOR PROCESSING PERSONAL INFORMATION
                  </p>
                  <p className="section_desc single_para">
                    Where required by law, we will use the information you
                    provide for the above purposes if:
                    <ul className="bullet_points_wrapper">
                      <li>
                        it is necessary to perform a contract to which you are
                        party (e.g., to process your payment and fulfil your
                        order;
                      </li>
                      <li>
                        we have obtained your consent (e.g., for marketing
                        communications);
                      </li>
                      <li>
                        we have a legitimate interest in doing so (including a
                        legitimate interest in performing marketing activities,
                        video surveillance, research activities, data analytics,
                        internal administration functions, or for fraud
                        prevention purposes and conducting our business in
                        compliance with relevant industry standards and our
                        policies); or
                      </li>
                      <li>
                        we need to comply with a legal obligation under
                        applicable laws.
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">HOW WE SHARE INFORMATION</p>
                  <p className="section_desc single_para">
                    We may share your personal information with:
                    <ul className="bullet_points_wrapper">
                      <li>
                        <span className="bold_text">Our Brands. {""}</span>
                        When you interact with a Brand, we may share your
                        personal information with other Brands. Those other
                        Brands may use your personal information for marketing
                        and advertising and other purposes identified in this
                        Privacy Policy.
                      </li>
                      <li>
                        <span className="bold_text">
                          Our Subsidiaries and Affiliates. {""}
                        </span>
                        We may transfer your personal information to our
                        subsidiaries and affiliates on a need-to-know basis for
                        the purposes identified in this Privacy Policy.
                      </li>
                      <li>
                        <span className="bold_text">Retail Sellers. {""}</span>
                        Products on our websites and in Brand stores in India
                        are sold by third-party retailers. When you purchase
                        products on our sites and in Brand stores, your personal
                        information will be shared with those retailers for
                        product sales and fulfilment, product returns and
                        exchanges, customer care such as chat with you or
                        responding to your inquiries, grievances and complaints.
                        You can obtain more information about the processing of
                        your personal information by such third-party retailers
                        by sending a request through our{" "}
                        <a
                          href="https://privacyportal-cdn.onetrust.com/dsarwebform/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/01aee039-914f-4bba-8e1c-046ed92ee898.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Request Portal
                        </a>
                        .
                      </li>
                      <li>
                        <span className="bold_text">
                          Service providers. {""}
                        </span>
                        We may transfer personal information to service
                        providers who perform services on our behalf based on
                        our instructions. We do not authorize these service
                        providers to use or disclose the information except as
                        necessary to perform services on our behalf or comply
                        with legal requirements. Examples of these service
                        providers include entities that process credit card
                        payments, fulfill orders, and that provide website and
                        application functionality, hosting, analytics,
                        advertising and marketing services.
                      </li>
                      <li>
                        <span className="bold_text">
                          Parties to a corporate transaction. {""}
                        </span>
                        We also reserve the right to transfer personal
                        information we have about you in the event we sell or
                        transfer all or a portion of our business or assets
                        (including in the event of a merger, acquisition, joint
                        venture, reorganization, divestiture, dissolution, or
                        liquidation).
                      </li>
                      <li>
                        <span className="bold_text">
                          Advertising Companies. {""}
                        </span>
                        We work with third party advertising companies (such as
                        advertising networks) to serve advertisements on our
                        behalf. For additional information, see the{" "}
                        <a
                          href={() => false}
                          onClick={() => handleScroll("advertise", -100)}
                        >
                          How We Use Information to Advertise
                        </a>{" "}
                        section.
                      </li>
                      <li>
                        <span className="bold_text">
                          Other third parties. {""}
                        </span>
                        In addition, we may disclose personal information about
                        you (i) if we are required to do so by law or legal
                        process, (ii) to law enforcement authorities or other
                        government officials, (iii) when we believe disclosure
                        is necessary or appropriate to prevent physical harm or
                        financial loss, or in connection with an investigation
                        of suspected or actual fraudulent or illegal activity,
                        (iv) when disclosure of your personal information is
                        otherwise required or permitted by law, or (v) with your
                        consent (such as third-party salons and spas).
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">
                    HOW YOU CONTROL YOUR INFORMATION
                  </p>
                  <p className="section_desc single_para">
                    You have rights and choices in connection with the personal
                    information we have about you.
                    <ul className="bullet_points_wrapper">
                      <li>
                        <span className="bold_text">
                          Data Subject Rights: {""}
                        </span>
                        Depending on applicable laws, you have rights with
                        respect to your personal information. For example, you
                        can request access to the personal information we
                        maintain about you, update and correct inaccuracies in
                        your personal information, and have the personal
                        information deleted or transmitted to a third-party. You
                        can also request to withdraw your consent you previously
                        provided to us, to restrict or to object to the
                        processing of your personal information, or to provide
                        us with general or specific guidelines relating to the
                        storage, deletion and sharing of your personal
                        information after your death. You can make such requests
                        through our{" "}
                        <a
                          href="https://privacyportal-cdn.onetrust.com/dsarwebform/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/01aee039-914f-4bba-8e1c-046ed92ee898.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Request Portal
                        </a>
                        .We may take reasonable steps to verify your identity
                        when you make a request. You also have the right to
                        lodge a complaint with the competent data protection
                        authority in your country.
                      </li>
                      <li>
                        <span className="bold_text">
                          Marketing & Advertising Preferences: {""}
                        </span>
                        marketing preferences. You can also opt-out of receiving
                        marketing communications (such as email, postal mail or
                        text messages) by following the unsubscribe instructions
                        sent within those communications or you can make a
                        request through our{" "}
                        <a
                          href="https://privacyportal-cdn.onetrust.com/dsarwebform/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/01aee039-914f-4bba-8e1c-046ed92ee898.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Request Portal.{" "}
                        </a>{" "}
                        When you unsubscribe from our marketing communications,
                        we will no longer use your email address or other
                        contact information for targeted advertising purposes.
                      </li>
                      <li>
                        <span className="bold_text">
                          Mobile Device & Browser Preferences: {""}
                        </span>
                        Depending on your mobile device or web browser, we may
                        request your location or request to send you push
                        notifications. You can edit your preferences using the
                        settings on your device.
                      </li>
                      <li>
                        <span className="bold_text">
                          Cookie Preferences: {""}
                        </span>
                        You can choose how certain cookies are used in
                        connection with our websites. You can edit your cookie
                        preferences at any time by editing your browser settings
                        or, in some countries, selecting the “Manage Cookies”
                        link at the bottom of each of our Brand websites. For
                        additional details see the{" "}
                        <a
                          href={() => false}
                          onClick={() => handleScroll("cookies", -100)}
                        >
                          HOW WE USE COOKIES{" "}
                        </a>
                        Section.
                      </li>
                    </ul>
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading" id="cookies">
                    HOW WE USE COOKIES
                  </p>
                  <p className="section_desc single_para">
                    Cookies are small text files that websites place on your
                    Internet-connected device to uniquely identify your browser
                    or to store information or settings in your browser which
                    allows us to remember you when you come back to our websites
                    and provide you with personalized experiences and
                    advertisements. We use different types of cookies on our
                    websites, including strictly necessary cookies, performance
                    cookies, functional cookies and targeting cookies
                  </p>
                  <p className="section_desc single_para">
                    You can edit your cookie preferences at any time by using
                    the tool available on the Brand website (if available) or by
                    editing your browser settings. In countries where the tool
                    is available, you can find a list of cookies used in
                    connection with any of our websites and edit your
                    preferences by accessing the “Manage Cookies” link at the
                    bottom of each of our Brand websites. When editing your
                    cookie preferences, please note that your settings only
                    applies to the browser you use to submit your opt-out
                    request, so if you use multiple browsers or devices, you
                    must opt-out on each browser, on each device. Your opt-out
                    is enabled using cookies so once you opt-out, if you delete
                    your browser’s saved cookies on a device, you will need to
                    opt-out again on that browser on that device.
                  </p>
                  <p className="section_desc single_para">
                    Our websites are not designed to respond to “do not track”
                    signals from browsers.
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading" id="advertise">
                    HOW WE USE INFORMATION TO ADVERTISE{" "}
                  </p>
                  <p className="section_desc single_para">
                    We may use, disclose or otherwise process your personal
                    information to advertise our products and services in
                    different ways, including targeted advertising. We work with
                    third party advertising companies (such as advertising
                    networks) to serve advertisements on our behalf. These
                    advertising companies may use cookies, pixel tags and
                    similar technologies to collect device identifiers, online
                    or network activity information, commercial information, or
                    inferences, such as information about the websites you visit
                    over time and the advertisements you click on to deliver
                    advertisements that are targeted to you or your profile. You
                    can opt-out of cookie-based advertising based on your visits
                    to our sites by editing your cookie preferences as described
                    in the{" "}
                    <a
                      href={() => false}
                      onClick={() => handleScroll("cookies", -100)}
                    >
                      HOW WE USE COOKIES{" "}
                    </a>{" "}
                    Section. Please note that even if you opt-out, you may still
                    see ads from us, but the ads will not be targeted based on
                    the websites you visit over time and the advertisements you
                    click on and may therefore be less relevant to you and your
                    interests.
                  </p>
                  <p className="section_desc single_para">
                    We also work with third-party platforms, including platforms
                    operated by social networks, to show you advertisements or
                    measure the effectiveness of our advertisements. We may
                    convert your email address, telephone number, or other
                    information into a unique value and have these third-party
                    platforms match this unique value with a user on their
                    platform or with other data they may have. This matching
                    enables us to deliver advertisements to you and others on
                    these platforms. You also can request that we refrain from
                    using your personal information in this way by contacting us
                    through our
                    <a
                      href="https://privacyportal-cdn.onetrust.com/dsarwebform/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/01aee039-914f-4bba-8e1c-046ed92ee898.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Request Portal
                    </a>
                    .
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">INTERNATIONAL TRANSFERS</p>
                  <p className="section_desc single_para">
                    In offering and providing our products and services, your
                    personal information may be transferred, stored or processed
                    in countries other than the country in which the information
                    was originally collected (such as the United States). Those
                    countries may not have the same data protection laws as your
                    country of residence, and your personal information will be
                    subject to applicable foreign laws. When we transfer your
                    personal information to other countries, we will protect
                    that information in the manner described in this Privacy
                    Policy. We will also comply with applicable legal
                    requirements providing adequate protection for the transfer
                    of personal information, such as the conclusion of data
                    transfer agreements, E.U. Standard Contractual Clauses, or
                    other applicable data transfer mechanisms. If you have
                    questions about our data transfers or would like to receive
                    a copy of any applicable data transfer agreements (where
                    required by law), you can submit a request through our
                    <a
                      href="https://privacyportal-cdn.onetrust.com/dsarwebform/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/01aee039-914f-4bba-8e1c-046ed92ee898.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Request Portal
                    </a>
                    .
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">
                    HOW WE PROTECT INFORMATION{" "}
                  </p>
                  <p className="section_desc single_para">
                    We maintain administrative, technical, and physical
                    safeguards designed to protect the personal information you
                    provide against accidental, unlawful or unauthorized
                    destruction, loss, alteration, access, disclosure, or use.
                    We restrict access to personal information on a need-to-know
                    basis to employees and authorized service providers who
                    require access to fulfil their job requirements.
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">
                    HOW LONG WE RETAIN INFORMATION{" "}
                  </p>
                  <p className="section_desc single_para">
                    In general, we retain personal information as long as
                    reasonably needed to achieve the purposes outlined in this
                    Privacy Policy. There are many factors that we use to
                    determine how long personal information is retained, such
                    as:
                    <ul className="bullet_points_wrapper">
                      <li>
                        the purposes for which the personal information was
                        collected, including to provide our products and
                        services;
                      </li>
                      <li>
                        your marketing preferences and how you engage with our
                        Brands;
                      </li>
                      <li>
                        any legal or regulatory requirements that apply to the
                        personal information; and
                      </li>
                      <li>
                        whether the personal information may be relevant to us
                        in protecting our own rights (e.g. applicable limitation
                        periods).
                      </li>
                    </ul>
                    <p className="section_desc single_para">
                      For additional information about data retention policies,
                      please submit a request through our{" "}
                      <a
                        href="https://privacyportal-cdn.onetrust.com/dsarwebform/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/01aee039-914f-4bba-8e1c-046ed92ee898.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Request Portal
                      </a>
                      .
                    </p>
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">
                    HOW WE TREAT CHILDREN’S INFORMATION{" "}
                  </p>
                  <p className="section_desc single_para">
                    Our products and services are designed for a general
                    audience and are not intended for or directed to children.
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">
                    UPDATES TO OUR PRIVACY POLICY{" "}
                  </p>
                  <p className="section_desc single_para">
                    This Privacy Policy may be updated periodically and without
                    prior notice to you to reflect changes in our personal
                    information practices. We will post a notice on our websites
                    to notify you of any significant changes to our privacy
                    practices and indicate at the top of the Privacy Policy when
                    it was most recently updated.
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">YOUR DATA CONTROLLERS </p>
                  <p className="section_desc single_para">
                    A data controller is the entity or entities responsible for
                    establishing the purposes and means for the processing of
                    your personal information. The data controllers in India are
                    as follows: Estee Lauder International, Inc. and ELCA
                    Cosmetics Pvt. Ltd.
                  </p>
                </div>

                <div className="term_content_box">
                  <p className="section_subheading">HOW TO CONTACT US </p>
                  <p className="section_desc single_para">
                    If you have any questions or comments about this Privacy
                    Policy or if you would like to exercise your rights, you can
                    contact our Data Protection Officer by submitting a request
                    through our{" "}
                    <a
                      href="https://privacyportal-cdn.onetrust.com/dsarwebform/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/01aee039-914f-4bba-8e1c-046ed92ee898.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Request Portal
                    </a>{" "}
                    or by emailing us at{" "}
                    <a href="mailto:privacy@eleurope.com">
                      privacy@eleurope.com.
                    </a>
                  </p>
                  <p className="section_desc single_para">
                    If we need, or are required, to contact you concerning any
                    event that involves your personal information, we may do so
                    by postal mail, telephone, email or through a notice on our
                    websites.
                  </p>
                </div> */}

               
              </div>
            </div>
          </div>
        </section>
      </main>

    
      <Footer />
    </>
  );
};

export default PrivacyAndCookiePolicy;
