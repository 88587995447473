import React, { useEffect, useState } from "react";
import "./masterclass.scss";
import { images, masterclassrightarrow } from "../../images";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  categoriesData,
  keywordsData,
  find as getMasterClass,
  yearsData,
} from "../../data/masterData";
import { Link } from "react-router-dom";
import { masterClass } from "../../helpers/constant-words";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { useWindowSize } from "react-use";

const Masterclass = () => {
  const { width } = useWindowSize();
  const [datas, setDatas] = useState([]);
  const [activeCategory, setActiveCategory] = useState([]);
  const [activeKeyword, setActiveKeyword] = useState([]);
  const [activeYear, setActiveYear] = useState([]);

  var selectedCategories, selectedKeywords, selectedYears;
  const handleCategoryChange = (categoryPosition, category) => {
    selectedCategories = [];
    selectedKeywords = [];
    selectedYears = [];
    const updatedSelectedCategories = activeCategory.map((itemState, index) => {
      if (category == "all") {
        return index === 0 ? true : false;
      } else {
        if (index == 0) {
          return false;
        } else {
          return index === categoryPosition ? !itemState : itemState;
        }
      }
    });

    if (!updatedSelectedCategories.includes(true)) {
      updatedSelectedCategories[0] = true;
    }
    console.log(updatedSelectedCategories);
    setActiveCategory(updatedSelectedCategories);

    updatedSelectedCategories.forEach((item, index) => {
      if (item) {
        selectedCategories.push(categoriesData[index]);
      }
    });
    activeKeyword.forEach((item, index) => {
      if (item) {
        selectedKeywords.push(keywordsData[index]);
      }
    });
    activeYear.forEach((item, index) => {
      if (item) {
        selectedYears.push(yearsData[index]);
      }
    });

    console.log(selectedCategories);

    const masterclasses = getMasterClass(
      selectedCategories,
      selectedKeywords,
      selectedYears
    );
    setDatas(masterclasses);
  };
  const handleKeywordChange = (categoryPosition, keyword) => {
    selectedKeywords = [];
    selectedCategories = [];
    selectedYears = [];
    const updatedSelectedKeywords = activeKeyword.map((itemState, index) => {
      if (keyword == "all") {
        return index === 0 ? true : false;
      } else {
        if (index == 0) {
          return false;
        } else {
          return index === categoryPosition ? !itemState : itemState;
        }
      }
    });

    if (!updatedSelectedKeywords.includes(true)) {
      updatedSelectedKeywords[0] = true;
    }
    console.log(
      "selected keywords hai :",
      updatedSelectedKeywords.includes(true)
    );
    console.log(updatedSelectedKeywords);
    setActiveKeyword(updatedSelectedKeywords);

    updatedSelectedKeywords.forEach((item, index) => {
      if (item) {
        selectedKeywords.push(keywordsData[index]);
      }
    });

    activeCategory.forEach((item, index) => {
      if (item) {
        selectedCategories.push(categoriesData[index]);
      }
    });
    activeYear.forEach((item, index) => {
      if (item) {
        selectedYears.push(yearsData[index]);
      }
    });

    console.log(selectedKeywords);

    const masterclasses = getMasterClass(
      selectedCategories,
      selectedKeywords,
      selectedYears
    );
    setDatas(masterclasses);
  };
  const handleYearChange = (categoryPosition, year) => {
    selectedCategories = [];
    selectedKeywords = [];
    selectedYears = [];
    const updatedSelectedYears = activeYear.map((itemState, index) => {
      if (year == "all") {
        return index === 0 ? true : false;
      } else {
        if (index == 0) {
          return false;
        } else {
          return index === categoryPosition ? !itemState : itemState;
        }
      }
    });

    if (!updatedSelectedYears.includes(true)) {
      updatedSelectedYears[0] = true;
    }
    console.log(updatedSelectedYears);
    setActiveYear(updatedSelectedYears);

    updatedSelectedYears.forEach((item, index) => {
      if (item) {
        selectedYears.push(yearsData[index]);
      }
    });
    activeCategory.forEach((item, index) => {
      if (item) {
        selectedCategories.push(categoriesData[index]);
      }
    });
    activeKeyword.forEach((item, index) => {
      if (item) {
        selectedKeywords.push(keywordsData[index]);
      }
    });

    console.log(selectedYears);

    const masterclasses = getMasterClass(
      selectedCategories,
      selectedKeywords,
      selectedYears
    );
    setDatas(masterclasses);
  };

  useEffect(() => {
    let defaultSelectedCategories = categoriesData.map((item) => {
      return item == "all";
    });

    setActiveCategory(defaultSelectedCategories);

    let defaultSelectedKeywords = keywordsData.map((item) => {
      return item == "all";
    });

    setActiveKeyword(defaultSelectedKeywords);

    let defaultSelectedYears = yearsData.map((item) => {
      return item == "2024";
    });

    setActiveYear(defaultSelectedYears);
  }, []);

  const categoryList = categoriesData.map((category, i) => (
    <li className="checkbox_input_wrapper category" key={i}>
      <input
        type="checkbox"
        value={category}
        name={category}
        checked={activeCategory[i]}
        id={`masterclass-checkbox-${i}`}
        className="checkox_input"
        onChange={() => handleCategoryChange(i, category)}
      />
      <label htmlFor={`masterclass-checkbox-${i}`} className="checkbox_label">
        <span className="label_text">{category}</span>
      </label>
    </li>
  ));
  const keywordsList = keywordsData.map((keyword, i) => (
    <>
      <li className="checkbox_input_wrapper keyword" key={i}>
        <input
          type="checkbox"
          id={`masterclass-keyword-${i}`}
          className="checkox_input"
          value={keyword}
          name={keyword}
          checked={activeKeyword[i]}
          onChange={() => handleKeywordChange(i, keyword)}
        />
        <label htmlFor={`masterclass-keyword-${i}`} className="checkbox_label">
          <span className="label_text">{keyword}</span>
        </label>
      </li>
    </>
  ));
  const yearList = yearsData.map((year, i) => (
    <>
      <li className="checkbox_input_wrapper keyword" key={i}>
        <input
          type="checkbox"
          id={`masterclass-year-${i}`}
          className="checkox_input"
          value={year}
          name={year}
          checked={activeYear[i]}
          onChange={() => handleYearChange(i, year)}
        />
        <label htmlFor={`masterclass-year-${i}`} className="checkbox_label">
          <span className="label_text">{year}</span>
        </label>
      </li>
    </>
  ));

  useEffect(() => {
    const masterclasses = getMasterClass(["all"], ["all"], ["2024"]);
    setDatas(masterclasses);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <CommonBanner
        bGPosition="center"
        imgSrc={
          width > 600
            ? images.newMasterclassBanner.image
            : images.newMasterclassBannerMb.image
        }
        title={`2024 ${width < 600 ? "<br />" : ""}MASTERCLASSES`}
      />
      <main className="master_main">
        <div className="my_container">
          <section className="masterclass_sec1">
            <div className="text_container">
              <h4 className="master_heading">
                HEAR FROM OUR EXPERTS ON WHAT IT TAKES TO SUCCEED IN ONE OF THE
                MOST CREATIVE BUSINESSES IN THE WORLD.
              </h4>
              <div className="section_desc master_desc">
                Join us for a series of masterclasses on the fundamentals of
                building a beauty business. Learn from senior executives at The
                Estée Lauder Companies alongside iconic founders and creators
                from the global beauty and creative industries.
              </div>
            </div>
          </section>
        </div>
        <div className="divider_line"></div>
        <div className="my_container master_container">
          <section className="masterclass_sec2">
            <div className="filter_master">
              <div className="filter_main_box">
                <div className="keywords_wrapper">
                  <ul className="keyword_list mbyear_list">{yearList}</ul>
                </div>
                <div className="categories_wrapper">
                  <ul className="category_list mbcategory_list">
                    {categoryList}
                  </ul>
                </div>
                <div className="keywords_wrapper">
                  <ul className="keyword_list mbkeyword_list">
                    {keywordsList}
                  </ul>
                </div>
              </div>

              <div className="masterclasss_wrapper">
                <div className="row">
                  {datas.map((item, i) => (
                    <div className="col-sm-6 col-md-6 col-lg-4 p-0">
                      <div className="masterclass_profile">
                        <Link
                          to={`${masterClass}/${item.slug}`}
                          style={{
                            textDecoration: "none",
                          }}
                          className="img_text_body"
                        >
                          <img
                            width={320}
                            height={320}
                            src={item.img}
                            alt={item.alt}
                            className="masterclass_img"
                            loading="lazy"
                          />
                          <h6 className="masterclass_category">
                            {item.category}
                          </h6>

                          {width > 600 ? (
                            <h6
                              className="masterclass_name"
                              dangerouslySetInnerHTML={{ __html: item.name }}
                            />
                          ) : (
                            <h6
                              className="masterclass_name"
                              dangerouslySetInnerHTML={{
                                __html: item.mobName ? item.mobName : item.name,
                              }}
                            />
                          )}
                          {width > 600 ? (
                            <p
                              className="masterclass_desc"
                              dangerouslySetInnerHTML={{
                                __html: item.designation,
                              }}
                            />
                          ) : (
                            <p
                              className="masterclass_desc"
                              dangerouslySetInnerHTML={{
                                __html: item.mobDesignation
                                  ? item.mobDesignation
                                  : item.designation,
                              }}
                            />
                          )}
                        </Link>
                        <div className="btn_wrapper">
                          <Link
                            className="general_cta"
                            to={`${masterClass}/${item.slug}`}
                          >
                            Information
                            <img
                              width={10.5}
                              height={10}
                              src={masterclassrightarrow}
                              alt=""
                              className="right_arrow"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Masterclass;
