import React from "react";
import { Link } from "react-router-dom";
import { homepage } from "../../helpers/constant-words";
import "./bannercontent.scss";

const BannerContent = ({
  bannerTitle,
  bannerDesc,
  ctaURL = homepage,
  ctaText = "LEARN MORE",
  featuredText,
  featuredImg,
  titleIcon,
  bannerClass,
}) => {
  return (
    <div className={`banner_text_container ${bannerClass}`}>
      <h2 className="banner_title">
        <span dangerouslySetInnerHTML={{ __html: bannerTitle }} />
        {titleIcon && (
          <img src={titleIcon} alt="" className="title_icon" loading="lazy" />
        )}
      </h2>
      {bannerDesc && (
        <p
          className="banner_desc"
          dangerouslySetInnerHTML={{ __html: bannerDesc }}
        />
      )}
      <div className="cta_wrapper">
        <Link to={ctaURL} className="project-button banner_cta white-cta">
          {ctaText}
        </Link>
      </div>
      {featuredImg && (
        <div className="featured_wrap">
          <div className="feature_text">{featuredText}</div>
          <img
            src={featuredImg}
            alt="feature"
            className="featured_img"
            loading="lazy"
          />
        </div>
      )}
    </div>
  );
};

export default BannerContent;
