import React, { useEffect, useState } from "react";
import "./press.scss";
import { images } from "../../images";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { reduceDescSize } from "../../helpers/helper";
import config from "../../config/config";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonBanner from "../../components/CommonBanner/CommonBanner";
import { pressData } from "../../data/Data";
import { useWindowSize } from "react-use";

const Press = () => {
  const { width } = useWindowSize();
  const { pathname, state } = useLocation();
  const [datas, setDatas] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState(state?.publisher);
  const [loading, setloading] = useState(false);
  const [loadingmore, setloadingmore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const dataLimit = 6;
  const [dataCount, setdataCount] = useState(0);
  const base_url = config.api_url + "2024/press"; //without trailing slash
  const image_url = config.image_url + "/press-2024/"; //with trailing slash

  const handleLoadMore = () => {
    console.log("loading more");
    console.log({ dataCount, dataLimit });
    setloadingmore(true);
    fetchDatas(dataCount + dataLimit, selectedPublisher);
    setdataCount(dataCount + dataLimit);
    setloadingmore(false);
  };
  const handleChange = (e) => {
    let publisher = e.target.value;
    setSelectedPublisher(publisher);
    // if(publisher){
    setloading(true);
    fetchDatas(0, publisher);
    setdataCount(0);
    setHasMore(true);
    setloading(false);
    // }
  };

  const fetchDatas = async (offset = 0, publisher = null) => {
    let url = `${base_url}`;

    let params = {};

    if (offset) {
      params.offset = offset;
    }
    if (publisher && publisher != "") {
      params.publisher = publisher;
    }

    const { data } = await http.get(url, { params });

    if (data) {
      if (offset) {
        setDatas((prevState) => {
          return [...prevState, ...data];
        });
      } else {
        setDatas(data);
      }
    }

    if (data?.length < dataLimit) {
      setHasMore(false);
    }
  };

  const fetchPublisher = async () => {
    const data = await http.get(`${base_url}/publishers`);
    if (data) {
      setPublishers(data.data);
    }
  };

  useEffect(() => {
    const defaultPublisher = state?.publisher;
    console.log({ defaultPublisher });
    if (defaultPublisher) {
      setSelectedPublisher(defaultPublisher);
    }

    setloading(true);
    fetchDatas(0, defaultPublisher);
    fetchPublisher();
    setloading(false);
  }, [pathname]);

  const publisherList = publishers.map((item) => {
    return <option value={item._id}>{item.title}</option>;
  });

  const pressList = datas.map((item, i) => {
    return (
      <div className="col-sm-6 col-md-6 col-lg-4 press_col" data-id={i} key={i}>
      <div className="img_content">
        <div className="col_img_wrapper">
          <img src={image_url +  item.image} alt="" className="col_img" />
        </div>
        <div className="content_box">
          <p className="content_date">
            {helper.dateFormatter(item.date, "MMMM D, YYYY")}
          </p>
          <h6 className="press_content">
            <p
              dangerouslySetInnerHTML={{
                __html: reduceDescSize(item.title, 80).replace(
                  /(<? *script)/gi,
                  "illegalscript"
                ),
              }}
            ></p>
          </h6>
          <p className="press_author">{item.author}</p>
        </div>
      </div>
      <div className="press_btn_wrapper">
        <a href={item.website} className="project-button press_button">
          READ MORE
        </a>
      </div>
    </div>
    );
  });

  const staticPressList = pressData.map((item, i) => {
    return (
      <div className="col-sm-6 col-md-6 col-lg-4 press_col" data-id={i} key={i}>
        <div className="img_content">
          <div className="col_img_wrapper">
            <img src={item.img} alt="" className="col_img" />
          </div>
          <div className="content_box">
            <p className="content_date">
              {helper.dateFormatter(item.date, "MMMM D, YYYY")}
            </p>
            <h6 className="press_content">
              <p
                dangerouslySetInnerHTML={{
                  __html: reduceDescSize(item.description, 80).replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></p>
            </h6>
            <p className="press_author">{item.author}</p>
          </div>
        </div>
        <div className="press_btn_wrapper">
          <a href={item.website} className="project-button press_button">
            READ MORE
          </a>
        </div>
      </div>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main className="press">
        <CommonBanner
          imgSrc={
            width > 600 ? images.pressBanner.image : images.pressBannerMb.image
          }
          title="PRESS"
        />

        <div className="my_container">
          <section className="press_sec1">
            <h1 className="section_subheading">
              Read what others say about us
            </h1>
          </section>
        </div>
        <div className="divider_line"></div>
        <div className="my_container">
          <section className="press_sec2">
            <InfiniteScroll
              className="row press_row"
              dataLength={dataCount} //This is important field to render the next data
              next={handleLoadMore}
              hasMore={hasMore}
              loader={<h4 className="loader_text">Loading...</h4>}
            >
              {/* <div className="col-md-4 press_col">
                <div className="col_img_wrapper">
                  <img
                    src={images.bnyoupress.image}
                    alt={images.bnyoupress.alt}
                    className="col_img"
                  />
                </div>
                <div className="content_box">
                  <p className="content_date">June 07, 2023</p>
                  <h6 className="press_content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: reduceDescSize(
                          "Introducing BEAUTY&YOU 2023: Supporting The Next Generation Of Indian Beauty Entrepreneurs and Creators / 2023 Decoding the Indian Beauty Landscape Report",
                          80
                        ).replace(/(<? *script)/gi, "illegalscript"),
                      }}
                    ></p>
                  </h6>
                  <p className="press_author">BEAUTY&YOU</p>
                </div>
                <div className="press_btn_wrapper">
                  <a
                    href={bnypressdocNew}
                    className="project-button   press_button"
                  >
                    READ MORE
                  </a>
                </div>
              </div> */}
              {pressList}
              {/* {staticPressList} */}
            </InfiniteScroll>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Press;
