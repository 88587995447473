import React, { useEffect } from "react";
import "./PageNotFound.scss";
import { homepage } from "../../helpers/constant-words";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="pagenotsec">
      <h1>
        404! <br /> page not found <br />
        Go Back to&nbsp;
        <Link to={homepage}>Home</Link>
      </h1>
    </main>
  );
};

export default PageNotFound;
