import { demovideo, finalist, images } from "../images";

export const imgSec4Data = [
  {
    id: 1,
    img: images.sec4Img1.image,
    alt: images.sec4Img1.alt,
    desc: `Imran Amed, founder and editor-in-chief of The Business of Fashion.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 2,
    img: images.sec4Img2.image,
    alt: images.sec4Img2.alt,
    desc: `Shana Randhava, SVP of The Estée Lauder Companies’ New Incubation Ventures (left) and Deepica Mutyala, CEO & founder of Live Tinted (right).`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 3,
    img: images.sec4Img3.image,
    alt: images.sec4Img3.alt,
    desc: `Hetali Kamdar, founder of BABOSKI (left) and Sanchaya Soundhar, founder & creative director of vēr (right).`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const imgSec5SliderData = [
  {
    id: 1,
    img: images.sec5Img1.image,
    mbImg: images.mbSec5Img1.image,
    alt: images.sec5Img1.alt,
    text: `View of the experiential table showcasing traditional Ayurvedic ingredients and objects used in practice. Curated by Sonya Khubchandani and Forest Essentials.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const imgSec6Data = [
  {
    id: 1,
    img: images.sec6Img1.image,
    alt: images.sec6Img1.alt,
    desc: `Akanksha Kamath, writer & creative consultant (left) and Anita Chhiba, founder of Diet Paratha (right).`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 2,
    img: images.sec6Img2.image,
    alt: images.sec6Img2.alt,
    desc: `Bhumi Pednekar, actor & investor.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 3,
    img: images.sec6Img3.image,
    alt: images.sec6Img3.alt,
    desc: `Scaffolding of Ritual’ installation by Howareyoufeeling.studio.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const imgSec7SliderData = [
  {
    id: 1,
    img: images.sec7Img1.image,
    mbImg: images.mbSec7Img1.image,
    alt: images.sec7Img1.alt,
    text: `View of the experiential table showcasing traditional Ayurvedic ingredients and objects used in practice; Curated by Forest Essentials.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const imgSec9Data = [
  {
    id: 1,
    img: images.sec9Img1.image,
    alt: images.sec9Img1.alt,
    desc: `View of a kuppi featured on the experiential table curated by Sonya Khubchandani and Forest Essentials.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 2,
    img: images.sec9Img2.image,
    alt: images.sec9Img2.alt,
    desc: `View of the experiential table with a portrait by Nishanth Radhakrishnan in the background. Table curated by Sonya Khubchandani and Forest Essentials.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const reelData = [
  {
    id: 1,
    type: "video",
    video: demovideo,
    desc: `Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
    placeholder: "REEL <br /> PLACEHOLDER",
    img: finalist,
    backgroundImg: images.reelImg.image,
    backgroundMbImg: images.mbReelImg.image,
  },
];
