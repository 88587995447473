import { images } from "../images";

export const awardSec4Data = [
  {
    id: 1,
    img: images.awardSec4Img1.image,
    alt: images.awardSec4Img1.alt,
    desc: `Hetali Kamdar, Imagine Award Winner for BABOSKI (left) and Gaurav Gupta, couturier/artist/designer (right).`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 2,
    img: images.awardSec4Img2.image,
    alt: images.awardSec4Img2.alt,
    desc: `Rupesh Pawar (left) and Khanak Gupta (right), Impact Award Winners for Sohrai.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 3,
    img: images.awardSec4Img3.image,
    alt: images.awardSec4Img3.alt,
    desc: `Dr. Avani Mainkar, Breakthrough Award Winner for Keva fragrances, Flavours & Aroma Ingredients.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const awardSec5SliderData = [
  {
    id: 1,
    img: images.awardSec5banner1.image,
    mbImg: images.mbAwardSec5banner1.image,
    alt: images.awardSec5banner1.alt,
    text: `Avanne Dubash, host (left) and Anita Chhiba, founder of Diet Paratha (middle) presenting Priya Minhas the Create Award for her directorial work.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const awardSec6Data = [
  {
    id: 1,
    img: images.awardSec6Img1.image,
    alt: images.awardSec6Img1.alt,
    desc: `Shara Ticku, Impact Award Winner for C16 Biosciences.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 2,
    img: images.awardSec6Img2.image,
    alt: images.awardSec6Img2.alt,
    desc: `Anousha Chauhan (left) and Shreyansh Chauhan (right), Community Award Winners for Beautywise.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
  {
    id: 3,
    img: images.awardSec6Img3.image,
    alt: images.awardSec6Img3.alt,
    desc: `Giti Datt, Community Award Winner for Havah.`,
    artist: "PHOTO BY INDIGITAL.TV.",
  },
];

export const awardSec7SliderData = [
    {
      id: 1,
      img: images.awardSec7banner1.image,
      mbImg: images.mbAwardSec7banner1.image,
      alt: images.awardSec7banner1.alt,
      text: `Hetali Kamdar, Imagine Award Winner for BABOSKI (left) and Gaurav Gupta, couturier/ artist/designer (right).`,
      artist: "PHOTO BY INDIGITAL.TV.",
    },
  ];
  
  export const awardSec9Data = [
    {
      id: 1,
      img: images.awardSec9Img1.image,
      alt: images.awardSec9Img1.alt,
      desc: `Rhea Goenka Jalan, Breakthrough Award Winner for Biome Laboratories.`,
      artist: "PHOTO BY INDIGITAL.TV.",
    },
  ];