import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import {
  commonfooterstrip,
  hamburgerclose,
  hamburgeropen,
  insta,
  linke,
  blacklogo,
  whitelogo,
  ytlogo,
  termsandconditionsPDF,
} from "../../images";
import {
  aboutUsURL,
  applicationcriteria,
  contactUs,
  instaLink,
  judges,
  linkedinLink,
  masterClass,
  pressURL,
  awardsURL,
  faqURL,
  privacyandcookiepolicyroute,
  businessOfFashionURL,
  awardsEveningURL,
  prevWinnersURL,
  twentThreeWinnersURL,
  exhibitionURL,
  accountRegister,
  votingURL,
  finalist2024URL,
  twentyFourWinnersURL,
} from "../../helpers/constant-words";
import "./Header.scss";
const body = document.querySelector("body");

const Header = React.forwardRef(
  ({ slug, mainHead, logo, fixedLogo, menu, initialMenu, hCta }, headerRef) => {
    const { width: windowWidth } = useWindowSize();
    const [openMenu, setOpenMenu] = useState(false);
    const [isBg, setIsbg] = useState(false);
    const [isnew, setnew] = useState(false);
    const { pathname: locationPathname } = useLocation();
    const [isSticky, setIsSticky] = useState(false);
    const [dropWinner, setDropWinner] = useState(false);
    const [drop2023, setDrop2023] = useState(false);
    const handleClick = () => {
      if (!openMenu) {
        // Disable scroll
        body.style.overflow = "hidden";
      } else {
        // Enable scroll
        body.style.overflow = "auto";
      }
      setOpenMenu(!openMenu);
    };

    useEffect(() => {
      if (
        window.location.pathname !== "/contact-us" &&
        window.location.pathname !== "/faq" &&
        window.location.pathname !== "/account/login" &&
        window.location.pathname !== "/account/sign-up" &&
        window.location.pathname !== "/account/register" &&
        window.location.pathname !== "/account/register/thankyou" &&
        window.location.pathname !== "/account/reset"
      ) {
        setIsbg(false);
      } else {
        setIsbg(true);
      }
    }, []);

    useEffect(() => {
      if (
        window.location.pathname.startsWith("/master-class/") &&
        window.location.pathname.split("/").length > 2
      ) {
        setIsbg(true);
      } else {
        setIsbg(false);
      }
    }, []);

    useEffect(() => {
      body.style.overflow = "auto";
      setOpenMenu(false);
    }, [locationPathname]);

    useEffect(() => {
      const headerHandleScroll = () => {
        if (window.location.pathname !== "/meet-the-winners") {
          setIsSticky(window.pageYOffset > (windowWidth > 600 ? 700 : 600));
          setIsbg(
            window.pageYOffset > (windowWidth > 600 ? 700 : 600) || isFixed
          );
        }
        if (
          window.location.pathname.startsWith("/master-class/") &&
          window.location.pathname.split("/").length > 2
        ) {
          setIsbg(true);
        } else {
          setIsSticky(window.pageYOffset > (windowWidth > 600 ? 50 : 50));
          setIsbg(
            window.pageYOffset > (windowWidth > 600 ? 50 : 50) || isFixed
          );
        }
      };

      window.addEventListener("scroll", headerHandleScroll);

      return () => {
        window.removeEventListener("scroll", headerHandleScroll);
      };
    });

    const isContactUs = window.location.pathname === "/contact-us";
    const isFAQ = window.location.pathname === "/faq";
    const isLogin = window.location.pathname === "/account/login";
    const isSignUp = window.location.pathname === "/account/sign-up";
    const isRegister = window.location.pathname === "/account/register";
    const isRegisterThankyou =
      window.location.pathname === "/account/register/thankyou";
    const isReset = window.location.pathname === "/account/reset";
    const isTerms = window.location.pathname === "/terms-and-conditions";
    const isPrivacy = window.location.pathname === "/privacy-and-policy";
    const isTransparent =
      isTerms ||
      isPrivacy ||
      isContactUs ||
      isFAQ ||
      isLogin ||
      isSignUp ||
      isRegister ||
      isRegisterThankyou ||
      isReset;

    const isFixed =
      isTerms ||
      isPrivacy ||
      isContactUs ||
      isFAQ ||
      isLogin ||
      isSignUp ||
      isRegister ||
      isRegisterThankyou ||
      isReset;

    return (
      <>
        <header
          className={`header ${isTransparent ? "active" : ""} ${
            // isSticky || isFixed ? "fixed" : ""
            isSticky || isFixed ? (isnew ? mainHead : "fixed") : ""
          }`}
          ref={headerRef}
        >
          <div className="my_container full_width_container zero_padding">
            <div className="header_flex">
              {/* {window.location.pathname !== "/meet-the-winners" ? (
                windowWidth > 600 ? (
                  <div className="apply_btns_wrapper ">
                    <Link to={accountRegister}>
                      <span
                        className={`project-button header_cta ${
                          isSticky || isFixed || isBg ? "" : "white-cta"
                        }`}
                      >
                        Apply Now
                      </span>
                    </Link>
                  </div>
                ) : (
                  ""
                )
              ) : null} */}
              <Link to="/" className="brandlogo_wrapper">
                <img
                  src={
                    isBg
                      ? isnew
                        ? logo
                        : blacklogo
                      : isFixed
                      ? blacklogo
                      : whitelogo
                  }
                  alt="Brand logo"
                  className="brandlogowhite"
                />
              </Link>
            </div>
          </div>
        </header>
        <div
          className={`nav_icon ${openMenu ? "open_nav" : ""} ${
            // isBg ? "active" : ""
            isBg ? (isnew ? menu : "active") : isnew ? initialMenu : ""
          } ${isTransparent ? "active" : ""} ${
            // isSticky || isFixed ?  "fixed" : ""
            isSticky || isFixed ? "fixed" : ""
          }`}
          onClick={handleClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`full_nav_wrapper ${openMenu ? "active" : ""}`}>
          <div className="hamburger_empty_wrapper" onClick={handleClick}></div>
          <div className="hamburger_inside_wrapper">
            <nav className="main_nav_links_wrapper">
              <ul className="nav_links_list">
                {/* {windowWidth < 991 && (
                  <li>
                    <NavLink to={accountRegister} className="nav_link">
                      APPLY NOW
                    </NavLink>
                  </li>
                )} */}
                <li>
                  <NavLink to={aboutUsURL} className="nav_link">
                    ABOUT BEAUTY&YOU
                  </NavLink>
                </li>
                <li>
                  <NavLink to={twentyFourWinnersURL} className="nav_link">
                    2024 Winners
                  </NavLink>
                </li>
                <li>
                  <NavLink to={finalist2024URL} className="nav_link">
                    MEET THE 2024 FINALISTS
                  </NavLink>
                </li>
                <li>
                  <NavLink to={judges} className="nav_link">
                    JUDGES
                  </NavLink>
                </li>
                <li>
                  <NavLink to={applicationcriteria} className="nav_link">
                    APPLICATION CRITERIA
                  </NavLink>
                </li>
                <li>
                  <NavLink to={awardsURL} className="nav_link">
                    PRIZES
                  </NavLink>
                </li>
                <li>
                  <NavLink to={masterClass} className="nav_link">
                    MASTERCLASSES
                  </NavLink>
                </li>
                <li>
                  <div
                    className="nav_link winner_drop"
                    onClick={() => {
                      setDropWinner((prev) => !prev);
                      setDrop2023(false);
                    }}
                  >
                    <span>PAST WINNERS</span>
                    <img
                      src={dropWinner ? hamburgeropen : hamburgerclose}
                      alt="close and open"
                      className="open_close_icon"
                      loading="lazy"
                    />
                  </div>
                  <ul className={`winners_list ${dropWinner && "active"}`}>
                    <li className="list-item">
                      <NavLink
                        to={twentThreeWinnersURL}
                        className="winner_link"
                      >
                        2023 Winners
                      </NavLink>
                    </li>
                    <li className="list-item">
                      <NavLink to={prevWinnersURL} className="winner_link">
                        2022 Winners
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <div
                    className="nav_link winner_drop"
                    onClick={() => {
                      setDrop2023((prev) => !prev);
                      setDropWinner(false);
                    }}
                  >
                    <span>2023 PROGRAM</span>
                    <img
                      src={drop2023 ? hamburgeropen : hamburgerclose}
                      alt="close and open"
                      className="open_close_icon"
                      loading="lazy"
                    />
                  </div>
                  <ul className={`winners_list ${drop2023 && "active"}`}>
                    <li className="list-item">
                      <NavLink to={awardsEveningURL} className="winner_link">
                        2023 AWARDS EVENING
                      </NavLink>
                    </li>
                    <li className="list-item">
                      <NavLink to={exhibitionURL} className="winner_link">
                        2023 FINALISTS DINNER & EXHIBITION
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to={businessOfFashionURL} className="nav_link">
                    THE BUSINESS OF INDIAN BEAUTY
                  </NavLink>
                </li>
                <li>
                  <NavLink to={faqURL} className="nav_link">
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink to={pressURL} className="nav_link">
                    PRESS
                  </NavLink>
                </li>
                <li>
                  <NavLink to={contactUs} className="nav_link">
                    CONTACT
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to={meetWinnersURL} className="nav_link">
                    MEET THE 2024 WINNERS
                  </NavLink>
                </li>
                <li>
                  <NavLink to={luminariesURL} className="nav_link">
                    LUMINARIES
                  </NavLink>
              </li>*/}
                {/* <li>
                  <NavLink to={finalistURL} className="nav_link">
                    MEET THE 2024 FINALISTS
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink to={votingURL} className="nav_link">
                    Community voting
                  </NavLink>
                </li> */}
                {/* <li>
                <NavLink
                  to={() => false}
                  style={{ pointerEvents: "none" }}
                  className="nav_link"
                >
                  SPONSORS
                </NavLink>
              </li> */}
                {/* <li>
                <NavLink 
                  style={{ pointerEvents: "none" }} to={() => false} className="nav_link">
                  2023 WINNERS
                </NavLink>
              </li> */}
              </ul>
              {/* <div className="social_icons_wrapper">
              <a href="#">
                <img src={fb} alt="Social Icon" />
              </a>
              <a
                href={instaLink}
                target="_blank" rel="noreferrer"
              >
                <img src={insta} alt="Social Icon" />
              </a>
              <a href="#">
                <img src={linke} alt="Social Icon" />
              </a>
              <a href="#">
                <img src={twiiter} alt="Social Icon" />
              </a>
            </div> */}
            </nav>
            <div className="footer_flex">
              <div className="footer_left">
                <p className="link1">BEAUTY&YOU is presented by</p>
                <figure className="line2_img">
                  <img src={commonfooterstrip} alt="" loading="lazy" />
                </figure>
                <p className="link2">
                  <Link
                    to={termsandconditionsPDF}
                    target="_blank"
                    rel="noreferrer"
                  >
                    TERMS & CONDITIONS
                  </Link>
                </p>
                <p className="link3">
                  <Link to={privacyandcookiepolicyroute}>PRIVACY POLICY</Link>
                </p>
              </div>
              <div className="footer_right">
                <Link to={contactUs} className="footer_link">
                  CONTACT
                </Link>
                <div className="footer_social_icons_wrapper">
                  <a href={instaLink} target="_blank" rel="noreferrer">
                    <img src={insta} alt="Social Icon" />
                  </a>
                  <a href={linkedinLink} target="_blank" rel="noreferrer">
                    <img src={linke} alt="Social Icon" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCo2cJCpgCOVf4RxmqTmuXDw?app=desktop"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={ytlogo} alt="Social Icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default Header;
