import React, { useEffect, useState } from "react";
import "./businessoffashion.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {
  bofBanner,
  bofVideoBannerSec3,
  bofVideoBannerSec5,
  bofVideoBannerSec7,
  demovideo,
  mbBofBanner,
  mbBofVideoBannerSec3,
  mbBofVideoBannerSec5,
  mbBofVideoBannerSec7,
  mbExhibitionBanner,
  mbVideoSec3,
  videoSec3,
} from "../../images";
import FsLightbox from "fslightbox-react";
// import Aos from "aos";
// import "aos/dist/aos.css";
// import { Fade } from "react-awesome-reveal";
// import { FADE_DELAY } from "../../helpers/constant-words";
import { useWindowSize } from "react-use";
import {
  bofSec4Data,
  bofSec6Data,
  bofSec8Data,
} from "../../data/businessOfFashionData";

const BusinessOfFashion = () => {
  const { width } = useWindowSize();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const handleWatchClick = (videoLink) => {
    setIsVideoModalOpen(!isVideoModalOpen);
    setVidLink(videoLink);
  };

  const sec4DataList = bofSec4Data.map((item, i) => (
    <div className="col-md-12 sec4_col" key={i}>
      {/* <Fade delay={FADE_DELAY}> */}
      <div
        className="img_wrapper"
        // data-aos="fade-up"
      >
        <img
          src={item.img}
          alt={item.alt}
          className="sec4_img"
          loading="lazy"
        />
        {item.desc !== "" ? (
          <p
            className="desc"
            // data-aos="fade-up"
          >
            {item.desc}
          </p>
        ) : null}
        {item.artist !== "" ? (
          <p
            className="artist"
            // data-aos="fade-up"
          >
            {item.artist}
          </p>
        ) : null}
      </div>
      {/* </Fade> */}
    </div>
  ));

  const sec6DataList = bofSec6Data.map((item, i) => (
    <div className="col-md-12 sec4_col" key={i}>
      {/* <Fade delay={FADE_DELAY}> */}
      <div
        className="img_wrapper"
        // data-aos="fade-up"
      >
        <img
          src={item.img}
          alt={item.alt}
          className="sec4_img"
          loading="lazy"
        />
        {item.desc !== "" ? (
          <div
            className="desc_wrapper"
            // data-aos="fade-up"
          >
            <p className="title">{item.title}</p>
            <p className="desc">{item.desc}</p>
          </div>
        ) : null}
        {item.artist !== "" ? (
          <p
            className="artist"
            // data-aos="fade-up"
          >
            {item.artist}
          </p>
        ) : null}
      </div>
      {/* </Fade> */}
    </div>
  ));

  const sec8DataList = bofSec8Data.map((item, i) => (
    <div className="col-md-12 sec4_col" key={i}>
      {/* <Fade delay={FADE_DELAY}> */}
      <div
        className="img_wrapper"
        // data-aos="fade-up"
      >
        <img
          src={item.img}
          alt={item.alt}
          className="sec4_img"
          loading="lazy"
        />
        {item.desc !== "" ? (
          <div
            className="desc_wrapper"
            // data-aos="fade-up"
          >
            <p className="title">{item.title}</p>
            <p className="desc">{item.desc}</p>
          </div>
        ) : null}
        {item.artist !== "" ? (
          <p
            className="artist"
            // data-aos="fade-up"
          >
            {item.artist}
          </p>
        ) : null}
      </div>
      {/* </Fade> */}
    </div>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main className="blog_main">
        {/* <Fade> */}
        <section
          className="bof_sec1"
          // data-aos="fade"
        >
          <div className="bof_banner_image_wrapper">
            {width > 600 ? (
              <img
                src={bofBanner}
                alt="banner"
                className="bof_banner_img"
                loading="lazy"
              />
            ) : (
              <img
                src={mbBofBanner}
                alt="banner"
                className="bof_banner_img"
                loading="lazy"
              />
            )}
            <div
              className="bof_banner_content"
              // data-aos="fade"
            >
              {width > 600 ? (
                <h1 className="bof_head">
                  THE FUTURE OF INDIAN{" "}
                  <span className="head_break">BEAUTY WITH THE</span>{" "}
                  <span className="head_break">BUSINESS OF FASHION</span>
                </h1>
              ) : (
                <h1 className="bof_head">
                  <span className="head_break">THE FUTURE OF</span>
                  <span className="head_break">INDIAN BEAUTY</span>
                  <span className="head_break">WITH THE</span>
                  <span className="head_break">BUSINESS OF</span>
                  <span className="head_break">FASHION</span>
                </h1>
              )}
            </div>
          </div>
        </section>
        {/* </Fade> */}

        {/* <Fade delay={FADE_DELAY}> */}
        <section className="bof_sec2">
          <div className="my_container">
            <div
              className="desc"
              // data-aos="fade-up"
            >
              The business of beauty is constantly evolving. To succeed,
              founders must be in a perpetual learning mode, seeking inspiration
              and uplifting others for the benefit of the larger community. On
              November 2, 2023 at the BEAUTY&YOU awards, Imran Amed, founder and
              CEO of The Business of Fashion (BOF), and Priya Rao, executive
              editor of The Business of Beauty at BOF moderated three panels on
              the future of Indian beauty.
              <br />
              <br />
              Candid discussions on everything from celebrity beauty brands to
              building a business from scratch, to re-imagining Indian beauty
              representation across all media gripped the audience in
              inspiration and thought – amplifying the call to celebrate and
              continue fueling what Shroff Adajania calls the “beauty
              revolution” in India.
            </div>
          </div>
        </section>
        {/* </Fade> */}

        {/* <Fade delay={FADE_DELAY}> */}
        <section className="bof_sec3">
          <div
            className="bof_img_container"
            // data-aos="fade-up"
          >
            <span
              className="reel_video"
              onClick={() =>
                handleWatchClick("https://www.youtube.com/watch?v=9rFz1Yw7oTY")
              }
            >
              <img
                src={width > 600 ? bofVideoBannerSec3 : mbBofVideoBannerSec3}
                alt="image"
                className="bof_img"
                loading="lazy"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="119"
                height="118"
                viewBox="0 0 119 118"
                fill="none"
                className="play_button"
              >
                <path
                  d="M59.5 108.167C86.8841 108.167 109.083 86.1539 109.083 58.9999C109.083 31.8459 86.8841 9.83325 59.5 9.83325C32.1158 9.83325 9.91663 31.8459 9.91663 58.9999C9.91663 86.1539 32.1158 108.167 59.5 108.167Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M49.5834 39.3333L79.3334 58.9999L49.5834 78.6666V39.3333Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
          <p
            className="img_desc"
            // data-aos="fade-up"
          >
            Actress and founder of Kay Beauty Katrina Kaif talks about the
            secret to a celebrity-owned beauty brand.
          </p>
        </section>
        {/* </Fade> */}

        {/* <Fade delay={FADE_DELAY}> */}
        <section className="bof_sec4">
          <div className="my_container">
            <div
              className="row sec4_row"
              // data-aos="fade-up"
            >
              {sec4DataList}
            </div>
          </div>
        </section>
        {/* </Fade> */}

        {/* <Fade delay={FADE_DELAY}> */}
        <section className="bof_sec3 bof_sec5">
          <div
            className="bof_img_container"
            // data-aos="fade-up"
          >
            <span
              className="reel_video"
              onClick={() =>
                handleWatchClick("https://www.youtube.com/watch?v=Gnbij64Olzg")
              }
            >
              <img
                src={width > 600 ? bofVideoBannerSec5 : mbBofVideoBannerSec5}
                alt="image"
                className="bof_img"
                loading="lazy"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="119"
                height="118"
                viewBox="0 0 119 118"
                fill="none"
                className="play_button"
              >
                <path
                  d="M59.5 108.167C86.8841 108.167 109.083 86.1539 109.083 58.9999C109.083 31.8459 86.8841 9.83325 59.5 9.83325C32.1158 9.83325 9.91663 31.8459 9.91663 58.9999C9.91663 86.1539 32.1158 108.167 59.5 108.167Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M49.5834 39.3333L79.3334 58.9999L49.5834 78.6666V39.3333Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
          <p
            className="img_desc"
            // data-aos="fade-up"
          >
            Exploring the “Growth Mindset” and building a beauty business from
            scratch in 2023.
          </p>
        </section>
        {/* </Fade> */}

        {/* <Fade delay={FADE_DELAY}> */}
        <section className="bof_sec4 bof_sec6">
          <div className="my_container">
            <div
              className="row sec4_row"
              // data-aos="fade-up"
            >
              {sec6DataList}
            </div>
          </div>
        </section>
        {/* </Fade> */}

        {/* <Fade delay={FADE_DELAY}> */}
        <section className="bof_sec3 bof_sec7">
          {width < 600 && (
            <p className="img_desc image_by">PHOTO BY INDIGITAL.TV.</p>
          )}
          <div
            className="bof_img_container"
            // data-aos="fade-up"
          >
            <span
              className="reel_video"
              onClick={() =>
                handleWatchClick("https://www.youtube.com/watch?v=D5WOZ6HfwSg")
              }
            >
              <img
                src={width > 600 ? bofVideoBannerSec7 : mbBofVideoBannerSec7}
                alt="image"
                className="bof_img"
                loading="lazy"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="119"
                height="118"
                viewBox="0 0 119 118"
                fill="none"
                className="play_button"
              >
                <path
                  d="M59.5 108.167C86.8841 108.167 109.083 86.1539 109.083 58.9999C109.083 31.8459 86.8841 9.83325 59.5 9.83325C32.1158 9.83325 9.91663 31.8459 9.91663 58.9999C9.91663 86.1539 32.1158 108.167 59.5 108.167Z"
                  fill="#1B1B1B"
                />
                <path
                  d="M49.5834 39.3333L79.3334 58.9999L49.5834 78.6666V39.3333Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
          <p
            className="img_desc"
            // data-aos="fade-up"
          >
            Unpacking the beauty revolution in India by Re-Imagining Indian
            Beauty.
          </p>
        </section>
        {/* </Fade> */}

        {/* <Fade delay={FADE_DELAY}> */}
        <section className="bof_sec4 bof_sec8">
          <div className="my_container">
            <div
              className="row sec4_row"
              // data-aos="fade-up"
            >
              {sec8DataList}
            </div>
          </div>
        </section>
        {/* </Fade> */}
      </main>

      <section className="bof_fsligthbox">
        <FsLightbox toggler={isVideoModalOpen} sources={[vidLink]} />
      </section>

      <Footer />
    </>
  );
};

export default BusinessOfFashion;
