import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Routing from "./Routing";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./fonts.css";
import "./common.scss";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserState from "./context/User/UserState";

const App = () => {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        {/* <AuthProvider> */}
        <UserState>
          {/* {process.env.REACT_APP_APP_MODE === "production" && <ScrollToTop />} */}

          <Routes>
            <Route path="*" element={<Routing />} />
          </Routes>
          {/* </AuthProvider> */}
        </UserState>
      </BrowserRouter>
    </>
  );
};

export default App;
