import React, { useEffect, useState } from "react";
import "./judges.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { images, plusiconblack } from "../../images";
import { useWindowSize } from "react-use";
import { judgesData } from "../../data/Data";
import CommonBanner from "../../components/CommonBanner/CommonBanner";

const Judges = () => {
  const { width } = useWindowSize();
  const [open, setOpen] = useState("");

  const judgesList = judgesData.map((item) => {
    return (
      <div className="col-sm-6 col-md-6 col-lg-4 p-0">
        <div className="judges_col">
          <div className="judges_inner_body">
            <div className="img_text_body">
              <img
                src={open === item.id ? item.img : item.img}
                alt={item.alt}
                className="judges_image"
              />
              <div className="judges_details">
                <h6 className="judges_name">{item.name}</h6>
                <p
                  className="judges_desg"
                  dangerouslySetInnerHTML={{ __html: item.desg }}
                ></p>
                <p className="judges_company">{item.company.toLowerCase()}</p>
              </div>
            </div>
            <div
              className={`read_more_cta ${open === item.id && "active"}`}
              onClick={() => setOpen(open === item.id ? "" : item.id)}
            >
              <div className="read_more">read more</div>
              <img
                // src={open === item.id ? minusicon : plusicon}
                src={plusiconblack}
                alt="plus icon"
                className="plus_icon"
                loading="lazy"
              />
            </div>
          </div>
          <p
            className={`judges_desc ${open === item.id ? "active" : null}`}
            dangerouslySetInnerHTML={{ __html: item.desc }}
          />
        </div>
      </div>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main className="judges">
        <CommonBanner
          imgSrc={width > 600 ? images.judgesBanner.image : images.banner08mb.image}
          title={`THE ${width < 600 ? "<br />" : ""}JUDGES`}
        />

        <section className="judges_sec1">
          <div className="my_container">
            <div className="text_container">
              <h4 className="section_subheading judges_heading">
                Our Extraordinary Judges
              </h4>
              <p className="section_desc judges_desc">Get to know our judges</p>
            </div>
          </div>
        </section>

        <div className="my_container judges_container">
          <section className="judges_sec2">
            <div className="row judges_row">{judgesList}</div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Judges;
