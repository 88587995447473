import React, { useEffect } from "react";
import "./winners.scss";
import Header from "../../components/Header/Header";
import { mbwinnerbanner, winnerbanimg } from "../../images";
import { winnersData } from "../../data/Data";
import Footer from "../../components/Footer/Footer";
import { useWindowSize } from "react-use";
import { Fade } from "react-awesome-reveal";
import { FADE_DELAY } from "../../helpers/constant-words";
import Aos from "aos";
import "aos/dist/aos.css";

const Winners = () => {
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    Aos.init({
      duration: 1500,
      disable: window.innerWidth < 1279,
    });
  }, []);

  const winnersList = winnersData.map((item, i) => {
    return (
      <div className="col-md-6 winner_col" key={i} data-aos="fade-up">
        <Fade delay={FADE_DELAY}>
          <h4 className="title_1" data-aos="fade-up">
            {item.title}
          </h4>
          <div className="box_1">
            <a
              className="link_1"
              href={item.link !== "" ? item.link : null}
              target="_blank"
              rel="noreferrer"
              data-aos="fade-up"
            >
              <img
                src={item.img}
                alt={item.alt}
                width={325}
                height={325}
                className="box1_img"
                data-aos="fade-up"
              />
            </a>
            <div className="box_content">
              <h6 className="heading_1" data-aos="fade-up">
                {item.name}
              </h6>
              <p className="text_1" data-aos="fade-up">
                {item.designation}
              </p>
            </div>
            <h6 className="text_2" data-aos="fade-up">
              {item.desc}
            </h6>
            <div className="box_link">
              <a
                className="link_1"
                href={item.link}
                rel="noreferrer"
                target="_blank"
                data-aos="fade-up"
              >
                {item.linktext}
              </a>
              <a
                className="link_1"
                href={item.email}
                target="_blank"
                rel="noreferrer"
                data-aos="fade-up"
              >
                {item.emailtext}
              </a>
            </div>
          </div>
        </Fade>
      </div>
    );
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main className="winner">
        <Fade delay={FADE_DELAY}>
          <section className="winner_sec1" data-aos="fade">
            {windowWidth > 600 ? (
              <>
                <div className="winner_banner_image">
                  <div className="winner_image_wrapper" data-aos="fade">
                    <img
                      src={winnerbanimg}
                      alt="Banner 2"
                      width={464}
                      height={530}
                      className="winner_img"
                      data-aos="fade"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mbwinner_banner_image">
                  <div className="mbwinner_image_wrapper" data-aos="fade">
                    <img
                      src={mbwinnerbanner}
                      alt="Banner 2"
                      width={209}
                      height={261}
                      className="mbwinner_img"
                      data-aos="fade"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="banner_content_box1">
              <h1 className="winnerbanner_heading" data-aos="fade">
                MEET
              </h1>
              <h1
                className="winnerbanner_heading winnerbanner_heading2"
                data-aos="fade"
              >
                THE
              </h1>
            </div>
            <div className="banner_content_box2">
              <h1
                className="winnerbanner_heading winnerbanner_heading3"
                data-aos="fade"
              >
                2022
              </h1>
            </div>
            <div className="banner_content_box3">
              <h1
                className="winnerbanner_heading winnerbanner_heading4"
                data-aos="fade"
              >
                WINNERS
              </h1>
            </div>
          </section>
        </Fade>

        <Fade delay={FADE_DELAY}>
          <section className="winner_sec2">
            <div className="my_container">
              <div className="winners_container" data-aos="fade-up">
                <h3 className="heading_1" data-aos="fade-up">
                  2022 WINNERS
                </h3>
                <h6 className="text_1" data-aos="fade-up">
                  Winners for the Imagine and Grow categories were independently
                  chosen by our panel of judges through a competitive judging
                  process in Mumbai, India. Winner for the Community category
                  was determined on the basis of an independent voting process{" "}
                  <a
                    href="www.beautyandyouawards.com"
                    target="_blank"
                    rel="noreferrer"
                    className="orange"
                  >
                    www.beautyandyouawards.com
                  </a>{" "}
                  via the BEAUTY&YOU website and an analysis of social
                  engagement.
                </h6>
                <p className="text_2" data-aos="fade-up">
                  To know more about each brand and get a glimpse of their
                  journey with BEAUTY&YOU, please click on the respective images
                  below.
                </p>
              </div>
            </div>
          </section>
        </Fade>

        <Fade delay={FADE_DELAY}>
          <section className="winner_sec3">
            <div className="my_container">
              <div className="row winner_row" data-aos="fade-up">
                {winnersList}
              </div>
            </div>
          </section>
        </Fade>
      </main>
      <Footer />
    </>
  );
};

export default Winners;
