import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  commonfooterstrip,
  idexfooter,
  insta,
  linke,
  nykaalogo,
  termsandconditionsPDF,
  ytlogo,
} from "../../images";
import "./Footer.scss";
import http from "../../helpers/http";
import config from "../../config/config";
import Joi from "joi";
import {
  aboutUsURL,
  contactUs,
  faqURL,
  instaLink,
  linkedinLink,
  privacyandcookiepolicyroute,
} from "../../helpers/constant-words";
import { useWindowSize } from "react-use";

const Footer = () => {
  const [values, setValues] = useState({ email: "" });
  const [formError, setFormError] = useState({});
  const [isMsgVisible, setisMsgVisible] = useState(false);
  const [term2, setTerm2] = useState(false);
  const { width: windowWidth } = useWindowSize();

  let base_url = config.api_url + "2024/forms/newsletter";
  const schema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (!term2) {
      errors["term2"] = "REQUIRED";
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    let errors = await validate(values);
    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    // setLoading(true);

    const { data } = await http.post(base_url, values);

    if (data) {
      // redirect
      setValues({ email: "" });
      setTerm2(false);
      setisMsgVisible(true);

      setTimeout(() => {
        setisMsgVisible(false);
      }, 5000);
    }
    // setLoading(false);
  };

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleterm2 = (e) => {
    setTerm2((current) => !current);
  };
  return (
    <>
      <footer className="footer">
        <div className="my_container">
          <div className="main_footer_wrap">
            <div className="row">
              <div className="col-lg-4 p-0 order-lg-1">
                <div className="box_1">
                  <div className="feauterd_img">
                    <p className="heading">BEAUTY&YOU IS CREATED BY</p>
                    <figure className="img_strip">
                      <img
                        src={commonfooterstrip}
                        alt="img_strip"
                        loading="lazy"
                      />
                    </figure>
                  </div>
                  <div className="feauterd_img">
                    <p className="heading">lead sponsor</p>
                    <figure className="img_strip">
                      <img src={nykaalogo} alt="img_strip" loading="lazy" />
                    </figure>
                  </div>
                  <div className="feauterd_img">
                    <p className="heading">executive producer</p>
                    <figure className="img_strip">
                      <img src={idexfooter} alt="img_strip" loading="lazy" />
                    </figure>
                  </div>
                </div>
              </div>
              {windowWidth > 576 ? (
                <div className="col-lg-1 p-0 order-lg-3"></div>
              ) : (
                ""
              )}
              <div className="col-lg-3 p-0 order-lg-4">
                <div className="row">
                  <div className="col-sm-7 col-md-7 p-0">
                    <div className="quick_wrap">
                      <Link to={aboutUsURL} className="quick_link">
                        ABOUT US
                      </Link>
                      <Link to={faqURL} className="quick_link">
                        FAQ
                      </Link>
                      <Link
                        to={termsandconditionsPDF}
                        target="_blank"
                        rel="noreferrer"
                        className="quick_link"
                      >
                        TERMS & CONDITIONS
                      </Link>
                      <Link
                        to={privacyandcookiepolicyroute}
                        className="quick_link"
                      >
                        PRIVACY POLICY
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`col-sm-5 col-md-5 contact_flex ${
                      windowWidth < 576 ? "p-0" : ""
                    }`}
                  >
                    <div className="contact_wrap">
                      <Link to={contactUs} className="quick_link">
                        CONTACT
                      </Link>

                      <div
                        className={`${windowWidth < 576 ? "mobile_box" : ""}`}
                      >
                        <span className="quick_link">FOLLOW US</span>
                        <div className="footer_social_icons_wrapper">
                          <a href={instaLink} target="_blank" rel="noreferrer">
                            <img
                              width={19.5}
                              height={19.5}
                              src={insta}
                              alt="Social Icon"
                              loading="lazy"
                            />
                          </a>
                          <a
                            href={linkedinLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              width={19.5}
                              height={19.5}
                              src={linke}
                              alt="Social Icon"
                              loading="lazy"
                            />
                          </a>
                          <a
                            href="https://www.youtube.com/channel/UCo2cJCpgCOVf4RxmqTmuXDw?app=desktop"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              width={24}
                              height={24}
                              src={ytlogo}
                              alt="Social Icon"
                              loading="lazy"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 p-0 order-lg-2">
                <div className="signup_heading">SIGN UP TO OUR NEWSLETTER</div>
                <form onSubmit={handleSubmit}>
                  <div className="footerform_wrapper">
                    <input
                      type="text"
                      placeholder="EMAIL"
                      className="footer_form_field"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <button type="submit" className="news_submit_btn">
                      SUBMit
                    </button>
                    <p className="error_message">{formError.email}</p>
                  </div>
                  <div className="confirmation_wrapper">
                    <input
                      type="checkbox"
                      id="footer-checkbox2"
                      name="term2"
                      value="term2"
                      checked={term2}
                      onClick={handleterm2}
                      className="confirmation_input"
                    />
                    <label
                      htmlFor="footer-checkbox2"
                      className="confirmation_label"
                    >
                      <span className="confirmation_outer">
                        <span className="confirmation_right_tick"></span>
                      </span>
                      <span className="confirmation_label_text">
                        By checking this box and clicking sign up, I agree to
                        the{" "}
                        <Link
                          to={termsandconditionsPDF}
                          target="_blank"
                          rel="noreferrer"
                          className="link_btn"
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and I acknowledge the{" "}
                        <Link
                          to="/privacy-and-policy"
                          target="_blank"
                          rel="noreferrer"
                          className="link_btn"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </span>
                    </label>
                    {isMsgVisible && (
                      <p className="thank_message section_subdesc">
                        Thank you for subscribing to the BEAUTY&YOU newsletter.
                      </p>
                    )}
                    <p className="error_message confirmation_error">
                      {formError.term2}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright_wrapper">
          <p>© {new Date().getFullYear()} Estée Lauder International, Inc.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
